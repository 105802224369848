import Dot from "../assets/img/dot.svg";
export const convertCurrency = (amount: string | number): string => {
  if (amount) {
    if (typeof amount === "string") {
      if (amount?.includes("-")) {
        return "+ $" + amount.replace("-", "");
      } else {
        return "$" + amount;
      }
    } else {
      if (amount < 0) {
        return "+ $" + amount.toFixed(2).replace("-", "");
      } else if (amount > 0) {
        return "$" + amount.toFixed(2);
      } else {
        return "$" + amount.toFixed(2);
      }
    }
  }
  return "";
};

export const convertReportCurrency = (amount: string | number): string => {
  if (amount) {
    if (typeof amount === "string") {
      if (amount?.includes("-")) {
        return "+ $" + amount.replace("-", "");
      } else {
        return "$" + amount;
      }
    } else {
      if (amount < 0) {
        return "+ $" + amount.toFixed(2).replace("-", "");
      } else if (amount > 0) {
        return "$" + amount.toFixed(2);
      } else {
        return "$" + amount.toFixed(2);
      }
    }
  }
  return "";
};


export const convertNegativeMealAmount = (amount: string | number): string => {
  if (amount) {
    if (typeof amount === "string") {
      if (amount?.includes("-")) {
        return "+ " + amount.replace("-", "");
      } else {
        return amount;
      }
    } else {
      if (amount < 0) {
        return "+ " + amount.toFixed(2).replace("-", "");
      } else if (amount > 0) {
        return amount.toFixed(2);
      } else {
        return amount.toFixed(2);
      }
    }
  }
  return "";
};

export const formatNumber = (number: number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    number
  );

export const formatMethod = (method: string): string => {
  switch (method) {
    case "PENDING":
      return "Pending";
    default:
      return "Paid";
  }
};

export const isUrl = (url: string): boolean => {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    url
  );
};

export const isHttp = (url: string): boolean => {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+$/.test(url);
};

export const toPhoneNumber = (phone?: string): string => {
  return phone
    ? phone.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    : "";
};

export const fromPhoneNumberToString = (phone?: string): string => {
  return phone
    ? phone.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3")
    : "";
};

export const toNumberWithComma = (num: string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getNumber = (number: string | number) =>
  typeof number === "string" ? parseFloat(number) : number;

export const toMaskedCardNumber = (number: string): string => {
  return number.replace(
    /X+/g,
    // "<span class='fw-bold'>&#x25cf;&#x25cf;&#x25cf;&#x25cf;</span>"
    `<span style="margin: -16px"><img src=${Dot} height="40px" style="margin-bottom: 3px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/></span>`
  );
};
export const toMaskedCardNumberFromAsterisk = (number: string): string => {
  return number
    ? number.replace(
        /\*+/g,
        // "<span class='fw-bold d-inline'>&#x25cf;&#x25cf;&#x25cf;&#x25cf;</span>"
        `<span style="margin: -16px; display: inline;"><img src=${Dot} height="40px" style="margin-bottom: 3px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/><img src=${Dot} height="40px" style="margin:0 0 3px -32px"/></span>`
      )
    : "";
};
export const toMaskedCardNumberForDownload = (number: string): string => {
  return number
    ? number.replace(
        /\*+/g,
        `<span style="margin: -16px"><img src=${Dot} height="40px" style="margin-bottom: 2px"/><img src=${Dot} height="40px" style="margin:0 0 2px -32px"/><img src=${Dot} height="40px" style="margin:0 0 2px -32px"/><img src=${Dot} height="40px" style="margin:0 0 2px -32px"/></span>`
      )
    : "";
};
export const toMaskedCardNumberForDownloadTable = (number: string): string => {
  return number
    ? number.replace(
        /\*+/g,
        `<span style="margin: -7px"><img src=${Dot} height="20px"/><img src=${Dot} height="20px" style="margin-left:-16px"/><img src=${Dot} height="20px" style="margin-left:-16px"/><img src=${Dot} height="20px" style="margin-left: -16px"/></span>`
      )
    : "";
};
