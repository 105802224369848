import withAuth from "../components/hocs/withAuth";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ForgotPasswordLinkExpired from "../pages/auth/ForgotPasswordLinkExpired";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import Dashboard from "../pages/dashboard/Dashboard";
import DashboardRegister from "../pages/dashboard/DashboardRegister";
import GetHelp from "../pages/GetHelp";
import Messages from "../pages/Messages";
import MyCardAddFunds from "../pages/mycard/MyCardAddFunds";
import MyCardAddFundsInstitutionFunded from "../pages/mycard/MyCardAddFundsInstitutionFunded";
import MyCardInstitutionFunded from "../pages/mycard/MyCardInstitutionFunded";
import OrderComplete from "../pages/OrderComplete";
import Restaurants from "../pages/Restaurants";
import Settings from "../pages/Settings";
import PlanReloads from "../pages/transactions/PlanReloads";
import Transactions from "../pages/transactions/Transactions";
import TransactionsMealPurchases from "../pages/transactions/TransactionsMealPurchases";

export const APP_ROUTES = {
  Login: {
    path: "/login",
    Component: Login,
    withAuth: false,
  },
  Logout: {
    path: "/logout",
    Component: Logout,
    withAuth: false,
  },
  Register: {
    path: "/register",
    Component: Register,
    withAuth: false,
  },
  ForgotPassword: {
    path: "/forgot-password",
    Component: ForgotPassword,
    withAuth: false,
  },
  ResetPassword: {
    path: "/reset-password",
    Component: ResetPassword,
    withAuth: false,
  },
  ForgotPasswordLinkExpired: {
    path: "/forgot-password-link-expired",
    Component: ForgotPasswordLinkExpired,
    withAuth: false,
  },
  Dashboard: {
    path: "/",
    Component: withAuth(Dashboard),
    withAuth: true,
  },
  DashboardRegister: {
    path: "/dashboard-register",
    Component: withAuth(DashboardRegister),
    withAuth: true,
  },
  GetHelp: {
    path: "/get-help",
    Component: withAuth(GetHelp),
    withAuth: true,
  },
  Messages: {
    path: "/messages",
    Component: withAuth(Messages),
    withAuth: true,
  },
  MyCardAddFundsInstitutionFunded: {
    path: "/my-card-add-funds-institution-funded",
    Component: withAuth(MyCardAddFundsInstitutionFunded),
    withAuth: true,
  },
  MyCardAddFunds: {
    path: "/my-card-add-funds",
    Component: withAuth(MyCardAddFunds),
    withAuth: true,
  },
  MyCard: {
    path: "/my-card",
    Component: withAuth(MyCardInstitutionFunded),
    withAuth: true,
  },
  OrderComplete: {
    path: "/order-complete/:orderId",
    Component: withAuth(OrderComplete),
    withAuth: true,
  },
  PlanReloads: {
    path: "/transactions/reloads",
    Component: withAuth(PlanReloads),
    withAuth: true,
  },
  Settings: {
    path: "/settings",
    Component: withAuth(Settings),
    withAuth: true,
  },
  TransactionsMealPurchases: {
    path: "/transactions/purchases",
    Component: withAuth(TransactionsMealPurchases),
    withAuth: true,
  },
  Transactions: {
    path: "/transactions",
    Component: withAuth(Transactions),
    withAuth: true,
  },
  Restaurants: {
    path: "/restaurants",
    Component: withAuth(Restaurants),
    withAuth: true,
  },
};
