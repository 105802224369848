import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import useOutsideClick from "../../hooks/useOutsideClick";
import { authActions } from "../../state/auth/authSlice";
import tickAnimate from "../../assets/img/tick-animation.gif";

type ResetPasswordFormValues = {
  resetPassword: string;
  confirmResetPassword: string;
};

const ResetPasswordFormValidateSchema = Yup.object({
  resetPassword: Yup.string()
    .required("Please provide a password.")
    .min(6, "Your password must be at least 6 characters long."),
  confirmResetPassword: Yup.string()
    .required("Please provide a confirm password.")
    .min(6, "Your password must be at least 6 characters long.")
    .oneOf(
      [Yup.ref("resetPassword"), null],
      "Confirm reset password must match with reset password"
    ),
});

const initialValues: ResetPasswordFormValues = {
  resetPassword: "",
  confirmResetPassword: "",
};

const EditSecurityModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  const [responseStatus, setResponseStatus] = useState({
    showSuccess: false,
    error: "",
    successMessage: "",
  });

  const formHandlers = useFormik<ResetPasswordFormValues>({
    initialValues,
    validationSchema: ResetPasswordFormValidateSchema,
    onSubmit: ({ resetPassword, confirmResetPassword }) => {
      dispatch(
        authActions.resetPassword({
          data: {
            reset_password: resetPassword,
            confirm_reset_password: confirmResetPassword,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setResponseStatus({
                showSuccess: true,
                error: "",
                successMessage: data.message,
              });
              formHandlers.resetForm();
              return;
            }
            formHandlers.resetForm();
            setResponseStatus({
              showSuccess: false,
              error:
                data?.message ||
                "Something went wrong on our end. Please try again.",
              successMessage: "",
            });
            return;
          },
        })
      );
    },
  });

  useOutsideClick(formRef, () => {
    setResponseStatus({
      showSuccess: false,
      error: "",
      successMessage: "",
    });
    formHandlers.resetForm();
  });

  return (
    <div
      className="modal fade"
      id="editSecurity"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={formRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {responseStatus.showSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {responseStatus.successMessage}
                </p>
              </div>
            ) : (
              <div className="form-content">
                <h2 className="xl-title text-center">Edit Security Settings</h2>
                <p className="lg-title text-center mb-5">
                  Complete the form below to change your password
                </p>
                <form
                  className="max-width-350"
                  onSubmit={formHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="password">New Password *</label>
                    <input
                      type="password"
                      name="resetPassword"
                      id="password"
                      className="form-control"
                      placeholder="New Password"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.resetPassword}
                    />
                    {formHandlers.touched.resetPassword &&
                    formHandlers.errors.resetPassword ? (
                      <label className="error">
                        {formHandlers.errors.resetPassword}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">
                      Confirm New Password *
                    </label>
                    <input
                      type="password"
                      name="confirmResetPassword"
                      id="confirmPassword"
                      className="form-control"
                      placeholder="Confirm New Password"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.confirmResetPassword}
                    />
                    {formHandlers.touched.confirmResetPassword &&
                    formHandlers.errors.confirmResetPassword ? (
                      <label className="error">
                        {formHandlers.errors.confirmResetPassword}
                      </label>
                    ) : null}
                  </div>
                  {responseStatus.error && (
                    <label className="error mb-1">{responseStatus.error}</label>
                  )}
                  <button type="submit" className="btn btn-primary w100">
                    Save Changes
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSecurityModal;
