import React from "react";
import { Helmet } from "react-helmet";
import AuthLayout from "../../components/layout/AuthLayout";
import ResetPasswordForm from "../../modules/auth/ResetPasswordForm";

const ResetPassword = () => {
  return (
    <AuthLayout
      pageName="auth-reset-password"
      navItem={{ url: "/register", text: "Create Account" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password | OCMP</title>
      </Helmet>
      <section>
        <div className="authentication_box">
          <h1 className="xl-title text-center mb-3">Reset Password</h1>
          <p className="text-center text-muted mb-4">
            Fill out the form below to reset your password
          </p>
          <ResetPasswordForm />
        </div>
      </section>
    </AuthLayout>
  );
};

export default ResetPassword;
