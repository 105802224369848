import apiClient from "../utils/apiClient";
import { APIResponse } from "./types";

// List Restaurant
export type APIListRestaurantsInput = {
  SchoolID?: string;
  name?: string;
  type?: string;
  latitude?: string;
  longitude?: string;
  m_id?: string;
  sort_by?: "alpha" | "dist"; // Options: alpha=alphabetical, dist=by distance.
  sort_order?: "asc" | "desc";
  items_per_page?: number;
  page_number?: number;
};

export type RestaurantEntity = {
  M_ID: number;
  Name: string;
  Addr_A: string;
  Addr_B: string;
  City: string;
  State: string;
  Zip: string;
  Active: number;
  Phone: string;
  Fax: string;
  Latitude: string;
  Longitude: string;
  Web: string;
  Type: string;
  Slogan: string;
  Logo: string;
  Delivery: string;
  OpenTime: string;
  CloseTime: string;
  miles_away: string;
  student_favorite: string;
};

export type APIListRestaurantsResponse = APIResponse<{
  data: Array<RestaurantEntity>;
}>;

const listRestaurants = (data: APIListRestaurantsInput) => {
  return apiClient.get<APIListRestaurantsResponse>(`/v1/restaurant.json`, {
    params: data,
  });
};

// Restaurant Details
export type RestaurantDetailsEntity = {
  M_ID: number;
  Name: string;
  Addr_A: string;
  Addr_B: string;
  City: string;
  State: string;
  Zip: string;
  Active: number;
  Phone: string;
  Fax: string;
  Latitude: string;
  Longitude: string;
  Web: string;
  Type: string;
  Slogan: string;
  Logo: string;
  Delivery: string;
  MonStart: string;
  MonEnd: string;
  TueStart: string;
  TueEnd: string;
  WedStart: string;
  WedEnd: string;
  ThuStart: string;
  ThuEnd: string;
  FriStart: string;
  FriEnd: string;
  SatStart: string;
  SatEnd: string;
  SunStart: string;
  SunEnd: string;
  MonDeliveryStart: string;
  MonDeliveryEnd: string;
  TueDeliveryStart: string;
  TueDeliveryEnd: string;
  WedDeliveryStart: string;
  WedDeliveryEnd: string;
  ThuDeliveryStart: string;
  ThuDeliveryEnd: string;
  FriDeliveryStart: string;
  FriDeliveryEnd: string;
  SatDeliveryStart: string;
  SatDeliveryEnd: string;
  SunDeliveryStart: string;
  SunDeliveryEnd: string;
  student_favorite: string;
};

export type RestaurantSpecialsEntity = {
  SpecialTitle: string;
  SpecialValidFrom: string;
  SpecialValidTo: string;
  SpecialPricing: string;
};

export type APIRestaurantDetailsResponse = APIResponse<{
  data: Array<{
    restaurantData: RestaurantDetailsEntity;
    restaurantSpecials: Array<RestaurantSpecialsEntity>;
  }>;
}>;

const getDetails = (data: { m_id: string }) => {
  return apiClient.get<APIRestaurantDetailsResponse>(
    `/v1/restaurant/${data.m_id}/details.json`
  );
};

// Restaurant Types
export type RestaurantTypeEntity = {
  type: string;
  desc: string;
};

export type APIRestaurantTypesResponse = APIResponse<{
  data: Array<RestaurantTypeEntity>;
}>;

const getTypes = () => {
  return apiClient.get<APIRestaurantTypesResponse>(`/v1/restaurant/types.json`);
};

// Suggest Restaurant
export type SuggestEntity = {
  restaurant_name: string;
  email_address: string;
  restaurant_address: string;
  restaurant_phone: string;
  restaurant_url: string;
};

export type SuggestBySchoolEntity = {
  SchoolID: string;
  restaurant_name: string;
  email_address: string;
  restaurant_address: string;
  restaurant_phone: string;
  restaurant_url: string;
  comments: string;
};

export type APISuggestRestaurantResponse = APIResponse<{
  data: Array<RestaurantTypeEntity>;
}>;

const suggestRestaurant = () => {
  return apiClient.get<APIRestaurantTypesResponse>(
    `/v1/restaurant/request.json`
  );
};

const requestRestaurant = (data: SuggestEntity) => {
  return apiClient.post<APIResponse<{}>>(`/v1/restaurant/request.json`, {
    ...data,
  });
};

const requestRestaurantBySchool = (data: SuggestBySchoolEntity) => {
  return apiClient.post<APIResponse<{}>>(
    `/v1/restaurant/requestbyschool.json`,
    {
      ...data,
    }
  );
};

const restaurantApi = {
  listRestaurants,
  getDetails,
  getTypes,
  suggestRestaurant,
  requestRestaurant,
  requestRestaurantBySchool,
};

export default restaurantApi;
