import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";
import { authStateSelector } from "../../state/auth/authSelector";

const withAuth = (Page: React.ComponentType) => {
  const AuthenticatedWrapper: React.FC = () => {
    const authState = useSelector(authStateSelector);
    const navigate = useNavigate();
    useEffect(() => {
      if (!authState.isAuthenticated) {
        navigate(APP_ROUTES.Login.path);
        return;
      }
    }, [authState.isAuthenticated]);
    if (!authState.isAuthenticated) return null;
    return <Page />;
  };
  return AuthenticatedWrapper;
};

export default withAuth;
