import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { HostedForm } from "react-acceptjs";
import * as Yup from "yup";
import { StoredPaymentOptionsEntity } from "../../api/paymentApi";
import { useAppDispatch } from "../../app/hooks";
import tickAnimate from "../../assets/img/tick-animation.gif";
import AddressSelection from "../../components/common/AddressSelection";
import { authData } from "../../constants/authdata";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
  listStoredPaymentOptions,
  paymentActions,
} from "../../state/payment/paymentSlice";
import { getCardLogo } from "../../utils/logo";
import { toMaskedCardNumber } from "../../utils/string";

interface AddCardValues {
  AuthDataDescriptor: string;
  AuthDataValue: string;
  BillingFirstName: string;
  BillingLastName: string;
  BillingAddr_A: string;
  BillingAddr_B: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
}

interface EditPaymentModalProps {
  storedPayments: StoredPaymentOptionsEntity[];
}

const initialValues: AddCardValues = {
  AuthDataDescriptor: "",
  AuthDataValue: "",
  BillingFirstName: "",
  BillingLastName: "",
  BillingAddr_A: "",
  BillingAddr_B: "",
  BillingCity: "",
  BillingState: "",
  BillingZip: "",
};

const AddCardValidateSchema = Yup.object({
  BillingFirstName: Yup.string().required("Please provide First name."),
  BillingLastName: Yup.string().required("Please provide Last name."),
  BillingAddr_A: Yup.string().required("Please provide Address 1."),
  BillingCity: Yup.string().required("Please provide City."),
  BillingState: Yup.string().required("Please provide State."),
  BillingZip: Yup.string().required("Please provide Zip."),
});

const EditPaymentModal: React.FC<EditPaymentModalProps> = ({
  storedPayments,
}) => {
  const dispatch = useAppDispatch();
  const formRef = useRef(null);

  const [showAddPayment, setShowAddPayment] = useState(false);
  const [responseStatus, setResponseStatus] = useState({
    showSuccess: false,
    error: "",
    successMessage: "",
  });

  useOutsideClick(formRef, () => {
    setShowAddPayment(false);
    setResponseStatus({
      showSuccess: false,
      error: "",
      successMessage: "",
    });
    addPaymentFormHandler.resetForm();
  });

  const addPaymentFormHandler = useFormik<AddCardValues>({
    initialValues,
    validationSchema: AddCardValidateSchema,
    onSubmit: ({
      BillingFirstName,
      BillingLastName,
      BillingAddr_A,
      BillingAddr_B,
      BillingCity,
      BillingState,
      BillingZip,
      AuthDataValue,
      AuthDataDescriptor,
    }) => {
      if (!AuthDataValue && !AuthDataDescriptor) {
        return document
          .querySelector<HTMLButtonElement>(".btn-add-payment")
          ?.click();
      }
      setResponseStatus({
        showSuccess: false,
        error: "",
        successMessage: "",
      });
      const paymentPayload = {
        BillingFirstName,
        BillingLastName,
        BillingAddr_A,
        BillingAddr_B,
        BillingCity,
        BillingState,
        BillingZip,
        AuthDataValue,
        AuthDataDescriptor,
      };
      dispatch(
        paymentActions.addStoredPayment({
          data: paymentPayload,
          onComplete: (error, data) => {
            if (data?.status) {
              setResponseStatus({
                showSuccess: true,
                error: "",
                successMessage: data.payload.response_data.response,
              });
              dispatch(listStoredPaymentOptions());
              return;
            }
            addPaymentFormHandler.setValues((values) => ({
              ...values,
              AuthDataDescriptor: "",
              AuthDataValue: "",
            }));
            setResponseStatus({
              showSuccess: false,
              error:
                data?.message ||
                "Something went wrong on our end. Please try again.",
              successMessage: "",
            });
            return;
          },
        })
      );
    },
  });

  return (
    <div
      className="modal fade"
      id="editPayment"
      tabIndex={-1}
      data-backdrop=""
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        ref={formRef}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {responseStatus.showSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {responseStatus.successMessage}
                </p>
              </div>
            ) : (
              <div className="form-content">
                <h2 className="xl-title text-center">Edit Payment Settings</h2>
                <p className="lg-title text-center mb-30">
                  Manage your payment settings below
                </p>
                <div className="max-width-500">
                  {storedPayments.length === 0 ? (
                    <p className="text-center">
                      You are having no saved credit cards now.
                    </p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table payment-table">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Number</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {storedPayments?.map((p) => (
                            <tr key={p.payment_profile_id}>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={getCardLogo(p.CardType)}
                                    alt="card-type"
                                    className="border rounded"
                                    width={36}
                                  />
                                  <div className="f500">{p.CardType}</div>
                                </div>

                                {/* <li className="list-inline-item f500">
                                <span className="badge badge-primary">
                                  PRIMARY
                                </span>
                              </li> */}
                              </td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: toMaskedCardNumber(
                                    p.CreditCardNumber
                                  ),
                                }}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button
                    className="btn btn-outline w100 mb-4 d-flex align-items-center justify-content-center"
                    onClick={() => setShowAddPayment(true)}
                  >
                    <span className="me-1"> Add Payment </span>
                    <svg
                      width={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 12H20.25"
                        stroke="#00529A"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 3.75V20.25"
                        stroke="#00529A"
                        strokeWidth="1.125"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <form
                    onSubmit={addPaymentFormHandler.handleSubmit}
                    className={showAddPayment ? "" : "d-none"}
                  >
                    <h2 className="xl-title mb-30">Billing Address</h2>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name *</label>
                      <input
                        type="text"
                        id="firstName"
                        name="BillingFirstName"
                        className="form-control"
                        placeholder="First Name"
                        value={addPaymentFormHandler.values.BillingFirstName}
                        onBlur={addPaymentFormHandler.handleBlur}
                        onChange={addPaymentFormHandler.handleChange}
                      />
                      {addPaymentFormHandler.touched.BillingFirstName &&
                      addPaymentFormHandler.errors.BillingFirstName ? (
                        <label className="error">
                          {addPaymentFormHandler.errors.BillingFirstName}
                        </label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name *</label>
                      <input
                        type="text"
                        id="lastName"
                        name="BillingLastName"
                        className="form-control"
                        placeholder="Last Name"
                        value={addPaymentFormHandler.values.BillingLastName}
                        onBlur={addPaymentFormHandler.handleBlur}
                        onChange={addPaymentFormHandler.handleChange}
                      />
                      {addPaymentFormHandler.touched.BillingLastName &&
                      addPaymentFormHandler.errors.BillingLastName ? (
                        <label className="error">
                          {addPaymentFormHandler.errors.BillingLastName}
                        </label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="billingAddress">Address 1 *</label>
                      <input
                        type="text"
                        name="BillingAddr_A"
                        id="billingAddress"
                        className="form-control"
                        placeholder="Address 1"
                        value={addPaymentFormHandler.values.BillingAddr_A}
                        onBlur={addPaymentFormHandler.handleBlur}
                        onChange={addPaymentFormHandler.handleChange}
                      />
                      {addPaymentFormHandler.touched.BillingAddr_A &&
                      addPaymentFormHandler.errors.BillingAddr_A ? (
                        <label className="error">
                          {addPaymentFormHandler.errors.BillingAddr_A}
                        </label>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="billingCity">City *</label>
                          <input
                            type="text"
                            id="billingCity"
                            className="form-control"
                            name="BillingCity"
                            placeholder="City"
                            value={addPaymentFormHandler.values.BillingCity}
                            onBlur={addPaymentFormHandler.handleBlur}
                            onChange={addPaymentFormHandler.handleChange}
                          />
                          {addPaymentFormHandler.touched.BillingCity &&
                          addPaymentFormHandler.errors.BillingCity ? (
                            <label className="error">
                              {addPaymentFormHandler.errors.BillingCity}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="billingState">State *</label>
                          <AddressSelection
                            id="billingState"
                            className="form-control p-0"
                            name="BillingState"
                            value={addPaymentFormHandler.values.BillingState}
                            onBlur={addPaymentFormHandler.handleBlur}
                            onChange={(value: string) =>
                              addPaymentFormHandler.setFieldValue(
                                "BillingState",
                                value
                              )
                            }
                          />
                          {addPaymentFormHandler.errors.BillingState ? (
                            <label className="error">
                              {addPaymentFormHandler.errors.BillingState}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="billingZip">Zip Code *</label>
                          <input
                            type="text"
                            id="billingZip"
                            className="form-control"
                            placeholder="Zip Code"
                            name="BillingZip"
                            value={addPaymentFormHandler.values.BillingZip}
                            onBlur={addPaymentFormHandler.handleBlur}
                            onChange={addPaymentFormHandler.handleChange}
                          />
                          {addPaymentFormHandler.touched.BillingZip &&
                          addPaymentFormHandler.errors.BillingZip ? (
                            <label className="error">
                              {addPaymentFormHandler.errors.BillingZip}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {responseStatus.error && (
                      <label className="w100 error mb-1 text-center">
                        {responseStatus.error}
                      </label>
                    )}
                    <button type="submit" className="btn btn-primary w100">
                      {addPaymentFormHandler.values.AuthDataDescriptor &&
                      addPaymentFormHandler.values.AuthDataValue
                        ? "Register This Card"
                        : "Register A Card"}
                    </button>
                    <HostedForm
                      environment={
                        process.env.REACT_APP_ENVIRONMENT === "production"
                          ? "PRODUCTION"
                          : "SANDBOX"
                      }
                      buttonClassName="btn btn-primary w-100 btn-add-payment d-none"
                      authData={authData}
                      buttonText="Register This Card"
                      formButtonText="Save this Card"
                      formHeaderText="Card Information"
                      onSubmit={async (res) => {
                        await addPaymentFormHandler.setValues((values) => ({
                          ...values,
                          AuthDataDescriptor: res.opaqueData.dataDescriptor,
                          AuthDataValue: res.opaqueData.dataValue,
                        }));
                        setTimeout(
                          () => addPaymentFormHandler.submitForm(),
                          1000
                        );
                      }}
                    />
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentModal;
