import apiClient from "../utils/apiClient";
import { TransactionEntity } from "./transactionApi";
import { APIResponse } from "./types";

// Student Data
export interface StudentEntity {
  first_name?: string;
  last_name?: string;
  HomeAddr_A?: string;
  HomeAddr_B?: string;
  HomeCity?: string;
  HomeState?: string;
  HomeZip?: string;
  HomePhone?: string;
  SchoolAddr_A?: string;
  SchoolAddr_B?: string;
  SchoolCity?: string;
  SchoolState?: string;
  SchoolZip?: string;
  SchoolPhone?: string;
  EmailAddress?: string;
  PIN?: number;
  GraduationYear?: string;
  SpecialProgram?: string;
  SchoolID?: string;
  schoolName?: string;
  program_id?: string;
  name_on_card?: string;
  student_email?: string;
}

export interface CardEntity {
  CardNumber: string;
  TempCardNumber: string;
  TempCardEnabled: string;
  PIN: string;
  Active: string;
  Balance: {
    Amount: string;
    Meals: string;
    StartingAmount: string;
    StartingMeals: string;
  };
  PaymentStatus: string;
  StartDate: string;
  EndDate: string;
}

export interface LoadTransactionEntity {
  order_number: string;
  Stamp: Date;
  NamePrintedOnCard: string;
  first_name: string;
  last_name: string;
  Amount: string;
  MealsPurchased: string;
  payment_method: string;
  payment_method_desc: string;
  PaymentStatus: string;
  plan_name: string;
  plan_meals: string;
  additional_meals: string;
  CreditCardNumber: string;
  MailToName: string;
  schoolName: string;
  mailTo: string;
  MailToAddr_A: string;
  MailToAddr_B: string;
  MailToCity: string;
  MailToState: string;
  MailToZip: string;
  BillingName: string;
  BillingAddr_A: string;
  BillingAddr_B: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
}

export type APIGetStudentResponse = APIResponse<{
  data: {
    alert: any[] | null;
    studentData: StudentEntity & { login_id: number };
    cardData: CardEntity;
    transactions: Array<TransactionEntity>;
    cardLoadTransactions: Array<LoadTransactionEntity>;
    favorites: Array<{ m_id: string; name: string }>;
    messages: Array<{ Stamp: string; message: string }>;
    schoolServices: Array<{
      Deeplink: string;
      Description: string;
      URL: string;
      SchoolServiceID: string;
    }>;
  };
}>;

const getStudentData = () => {
  return apiClient.get<APIGetStudentResponse>("/v1/student.json");
};

const postStudentData = (data: StudentEntity) => {
  return apiClient.post<APIResponse<{}>>("/v1/student.json", {
    ...data,
  });
};

// Student Favorites
export type APIGetStudentFavoritesResponse = APIResponse<{
  data: {
    favorites: Array<{ m_id: string; name: string }>;
  };
}>;

const getStudentFavorites = () => {
  return apiClient.get<APIGetStudentFavoritesResponse>(
    "/v1/student/favorites.json"
  );
};

const postStudentFavorite = (data: { m_id: string }) => {
  return apiClient.post<APIResponse<{}>>("/v1/student/favorite.json", {
    ...data,
  });
};

const deleteStudentFavorite = (data: { m_id: string }) => {
  return apiClient.delete<APIResponse<{}>>("/v1/student/favorite.json", {
    params: data,
  });
};

// Load transactions
export type APILoadTransactionsInput = {
  start_date?: string;
  end_date?: string;
  sort_order?: "asc" | "desc";
  items_per_page?: number;
  page_number?: number;
};

export type APILoadTransactionsResponse = APIResponse<{
  data: {
    loadTransactions: Array<LoadTransactionEntity>;
  };
}>;

const loadTransactions = (data: APILoadTransactionsInput) => {
  return apiClient.get<APILoadTransactionsResponse>(
    "/v1/student/loadtransactions.json",
    {
      params: data,
    }
  );
};

// Load details transaction
export type APILoadDetailsTransactionResponse = APIResponse<{
  data: LoadTransactionEntity;
}>;

const loadDetailsTransaction = (data: { order_number: string }) => {
  return apiClient.get<APILoadDetailsTransactionResponse>(
    "/v1/student/loadtransaction.json",
    {
      params: data,
    }
  );
};

// Get transactions
export type APIGetTransactionsInput = {
  start_date?: string;
  end_date?: string;
  sort_order?: "asc" | "desc";
  items_per_page?: number;
  page_number?: number;
};

export type APIGetTransactionsResponse = APIResponse<{
  data: {
    transactions: Array<TransactionEntity>;
  };
}>;

const getTransactions = (data: APIGetTransactionsInput) => {
  return apiClient.get<APIGetTransactionsResponse>(
    "/v1/student/transactions.json",
    {
      params: data,
    }
  );
};

// Change PIN card
export type APIChangePINCardInput = {
  reset_pin: string;
  confirm_reset_pin: string;
};

const changePINCard = (data: APIChangePINCardInput) => {
  return apiClient.post<APIResponse<{}>>("/v1/student/pin.json", {
    ...data,
  });
};

// Add existing card
export type APIAddExisingCardInput = {
  CardNumber: string;
  PIN: string;
};

const addExistingCard = (data: APIAddExisingCardInput) => {
  return apiClient.post<APIResponse<{}>>("/v1/student/addexistingcard.json", {
    ...data,
  });
};

// Refer a Friend
export type APIReferFriendInput = {
  friend_email: string;
  friend_name: string;
  message?: string;
};

const referFriend = (data: APIReferFriendInput) => {
  return apiClient.post<APIResponse<{}>>("/v1/student/referafriend.json", {
    ...data,
  });
};

// Reissue Card
export type APIReissueCardInput = {
  reason: string;
  PIN: string;
  Mailto: string;
  Addr_A: string;
  Addr_B: string;
  City: string;
  State: string;
  Zip: string;
};

export type APIReissueCardResponse = APIResponse<{
  data: {
    CardNumber: string;
    TempCardNumber: string;
  };
}>;

const reissueCard = (data: APIReissueCardInput) => {
  return apiClient.post<APIReissueCardResponse>(
    "/v1/student/reissuecard.json",
    {
      ...data,
    }
  );
};
//student setting
export type APIGetSettingSMSResponse = APIResponse<{
  data: {
    sms_number: string;
    enabled: string;
  };
}>;
const getSettingSMS = () => {
  return apiClient.get<APIGetSettingSMSResponse>(
    "/v1/student/settings/sms.json"
  );
};

export type APIPostSettingSMSResponse = APIResponse<{
  data: {
    sms_number: string;
    enabled: number;
  };
}>;
export type APIPostSettingSMSInput = {
  sms_number?: string;
  sms_enabled?: string;
  sms_consent?: string;
};
const postSettingSMS = (data: APIPostSettingSMSInput) => {
  return apiClient.post<APIPostSettingSMSResponse>(
    "/v1/student/settings/sms.json",
    {
      ...data,
    }
  );
};

//student qrcode
export type APIGetQRCodeResponse = APIResponse<{
  data: {
    qr_code: string;
  };
}>;

const getQRCode = () => {
  return apiClient.get<APIGetQRCodeResponse>("/v1/student/qrcode.json");
};

// List Messages
export interface MessageEntity {
  Stamp: string;
  message_type: string;
  message: string;
}

export type APIListMessagesResponse = APIResponse<{
  data: {
    transactions: Array<MessageEntity>;
  };
}>;

const listMessages = () => {
  return apiClient.get<APIListMessagesResponse>("/v1/student/messages.json");
};

export type PurchaseEntity = {
  first_name?: string;
  last_name?: string;
  EmailAddress?: string;
  HomeAddr_A?: string;
  HomeAddr_B?: string;
  HomeCity?: string;
  HomeState?: string;
  HomeZip?: string;
  HomePhone?: string;
  SchoolAddr_A?: string;
  SchoolAddr_B?: string;
  SchoolCity?: string;
  SchoolState?: string;
  SchoolZip?: string;
  SchoolPhone?: string;
  BillingFirstName?: string;
  BillingLastName?: string;
  BillingAddr_A?: string;
  BillingAddr_B?: string;
  BillingCity?: string;
  BillingState?: string;
  BillingZip?: string;
  Mailto?: string;
  additionalMeals?: number;
  plan_id?: number;
  plan_meals_added?: number;
  additional_meals: number;
  amount?: number;
  payment_method?: string;
  GraduationYear?: string;
  PIN?: string;
  name_on_card?: string;
  student_email?: string;
  AuthDataValue?: string;
  AuthDataDescriptor?: string;
  store_payment_info?: number;
  payment_profile_id?: string;
  parent: number;
};

export type APIPurchaseResponse = APIResponse<{
  data: LoadTransactionEntity;
  response_data: {
    approved: boolean;
    response: string;
  };
  errors: Array<string[]>;
}>;

const purchaseMeal = (data: PurchaseEntity) => {
  return apiClient.post<APIPurchaseResponse>("/v1/student/purchase.json", {
    ...data,
  });
};

const studentApi = {
  getStudentData,
  postStudentData,
  getStudentFavorites,
  postStudentFavorite,
  deleteStudentFavorite,
  loadTransactions,
  loadDetailsTransaction,
  getTransactions,
  changePINCard,
  addExistingCard,
  referFriend,
  reissueCard,
  getSettingSMS,
  getQRCode,
  postSettingSMS,
  listMessages,
  purchaseMeal,
};

export default studentApi;
