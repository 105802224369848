import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import { authActions } from "../../state/auth/authSlice";
import { schoolsSelector } from "../../state/school/schoolSelector";
import { listSchools } from "../../state/school/schoolSlice";
import { getStudent } from "../../state/student/studentSlice";
import { generateNext12Years } from "../../utils/datetime";

const listNext12Years = generateNext12Years();

type RegisterFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  school: string;
  expectedGraduationYear: string;
  referral_code?: string;
};

const RegisterFormValidateSchema = Yup.object({
  firstName: Yup.string().required("Please provide a first name."),
  lastName: Yup.string().required("Please provide a last name."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  password: Yup.string()
    .required("Please provide a password.")
    .min(6, "Your password must be at least 6 characters long."),
  confirmPassword: Yup.string()
    .required("Please provide a confirm password.")
    .oneOf([Yup.ref("password")], "Confirm Password must match with Password."),
  school: Yup.string().required("Please provide a school."),
  expectedGraduationYear: Yup.string().required(
    "Please provide an expected graduation year."
  ),
});

const RegisterForm: React.FC<{ handleError: (e: string) => void }> = ({
  handleError,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listSchools());
  }, []);

  const { data: schools } = useSelector(schoolsSelector);

  const formHandlers = useFormik<RegisterFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      school: "",
      expectedGraduationYear: "",
      referral_code: "",
    },
    validationSchema: RegisterFormValidateSchema,
    onSubmit: ({
      firstName,
      lastName,
      email,
      password,
      school,
      expectedGraduationYear,
      referral_code,
    }) => {
      dispatch(
        authActions.register({
          data: {
            first_name: firstName,
            last_name: lastName,
            username: email,
            password: password,
            account_type: "student",
            SchoolID: Number(school),
            GraduationYear: expectedGraduationYear,
            referral_code,
          },
          onComplete: (error, data) => {},
        })
      )
        .unwrap()
        .then((data) => {
          if (data.status) {
            handleError("");
            navigate(APP_ROUTES.Dashboard.path);
            dispatch(getStudent());
            return;
          }
          handleError(
            data?.message || "Something went wrong on our end. Please try again"
          );
          return;
        });
    },
  });

  return (
    <>
      <form id="register-form" onSubmit={formHandlers.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name *</label>
              <input
                type="text"
                name="firstName"
                itemRef="firstName"
                placeholder="First Name"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.firstName}
              />
              {formHandlers.touched.firstName &&
              formHandlers.errors.firstName ? (
                <label className="error">{formHandlers.errors.firstName}</label>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastName">Last Name *</label>
              <input
                type="text"
                name="lastName"
                itemRef="lastName"
                placeholder="Last Name"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.lastName}
              />
              {formHandlers.touched.lastName && formHandlers.errors.lastName ? (
                <label className="error">{formHandlers.errors.lastName}</label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="emailField">Email *</label>
              <input
                type="email"
                name="email"
                id="emailField"
                placeholder="Email"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.email}
              />
              {formHandlers.touched.email && formHandlers.errors.email ? (
                <label className="error">{formHandlers.errors.email}</label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>School *</label>
              <select
                name="school"
                id="school"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.school}
              >
                <option value="" disabled>
                  Select a School
                </option>
                {schools.map((school) => (
                  <option key={school.SchoolID} value={school.SchoolID}>
                    {school.Name}
                  </option>
                ))}
              </select>
              {formHandlers.touched.school && formHandlers.errors.school ? (
                <label className="error">{formHandlers.errors.school}</label>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="year">Expected Graduation Year*</label>
              <select
                name="expectedGraduationYear"
                id="year"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.expectedGraduationYear}
              >
                <option value="" disabled>
                  Select a Year
                </option>
                {listNext12Years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {formHandlers.touched.expectedGraduationYear &&
              formHandlers.errors.expectedGraduationYear ? (
                <label className="error">
                  {formHandlers.errors.expectedGraduationYear}
                </label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="password">Password *</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.password}
              />
              {formHandlers.touched.password && formHandlers.errors.password ? (
                <label className="error">{formHandlers.errors.password}</label>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password *</label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.confirmPassword}
              />
              {formHandlers.touched.confirmPassword &&
              formHandlers.errors.confirmPassword ? (
                <label className="error">
                  {formHandlers.errors.confirmPassword}
                </label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="emailField">Referral Code</label>
              <input
                type="text"
                name="referral_code"
                id="referral_code"
                placeholder="Referral Code"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.referral_code}
              />
            </div>
          </div>
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary btn-block">
            Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
