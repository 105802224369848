import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import { studentActions } from "../../state/student/studentSlice";
import tickAnimate from "../../assets/img/tick-animation.gif";
import useOutsideClick from "../../hooks/useOutsideClick";

type ChangePINFormValues = {
  resetPIN: string;
  confirmResetPIN: string;
};

const ChangePINFormValidateSchema = Yup.object({
  resetPIN: Yup.string().required("Please provide reset PIN."),
  confirmResetPIN: Yup.string()
    .required("Please provide confirm reset PIN.")
    .oneOf(
      [Yup.ref("resetPIN"), null],
      "Confirm reset PIN must match with reset PIN"
    ),
});

const initialValues: ChangePINFormValues = {
  resetPIN: "",
  confirmResetPIN: "",
};

const ChangePINForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [attemptChangeError, setAttemptChangeError] = useState("");
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [attemptChangeSuccess, setAttemptChangeSuccess] = useState("");

  const formRef = useRef(null);

  useOutsideClick(formRef, () => formHandlers.resetForm());

  const formHandlers = useFormik<ChangePINFormValues>({
    initialValues,
    validationSchema: ChangePINFormValidateSchema,
    onSubmit: ({ resetPIN, confirmResetPIN }, actions) => {
      setAttemptChangeError("");
      dispatch(
        studentActions.changePINCard({
          data: {
            reset_pin: resetPIN,
            confirm_reset_pin: confirmResetPIN,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setAttemptChangeError("");
              setChangeSuccess(true);
              setAttemptChangeSuccess(data.message);
              actions.resetForm({
                values: initialValues,
              });
              return;
            }
            setAttemptChangeError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );
            setChangeSuccess(false);
            return;
          },
        })
      );
    },
  });

  return (
    <div
      className="modal fade"
      id="changePin"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={formRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => formHandlers.resetForm()}
            />
          </div>
          <div className="modal-body">
            {changeSuccess ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {attemptChangeSuccess}
                </p>
              </div>
            ) : (
              <div className="form-content">
                <h2 className="xl-title text-center">Change Pin</h2>
                <h6 className="text-center dark-text">
                  Complete the form below to change your pin
                </h6>
                {attemptChangeError && (
                  <label className="error mb-4 text-center w-100">
                    {attemptChangeError}
                  </label>
                )}

                <form
                  className="max-width-350"
                  onSubmit={formHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label>New Pin *</label>
                    <input
                      type="text"
                      name="resetPIN"
                      className="form-control"
                      placeholder="New Pin"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.resetPIN}
                    />
                    {formHandlers.touched.resetPIN &&
                    formHandlers.errors.resetPIN ? (
                      <label className="error">
                        {formHandlers.errors.resetPIN}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>Confirm New Pin *</label>
                    <input
                      type="text"
                      name="confirmResetPIN"
                      className="form-control"
                      placeholder="Confirm New Pin "
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.confirmResetPIN}
                    />
                    {formHandlers.touched.confirmResetPIN &&
                    formHandlers.errors.confirmResetPIN ? (
                      <label className="error">
                        {formHandlers.errors.confirmResetPIN}
                      </label>
                    ) : null}
                  </div>
                  <button type="submit" className="btn btn-primary w100">
                    Change My Pin
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePINForm;
