import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import { authActions } from "../../state/auth/authSlice";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
    navigate(APP_ROUTES.Login.path);
  }, []);
  return null;
};

export default Logout;
