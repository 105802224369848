import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import paymentApi, {
  APIAddStoredPaymentResponse,
  StoredPaymentEntity,
  StoredPaymentOptionsEntity,
} from "../../api/paymentApi";
import { onCompleteCB } from "../../utils/apiClient";
import { APIListStoredPaymentOptionsResponse } from "./../../api/paymentApi";

export const listStoredPaymentOptions = createAsyncThunk<
  APIListStoredPaymentOptionsResponse,
  onCompleteCB<APIListStoredPaymentOptionsResponse> | undefined
>("payment/stored", (onComplete = () => {}) => {
  return paymentApi
    .listStoredPaymentOptions()
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export const addStoredPayment = createAsyncThunk<
  APIAddStoredPaymentResponse,
  {
    data: StoredPaymentEntity;
    onComplete: onCompleteCB<APIAddStoredPaymentResponse>;
  }
>("payment/add", ({ data, onComplete }) => {
  return paymentApi
    .addStoredPayment(data)
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export interface PaymentSate {
  storedPayments: {
    isFetching: boolean;
    data: Array<StoredPaymentOptionsEntity>;
  };
  addPayment: {
    isFetching: boolean;
  };
}

const initialPaymentState: PaymentSate = {
  storedPayments: {
    isFetching: false,
    data: [],
  },
  addPayment: {
    isFetching: false,
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listStoredPaymentOptions.pending, (state) => {
        state.storedPayments.isFetching = true;
      })
      .addCase(listStoredPaymentOptions.fulfilled, (state, action) => {
        state.storedPayments.isFetching = false;
        state.storedPayments.data = action.payload?.payload?.data || [];
      })
      .addCase(listStoredPaymentOptions.rejected, (state) => {
        state.storedPayments.isFetching = false;
      })

      .addCase(addStoredPayment.pending, (state) => {
        state.addPayment.isFetching = true;
      })
      .addCase(addStoredPayment.fulfilled, (state, action) => {
        state.addPayment.isFetching = false;
      })
      .addCase(addStoredPayment.rejected, (state) => {
        state.addPayment.isFetching = false;
      });
  },
});

export const paymentReducer = paymentSlice.reducer;

export const paymentActions = {
  ...paymentSlice.actions,
  listStoredPaymentOptions,
  addStoredPayment,
};
