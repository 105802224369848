import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CardEntity, StudentEntity } from "../../api/studentApi";
import { APP_ROUTES } from "../../app/routes";
import { Spinner } from "reactstrap";
import { formatNumber } from "../../utils/string";
import PrintTempCard from "./PrintTempCard";
import Tippy from "@tippyjs/react";
type CardProps = {
  cardData: CardEntity | undefined;
  cardLoading?: boolean;
  studentData: StudentEntity | undefined;
  className?: string;
};

const Card: React.FC<CardProps> = ({
  cardData,
  cardLoading,
  studentData,
  className,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (cardData?.CardNumber === "" && location.pathname === "/my-card") {
      navigate("/my-card-add-funds");
    }
  }, [cardData]);

  return (
    <div className={className ? `${className} card` : "card"}>
      {cardLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner type="border" color="primary" />
        </div>
      ) : (
        <React.Fragment>
          {studentData?.SpecialProgram === "0" ? (
            <React.Fragment>
              <h4 className="md-title text-uppercase d-flex flex-wrap align-items-center justify-content-between">
                {(cardData?.TempCardEnabled === "0" ||
                cardData?.TempCardEnabled === "1") ? (
                  "My Card"
                ) : (
                  <div
                    className="text-danger mb-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-html="true"
                  >
                    Temporary Card
                    <Tippy content="This is your temporary card.">
                      <img src="img/warning.svg" alt="" className="v-bottom" />
                    </Tippy>
                  </div>
                )}
                <div className="price">
                  {/*formatNumber(parseFloat(cardData?.Balance.Amount || "0"))*/}{" "}
                  {cardData?.PaymentStatus !== "PAID" && (
                    <div className="float-end">
                      <Tippy content="Pending Reload">
                        <img
                          src="img/warning.svg"
                          alt=""
                          className="v-bottom"
                        />
                      </Tippy>
                    </div>
                  )}
                </div>

                <Link
                  to={APP_ROUTES.MyCardAddFunds.path}
                  className="btn btn-primary d-flex align-items-center justify-content-center py-1 px-2 px-md-4 text-capitalize"
                >
                  <span className="me-1">Add Funds</span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 12H20.25"
                      stroke="#ffffff"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 3.75V20.25"
                      stroke="#ffffff"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </h4>
              <div className="remaining_meal">
                <span>{cardData?.Balance.Meals || 0}</span>
                Meals Remaining
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h4 className="md-title text-uppercase">
                {cardData?.TempCardEnabled === "0" ||
                cardData?.TempCardEnabled === "1" ? (
                  "My Card"
                ) : (
                  <div
                    className="text-danger d-flex align-items-center justify-content-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-html="true"
                  >
                    Temporary Card
                    <Tippy content="Use this card number with your PIN while your permanent card is in transit.">
                      <img src="img/warning.svg" alt="" className="v-bottom" />
                    </Tippy>
                  </div>
                )}
                <div className="price">
                  <span>{cardData?.Balance.Meals} Meals</span>
                  {cardData?.PaymentStatus !== "PAID" && (
                    <div
                      className="float-end"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      data-bs-html="true"
                    >
                      <Tippy content="Pending Reload.">
                        <img
                          src="img/warning.svg"
                          alt=""
                          className="v-bottom"
                        />
                      </Tippy>
                    </div>
                  )}
                </div>
              </h4>
              <div className="remaining_meal my-70">
                <span>${cardData?.Balance.Amount}</span>
                Balance Remaining
              </div>
            </React.Fragment>
          )}
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="cardDetail me-2">
              <div className="cardName">{`${studentData?.name_on_card}`}</div>
              <div className="cardNumber mt-2">
                {cardData?.TempCardEnabled === "1"
                  ? cardData?.TempCardNumber
                  : cardData?.CardNumber}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <PrintTempCard cardData={cardData} studentData={studentData} />
    </div>
  );
};

export default Card;
