import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import { studentActions } from "../../state/student/studentSlice";
import tickAnimate from "../../assets/img/tick-animation.gif";

type AddExistingCardFormValues = {
  cardNumber: string;
  PIN: string;
};

const AddExistingCardFormValidateSchema = Yup.object({
  cardNumber: Yup.string().required("Please provide card number."),
  PIN: Yup.string().required("Please provide PIN number."),
});

const initialValues: AddExistingCardFormValues = {
  cardNumber: "",
  PIN: "",
};

const AddExistingCardForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [attemptAddError, setAttemptAddError] = useState("");

  const formHandlers = useFormik<AddExistingCardFormValues>({
    initialValues,
    validationSchema: AddExistingCardFormValidateSchema,
    onSubmit: ({ cardNumber, PIN }) => {
      dispatch(
        studentActions.addExistingCard({
          data: {
            CardNumber: cardNumber,
            PIN,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              window.location.reload();
              return;
            }
            setAttemptAddError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );

            return;
          },
        })
      );
    },
  });

  return (
    <div className="modal fade" id="addExistingCard" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-content">
              <h2 className="xl-title text-center">Add Existing OCMP Card</h2>
              <h6 className="text-center dark-text">
                Fill out the form below to add an existig OCMP card <br />
                to your account.
              </h6>
              {attemptAddError && (
                <label className="error mb-1 text-center w-100">
                  {attemptAddError}
                </label>
              )}
              <form
                className="max-width-350"
                id="addCard"
                onSubmit={formHandlers.handleSubmit}
              >
                <div className="form-group">
                  <label>Card number *</label>
                  <input
                    type="text"
                    name="cardNumber"
                    className="form-control"
                    placeholder="Card number"
                    onChange={formHandlers.handleChange}
                    onBlur={formHandlers.handleBlur}
                    value={formHandlers.values.cardNumber}
                  />
                  {formHandlers.touched.cardNumber &&
                  formHandlers.errors.cardNumber ? (
                    <label className="error">
                      {formHandlers.errors.cardNumber}
                    </label>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Pin *</label>
                  <input
                    type="text"
                    name="PIN"
                    className="form-control"
                    placeholder="Pin"
                    onChange={formHandlers.handleChange}
                    onBlur={formHandlers.handleBlur}
                    value={formHandlers.values.PIN}
                  />
                  {formHandlers.touched.PIN && formHandlers.errors.PIN ? (
                    <label className="error">{formHandlers.errors.PIN}</label>
                  ) : null}
                </div>
                <button type="submit" className="btn btn-primary w100">
                  Add My Card
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExistingCardForm;
