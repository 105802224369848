import Tippy from "@tippyjs/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { RestaurantEntity } from "../../api/restaurantApi";
import { hoursOperation } from "../../utils/datetime";
import { getLogoUrl } from "../../utils/logo";
import { isHttp, toPhoneNumber } from "../../utils/string";
import FavoriteCheckbox from "./FavoriteCheckbox";

type RestaurantListProps = {
  restaurantData: Array<RestaurantEntity>;
  setMID: any;
  handleChangeFavorite: any;
  isFetching?: boolean;
  myFavoriteRef?: any;
};

const RestaurantListView: React.FC<RestaurantListProps> = ({
  restaurantData,
  setMID,
  handleChangeFavorite,
  isFetching,
  myFavoriteRef,
}) => {
  return (
    <div className="bg-white shadow-sm py-1 px-md-3 py-md-3 border-none rounded-3 d-flex justify-content-between flex-column">
      <table className="restaurants_table my-md-4">
        <tbody>
          {isFetching && (
            <div className="d-flex w-100 align-items-center justify-content-center my-2">
              <Spinner type="border" color="primary" />
            </div>
          )}
          {!isFetching &&
            restaurantData.length > 0 &&
            restaurantData.map((restaurant, key) => {
              return (
                <tr key={key}>
                  <td className="align-middle">
                    <div className="restaurant_name">
                      <img
                        src={getLogoUrl(restaurant.Logo)}
                        alt="..."
                        className="img-fluid"
                      />
                      <div className="w-100">
                        <p className="d-none d-md-block">{restaurant.Type}</p>
                        <h5 className="mb-0">
                          {restaurant.Name}
                          {restaurant.Delivery === "1" && (
                            <Tippy content="This restaurant offers delivery.">
                              <Link
                                to="#delivery"
                                className="me-3 d-inline-block d-md-none deliveryAvailable"
                              >
                                <img src="img/delivery.svg" alt="" />
                              </Link>
                            </Tippy>
                          )}
                        </h5>
                        <div className="d-flex flex-wrap justify-content-between d-md-none w-100">
                          <span>{restaurant.Addr_A}</span>
                          <span>
                            {hoursOperation(
                              restaurant.OpenTime,
                              restaurant.CloseTime
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {restaurant.Delivery === "1" && (
                      <Tippy content="This restaurant offers delivery.">
                        <Link to="#delivery" className="me-3 d-none d-md-block">
                          <img src="img/delivery.svg" alt="" />
                        </Link>
                      </Tippy>
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-start">
                      <img src="img/map-marker.svg" alt="" className="me-2" />
                      <div>
                        <span>{restaurant.Addr_A}</span>
                        <br />
                        {restaurant.Addr_B !== "" &&
                          restaurant.Addr_B !== null && (
                            <>
                              {<span>{restaurant.Addr_B}</span>}
                              <br />
                            </>
                          )}
                        <span>{`${restaurant.City}, ${restaurant.State} ${restaurant.Zip}`}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {restaurant.Phone && (
                      <a
                        className="d-flex align-items-center mb-3"
                        href={`tel:${restaurant.Phone}`}
                      >
                        <img src="img/phone-call.svg" alt="" className="me-2" />
                        {toPhoneNumber(restaurant.Phone)}
                      </a>
                    )}

                    <div className="d-flex align-items-center text-nowrap">
                      <img src="img/clock.svg" alt="" className="me-2" />
                      {hoursOperation(
                        restaurant.OpenTime,
                        restaurant.CloseTime
                      )}
                    </div>
                  </td>
                  <td>
                    {restaurant.Web && (
                      <a
                        href={
                          !isHttp(restaurant.Web)
                            ? restaurant.Web
                            : `https://${restaurant.Web}`
                        }
                        className="btn btn-outline d-block"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit Website
                      </a>
                    )}
                    <Link
                      to="#moreDetail"
                      data-bs-toggle="modal"
                      className="btn btn-outline d-block"
                      onClick={() => setMID(String(restaurant.M_ID))}
                    >
                      More Details
                    </Link>
                  </td>
                  <td
                    className={
                      "d-md-none d-inline-block " +
                      (restaurant.Web ? "" : "w-100")
                    }
                  >
                    <a href={`tel:${restaurant.Phone}`} className="btn-contact">
                      Call <img src="img/phone.svg" alt="" />
                    </a>
                  </td>
                  <td className="d-md-none d-inline-block">
                    {restaurant.Web && (
                      <a href={restaurant.Web} className="btn-contact">
                        Website <img src="img/web.svg" alt="..." />
                      </a>
                    )}
                  </td>
                  <td className="d-md-none d-inline-block p-0 w-100 mt-3 pr-0">
                    <Link
                      to="#moreDetail"
                      data-bs-toggle="modal"
                      className="btn-contact"
                      onClick={() => setMID(String(restaurant.M_ID))}
                    >
                      More Details
                    </Link>
                  </td>
                  <td>
                    <FavoriteCheckbox
                      studentFavorite={restaurant.student_favorite}
                      mID={String(restaurant.M_ID)}
                      checkboxID={`list${String(restaurant.M_ID)}`}
                      handleChangeFavorite={handleChangeFavorite}
                    />
                  </td>
                </tr>
              );
            })}
          {!isFetching && restaurantData.length === 0 && (
            <div className="">
              <div className="no-restaurant p-5">
                <h5 className="text-center mb-4">
                  No restaurants have been found
                </h5>
                <Button
                  onClick={() => myFavoriteRef.current.click()}
                  className="btn btn-primary w-100"
                >
                  View All Restaurants
                </Button>
              </div>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RestaurantListView;
