import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mealPlanApi, {
  APIListMealPlanResponse,
  MealPlanEntity,
} from "../../api/mealPlanApi";
import { onCompleteCB } from "../../utils/apiClient";

export const listMealPlans = createAsyncThunk<
  APIListMealPlanResponse,
  {
    data: { SchoolID?: string };
    onComplete?: onCompleteCB<APIListMealPlanResponse>;
  }
>("mealplan/list", (thunkArg) => {
  return mealPlanApi
    .listMealPlans(thunkArg.data.SchoolID)
    .then(({ data }) => {
      thunkArg.onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      thunkArg.onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export interface MealPlanState {
  mealPlans: {
    isFetching: boolean;
    data: Array<MealPlanEntity>;
  };
}

const initialMealPlanState: MealPlanState = {
  mealPlans: {
    isFetching: false,
    data: [],
  },
};

const mealPlanSlice = createSlice({
  name: "mealplan",
  initialState: initialMealPlanState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listMealPlans.pending, (state) => {
        state.mealPlans.isFetching = true;
      })
      .addCase(listMealPlans.fulfilled, (state, action) => {
        state.mealPlans.isFetching = false;
        state.mealPlans.data = action.payload.payload.data;
      })
      .addCase(listMealPlans.rejected, (state) => {
        state.mealPlans.isFetching = false;
      });
  },
});

export const mealPlanReducer = mealPlanSlice.reducer;

export const mealPlanActions = {
  ...mealPlanSlice.actions,
  listMealPlans,
};
