import React, { useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import * as Yup from "yup";
import { restaurantActions } from "../../state/restaurant/restaurantSlice";
import { useFormik } from "formik";
import tickAnimate from "../../assets/img/tick-animation.gif";
import useOutsideClick from "../../hooks/useOutsideClick";

type RequestRestaurantFormValues = {
  restaurant_name: string;
  restaurant_address: string;
  restaurant_phone: string;
  restaurant_url: string;
};

interface ModalProps {
  email: string;
}

const RequestRestaurantFormValidateSchema = Yup.object({
  restaurant_name: Yup.string().required("Please provide a name."),
  restaurant_address: Yup.string().required("Please provide an address."),
  restaurant_phone: Yup.string().required("Please provide a phone number."),
  restaurant_url: Yup.string().required("Please provide a website url."),
});

const initialValues: RequestRestaurantFormValues = {
  restaurant_name: "",
  restaurant_address: "",
  restaurant_phone: "",
  restaurant_url: "",
};

const SuggestRestaurantModal: React.FC<ModalProps> = ({ email }) => {
  const dispatch = useAppDispatch();
  const [attemptRequestError, setAttemptRequestError] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [attemptRequestSuccess, setAttemptRequestSuccess] = useState("");

  const formRef = useRef(null);

  useOutsideClick(formRef, () => {
    setRequestSuccess(false);
    setAttemptRequestError("");
    setAttemptRequestSuccess("");
    formHandlers.resetForm();
  });

  const formHandlers = useFormik<RequestRestaurantFormValues>({
    initialValues,
    validationSchema: RequestRestaurantFormValidateSchema,
    onSubmit: (
      { restaurant_name, restaurant_address, restaurant_phone, restaurant_url },
      actions
    ) => {
      dispatch(
        restaurantActions.requestRestaurant({
          data: {
            restaurant_name,
            email_address: email,
            restaurant_address,
            restaurant_phone,
            restaurant_url,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setAttemptRequestError("");
              setRequestSuccess(true);
              setAttemptRequestSuccess(data.message);
              return;
            }
            return setAttemptRequestError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );
          },
        })
      );
    },
  });

  return (
    <div
      className="modal fade"
      id="suggestRestaurant"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={formRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body pt-0">
            {requestSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {attemptRequestSuccess}
                </p>
              </div>
            ) : (
              <>
                <h2 className="xl-title text-center mb-30">
                  Suggest A Restaurant
                </h2>
                {attemptRequestError && (
                  <label className="error mb-1 text-center">
                    {attemptRequestError}
                  </label>
                )}
                <form
                  className="max-width-350"
                  id="reportCard"
                  noValidate
                  onSubmit={formHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="name">Restaurant Name*</label>
                    <input
                      type="text"
                      id="restaurant_name"
                      name="restaurant_name"
                      className="form-control"
                      placeholder="Restaurant Name"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.restaurant_name}
                    />
                    {formHandlers.touched.restaurant_name &&
                    formHandlers.errors.restaurant_name ? (
                      <label className="error">
                        {formHandlers.errors.restaurant_name}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Address*</label>
                    <input
                      type="text"
                      id="restaurant_address"
                      name="restaurant_address"
                      className="form-control"
                      placeholder="Restaurant Address"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.restaurant_address}
                    />
                    {formHandlers.touched.restaurant_address &&
                    formHandlers.errors.restaurant_address ? (
                      <label className="error">
                        {formHandlers.errors.restaurant_address}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Phone*</label>
                    <input
                      type="text"
                      id="restaurant_phone"
                      name="restaurant_phone"
                      className="form-control"
                      placeholder="Restaurant Phone Number"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.restaurant_phone}
                    />
                    {formHandlers.touched.restaurant_phone &&
                    formHandlers.errors.restaurant_phone ? (
                      <label className="error">
                        {formHandlers.errors.restaurant_phone}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Website*</label>
                    <input
                      type="text"
                      id="restaurant_url"
                      name="restaurant_url"
                      className="form-control"
                      placeholder="Restaurant Website"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.restaurant_url}
                    />
                    {formHandlers.touched.restaurant_url &&
                    formHandlers.errors.restaurant_url ? (
                      <label className="error">
                        {formHandlers.errors.restaurant_url}
                      </label>
                    ) : null}
                  </div>
                  <button type="submit" className="btn btn-primary w100">
                    Submit
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestRestaurantModal;
