import React from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import ForgotPasswordForm from "../../modules/auth/ForgotPasswordForm";
import { Helmet } from "react-helmet";
const ForgotPassword = () => {
  return (
    <AuthLayout
      pageName="auth-forgot-password"
      navItem={{ url: "/register", text: "Create Account" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password | OCMP</title>
      </Helmet>
      <section>
        <div className="authentication_box">
          <ForgotPasswordForm />
        </div>
      </section>
    </AuthLayout>
  );
};

export default ForgotPassword;
