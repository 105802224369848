import React from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import RegisterStudentForm from "../../modules/student/RegisterStudentForm";

const DashboardRegister = () => {
  return (
    <DashboardLayout>
      {/* breadcrumb */}
      <section className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active" aria-current="page">
                    Complete Registration
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* dashboard register */}
      <section className="dashboard-register">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="lg-title">Hi Brandon Blau</h2>
              <p className="dark-text">
                Fill out the information below to complete your registration.
              </p>
              <RegisterStudentForm />
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default DashboardRegister;
