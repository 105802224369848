import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { restaurantDetailsSelector } from "../../state/restaurant/restaurantSelector";
import { getDetails } from "../../state/restaurant/restaurantSlice";
import { hoursOperation } from "../../utils/datetime";
import { getLogoUrl } from "../../utils/logo";
import { isHttp, isUrl } from "../../utils/string";
import FavoriteCheckbox from "./FavoriteCheckbox";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

type RestaurantDetailsProps = {
  isOpen?: boolean;
  toggle?: () => void;
  m_id: string;
  setMID?: React.Dispatch<React.SetStateAction<string>>;
  handleChangeFavorite?: any;
  handleClose?: any;
};
const RestaurantDetailsModal: React.FC<RestaurantDetailsProps> = ({
  isOpen,
  toggle,
  m_id,
  setMID,
  handleChangeFavorite,
  handleClose,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || "",
  });
  const dispatch = useAppDispatch();
  const detailsState = useSelector(restaurantDetailsSelector);
  const restaurantData = detailsState.data?.data[0].restaurantData;
  const restaurantSpecials = detailsState.data?.data[0].restaurantSpecials;

  useEffect(() => {
    if (m_id) {
      dispatch(getDetails({ data: { m_id } }));
    }
  }, [m_id]);
  const dayNow = new Date().getDay();

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      if (restaurantData) {
        const resPos = new google.maps.LatLng({
          lat: Number(restaurantData?.Latitude),
          lng: Number(restaurantData?.Longitude),
        });
        map.setCenter(resPos);
        map.setZoom(18);
      }
      setMap(map);
    },
    [restaurantData]
  );
  useEffect(() => {
    return () => {
      setMID && setMID("");
      toggle && toggle();
      handleClose && handleClose();
    };
  }, []);
  return (
    <Modal
      className="modal-dialog modal-dialog-centered moredetails__modal"
      isOpen={isOpen}
      toggle={toggle}
    >
      {!detailsState.isFetching && (
        <>
          <ModalHeader
            className="position-absolute top-0 end-0"
            close={
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setMID && setMID("");
                }}
              />
            }
          />
          <ModalBody className="pb-4">
            {isLoaded && restaurantData && (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "204px",
                }}
                center={{
                  lat: Number(restaurantData.Latitude),
                  lng: Number(restaurantData.Longitude),
                }}
                onLoad={onLoad}
                options={{
                  disableDefaultUI: true,
                }}
              >
                <Marker
                  clickable={false}
                  icon={{
                    url: "img/blueplace.svg",
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  position={{
                    lat: Number(restaurantData.Latitude),
                    lng: Number(restaurantData.Longitude),
                  }}
                />
              </GoogleMap>
            )}
            <div className="restaurant_name restaurant_modal mt-4">
              <div className="d-flex align-items-center">
                <img
                  src={getLogoUrl(restaurantData?.Logo)}
                  alt="..."
                  className="img-fluid"
                />
                <div>
                  <p>{restaurantData?.Type}</p>
                  <h5 className="mb-0">{restaurantData?.Name}</h5>
                  {restaurantData?.Slogan !== "" && (
                    <div className="active-card-toast d-none d-md-block">
                      <span>{restaurantData?.Slogan}</span>
                    </div>
                  )}
                </div>
              </div>

              <FavoriteCheckbox
                studentFavorite={restaurantData?.student_favorite}
                mID={String(restaurantData?.M_ID)}
                checkboxID={`modal${String(restaurantData?.M_ID)}`}
                handleChangeFavorite={handleChangeFavorite}
              />
            </div>
            {restaurantData?.Slogan !== "" && (
              <div className="active-card-toast d-inline-block mt-3 d-md-none">
                <span>{restaurantData?.Slogan}</span>
              </div>
            )}
            <div className="row f14" style={{ marginTop: "40px" }}>
              <div className="col-md-6">
                <div className="d-flex align-items-start">
                  <img src="img/map-marker.svg" className="me-2" alt="" />
                  <div className="d-flex flex-column justify-content-center">
                    <span>{restaurantData?.Addr_A} </span>
                    {restaurantData?.Addr_B && (
                      <span>{restaurantData?.Addr_B}</span>
                    )}
                    <span>{`${restaurantData?.City}, ${restaurantData?.State} ${restaurantData?.M_ID}`}</span>
                  </div>
                </div>
                <div className="d-flex d-md-none mt-4 align-items-start">
                  <img src="img/clock.svg" className="me-2" alt="" />
                  <div>
                    Hours Of Operation: <br className="d-block" />
                    <span className={dayNow === 0 ? "fw-bold" : ""}>
                      Monday:
                      {hoursOperation(
                        restaurantData?.MonStart,
                        restaurantData?.MonEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 1 ? "fw-bold" : ""}>
                      Tuesday:
                      {hoursOperation(
                        restaurantData?.TueStart,
                        restaurantData?.TueEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 2 ? "fw-bold" : ""}>
                      Wednesday:
                      {hoursOperation(
                        restaurantData?.WedStart,
                        restaurantData?.WedEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 3 ? "fw-bold" : ""}>
                      Thursday:
                      {hoursOperation(
                        restaurantData?.ThuStart,
                        restaurantData?.ThuEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 4 ? "fw-bold" : ""}>
                      Friday:
                      {hoursOperation(
                        restaurantData?.FriStart,
                        restaurantData?.FriEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 5 ? "fw-bold" : ""}>
                      Saturday:
                      {hoursOperation(
                        restaurantData?.SatStart,
                        restaurantData?.SatEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 6 ? "fw-bold" : ""}>
                      Sunday:
                      {hoursOperation(
                        restaurantData?.SunStart,
                        restaurantData?.SunEnd
                      )}
                    </span>
                  </div>
                </div>
                <div className="mt-md-5 mt-4">
                  {restaurantData?.Phone && (
                    <a
                      className="d-flex align-items-center mb-2"
                      href={`tel:${restaurantData?.Phone}`}
                    >
                      <img src="img/phone-call.svg" alt="" className="me-2" />
                      {restaurantData?.Phone}
                    </a>
                  )}

                  <div className="d-flex align-items-center">
                    <img src="img/delivery.svg" alt="" className="me-2" />
                    Delivery: {restaurantData?.Delivery === "1" ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-md-0 mt-3 d-none d-md-block">
                <div className="d-flex align-items-start">
                  <img src="img/clock.svg" className="me-2" alt="" />
                  <div className="text-nowrap">
                    Hours Of Operation: <br className="d-block" />
                    <span className={dayNow === 1 ? "fw-bold" : ""}>
                      Monday:
                      {hoursOperation(
                        restaurantData?.MonStart,
                        restaurantData?.MonEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 2 ? "fw-bold" : ""}>
                      Tuesday:
                      {hoursOperation(
                        restaurantData?.TueStart,
                        restaurantData?.TueEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 3 ? "fw-bold" : ""}>
                      Wednesday:
                      {hoursOperation(
                        restaurantData?.WedStart,
                        restaurantData?.WedEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 4 ? "fw-bold" : ""}>
                      Thursday:
                      {hoursOperation(
                        restaurantData?.ThuStart,
                        restaurantData?.ThuEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 5 ? "fw-bold" : ""}>
                      Friday:
                      {hoursOperation(
                        restaurantData?.FriStart,
                        restaurantData?.FriEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 6 ? "fw-bold" : ""}>
                      Saturday:
                      {hoursOperation(
                        restaurantData?.SatStart,
                        restaurantData?.SatEnd
                      )}
                      <br className="d-block" />
                    </span>
                    <span className={dayNow === 0 ? "fw-bold" : ""}>
                      Sunday:
                      {hoursOperation(
                        restaurantData?.SunStart,
                        restaurantData?.SunEnd
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {Array.isArray(restaurantSpecials) &&
              restaurantSpecials.length > 0 && (
                <div>
                  <p className="f14 dark-text mb-1">Specials:</p>
                  {restaurantSpecials.map((item, key) => {
                    return (
                      <div key={key} className="d-flex justify-content-between">
                        <div>
                          <p className="f14 black-text mb-0 d-none d-md-block">
                            {item.SpecialTitle}
                          </p>
                          <p className="f14 black-text mb-0 d-block d-md-none">
                            {item.SpecialTitle}
                          </p>
                          <p className="f14 black-text">
                            <b>Exp: </b> {item.SpecialValidTo}
                          </p>
                        </div>
                        <p className="f14 black-text">{item.SpecialPricing}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            {restaurantData?.Web && isUrl(restaurantData.Web) && (
              <a
                href={
                  !isHttp(restaurantData.Web)
                    ? restaurantData.Web
                    : `https://${restaurantData.Web}`
                }
                className="btn btn-primary d-block f400"
                target="_blank"
                rel="noreferrer"
              >
                Visit Website
              </a>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default RestaurantDetailsModal;
