import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ProgramEntity } from "../../api/programApi";
import { CardEntity, StudentEntity } from "../../api/studentApi";
import { TransactionEntity } from "../../api/transactionApi";
import { APP_ROUTES } from "../../app/routes";
import { convertCurrency, convertNegativeMealAmount, toNumberWithComma } from "../../utils/string";
import Card from "../mycard/Card";
import ProgramCard from "../mycard/ProgramCard";
import PayWithQR from "../mycard/PayWithQR";

type TransactionListProps = {
  alertData: any[] | null | undefined;
  cardData: CardEntity | undefined;
  cardLoading?: boolean;
  studentData: StudentEntity | undefined;
  transactions: Array<TransactionEntity> | undefined;
  transactionsLoading?: boolean;
  programData?: ProgramEntity;
};

const TransactionList: React.FC<TransactionListProps> = ({
  alertData,
  cardData,
  cardLoading,
  studentData,
  transactions = [],
  transactionsLoading,
  programData,
}) => {
  const navigate = useNavigate();
  return (
    <section className="transaction">
      <div className="container">
        <div className="row col-md-12 gy-4 mt-1 mt-md-0">
          {alertData &&
            alertData?.length > 0 &&
            alertData.map((alert) => (
              <div className="alert min-alert col-3" key={alert.id}>
                <svg
                  width={14}
                  height={20}
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8593 9.23416C13.8361 9.13342 13.7883 9.04 13.7201 8.96229C13.652 8.88457 13.5656 8.82498 13.4687 8.78885L8.96871 7.10135L10.1093 1.37479C10.1356 1.24214 10.1186 1.10457 10.061 0.982256C10.0033 0.85994 9.90802 0.759304 9.78902 0.695098C9.66884 0.631171 9.5309 0.608767 9.39667 0.631375C9.26243 0.653983 9.13944 0.720333 9.04683 0.820098L0.296832 10.1951C0.225135 10.2695 0.173259 10.3607 0.145959 10.4603C0.11866 10.56 0.116811 10.6649 0.140582 10.7654C0.165041 10.8656 0.213326 10.9584 0.281333 11.0359C0.349341 11.1135 0.435061 11.1734 0.531207 11.2107L5.03121 12.8982L3.89058 18.6248C3.86435 18.7574 3.8813 18.895 3.93894 19.0173C3.99658 19.1396 4.09189 19.2403 4.21089 19.3045C4.3006 19.3497 4.39949 19.3738 4.49996 19.3748C4.58486 19.3751 4.66892 19.358 4.74689 19.3244C4.82485 19.2908 4.89505 19.2414 4.95308 19.1795L13.7031 9.80447C13.7748 9.73009 13.8267 9.63889 13.854 9.53925C13.8813 9.4396 13.8831 9.3347 13.8593 9.23416Z"
                    fill="#EE3D42"
                  />
                </svg>
                {alert.content}
                <Link to="/">More Information</Link>
              </div>
            ))}
        </div>
        <div className="row">
          <div className="col-lg-6 d-flex flex-column gap-4 gap-xs mb-xs-3">
            <Card
              cardData={cardData}
              cardLoading={cardLoading}
              studentData={studentData}
              className="min-h-0"
            />
             <Link
              to="#payWithQR"
              data-bs-toggle="modal"
              className="btn btn-primary w100"
              >
                Pay with QR Code
              </Link>
          </div>
          <div className="col-lg-6">
            {studentData?.SpecialProgram === "1" && programData ? (
              <ProgramCard
                programData={programData}
                amountProvided={cardData?.Balance.StartingAmount}
              />
            ) : (
              <div className="card justify-content-start">
                <h4 className="md-title text-uppercase">Recent Transactions</h4>
                {transactions?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table transaction_table">
                        <thead>
                          <tr>
                            <th>Merchant </th>
                            <th>DATE </th>
                            <th>$ Amount ( # Meals) </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.slice(0, 5).map((transaction) => {
                            const total =
                              Number(transaction.Amount || 0) +
                              Number(transaction.TipAmount || 0);
                            const mealTotal =
                              Number(transaction.Meals || 0) +
                              Number(transaction.TipMeals || 0);
                            return (
                              <tr
                                key={transaction.Stamp}
                                onClick={() =>
                                  navigate("/transactions/purchases")
                                }
                              >
                                <td>{transaction.MerchantName}</td>
                                <td>
                                  {moment(transaction.Stamp).format(
                                    "MM.DD.YYYY"
                                  )}
                                </td>
                                <td>
                                  {convertCurrency(total)}
                                  <span> ({convertNegativeMealAmount(mealTotal.toFixed(2))})</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {transactionsLoading && (
                      <div className="d-flex w-100 align-items-center justify-content-center">
                        <Spinner type="border" color="primary" />
                      </div>
                    )}
                    <Link
                      to={APP_ROUTES.Transactions.path}
                      className="btn btn-outline mt-0 mt-md-auto"
                    >
                      View All Transactions
                    </Link>
                  </>
                ) : (
                  <div className="fs-5 d-flex justify-content-center align-items-center h-100">
                    You have no recent transactions.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <PayWithQR />
    </section>
  );
};

export default TransactionList;
