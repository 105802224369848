import moment from "moment";
import React, { useEffect, useRef } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Spinner } from "reactstrap";
import { useAppDispatch } from "../../app/hooks";
import OCMPLogo from "../../assets/img/ocmp.svg";
import BreadCrumbLayout from "../../components/layout/BreadCrumbLayout";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {
  currentStudentSelector,
  transactionsSelector,
} from "../../state/student/studentSelector";
import { getTransactions } from "../../state/student/studentSlice";
import { convertCurrency, toNumberWithComma,
  convertNegativeMealAmount, convertReportCurrency } from "../../utils/string";

const TransactionsMealPurchases: React.FC = () => {
  const dispatch = useAppDispatch();
  const transactionsState = useSelector(transactionsSelector);
  const currentStudentState = useSelector(currentStudentSelector);
  const receiptRef = useRef<HTMLDivElement>(null);

  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(moment().subtract(1, "d").format("YYYY-MM-DD")),
  //   endDate: new Date(),
  //   key: "selection",
  // });
  // const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      getTransactions({
        data: {
          items_per_page: 1000,
          page_number: 1,
        },
      })
    );
  }, []);

  // const showMore = () => {
  //   setPageNumber(pageNumber + 1);
  // };

  // const handleSelect = (ranges?: any) => {
  //   setSelectionRange({
  //     startDate: ranges?.selection.startDate,
  //     endDate: ranges?.selection.endDate,
  //     key: "selection",
  //   });
  //   dispatch(
  //     getTransactions({
  //       data: {
  //         items_per_page: 10,
  //         page_number: pageNumber,
  //         start_date: moment(ranges?.selection.startDate).format("YYYY-MM-DD"),
  //         end_date: moment(ranges?.selection.endDate).format("YYYY-MM-DD"),
  //       },
  //     })
  //   );
  // };
  const pageStyle = `
    @page {
      size: A4;
    }
  `;
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    pageStyle,
  });

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Transactions | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Meal Purchases
        </li>
      </BreadCrumbLayout>
      {/* transaction */}
      <section className="transaction_history">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="row mb-3">
                  <div className="col-md-5">
                    {/* <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="dateRange"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div id="receiptRange">
                        <img src="/img/calendar.svg" alt="" />
                        &nbsp;
                        {`${moment(selectionRange.startDate).format(
                          "MM.DD.YYYY"
                        )} - ${moment(selectionRange.endDate).format(
                          "MM.DD.YYYY"
                        )}`}
                        <span />
                      </div>
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dateRange">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={(selection) => handleSelect(selection)}
                        staticRanges={customRanges}
                      />
                    </ul> */}
                  </div>
                  <div className="col-md-7 text-md-end">
                    <ul className="list-inline button-list">
                      <li className="list-inline-item">
                        <button
                          className="btn btn-primary"
                          onClick={handlePrint}
                        >
                          Print Receipt
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive d-none d-md-block">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Merchant</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>TYPE</th>
                        <th>$ Amount</th>
                        <th>TIP </th>
                        <th>$ Total</th>
                        <th># Meals</th>
                      </tr>
                    </thead>
                    {transactionsState.data?.transactions.length > 0 && (
                      <tbody>
                        {transactionsState.data?.transactions.map(
                          (transaction, i) => {
                            const total =
                              Number(transaction.Amount || 0) +
                              Number(transaction.TipAmount || 0);
                            const mealTotal =
                              Number(transaction.Meals || 0) +
                              Number(transaction.TipMeals || 0);
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="f500">
                                    {transaction.MerchantName}
                                  </div>
                                </td>
                                <td>
                                  <div className="f300">
                                    {moment(transaction.Stamp).format(
                                      "MM.DD.YYYY"
                                    )}
                                  </div>
                                </td>
                                <td className="f300">
                                  {moment(transaction.Stamp).format(
                                    "hh:mm:ss a"
                                  )}
                                </td>
                                <td>
                                  <div className="f300">
                                    {transaction.TrxnType}
                                  </div>
                                </td>
                                <td className="f300">
                                  {toNumberWithComma(
                                    convertReportCurrency(transaction.Amount)
                                  )}
                                </td>
                                <td className="f300">
                                  {toNumberWithComma(
                                    convertReportCurrency(transaction.TipAmount)
                                  )}
                                </td>
                                <td>
                                  {toNumberWithComma(convertReportCurrency(total))}
                                </td>
                                <td>{convertNegativeMealAmount(mealTotal.toFixed(2))}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
                {transactionsState.isFetching ? (
                  <div className="d-flex w-100 align-items-center justify-content-center my-2">
                    <Spinner type="border" color="primary" />
                  </div>
                ) : (
                  transactionsState.data?.transactions.length === 0 && (
                    <div className="empty">No data display here</div>
                  )
                )}
                <ul className="list-unstyled transaction-list mb-0 d-block d-md-none">
                  {transactionsState.data?.transactions.length > 0 &&
                    transactionsState.data?.transactions.map(
                      (transaction, idx) => (
                        <li key={idx}>
                          <div className="date-time dark-text f400 mb-1">
                            {`${moment(transaction.Stamp).format(
                              "MM.DD.YYYY"
                            )} / ${moment(transaction.Stamp).format(
                              "hh:mm:ss a"
                            )}`}
                          </div>
                          <h4 className="md-title mb-4">
                            {transaction.MerchantName}
                          </h4>
                          <ul className="list-unstyled">
                            <li>
                              TYPE <span> {transaction.TrxnType}</span>
                            </li>
                            <li>
                              $ Amount
                              <span>
                                {toNumberWithComma(
                                  convertReportCurrency(transaction.Amount)
                                )}
                              </span>
                            </li>
                            <li>
                              $ TIP <span>${transaction.TipAmount}</span>
                            </li>
                            <li>
                              $ Total <span>{transaction.FormattedAmount}</span>
                            </li>
                          </ul>
                        </li>
                      )
                    )}
                </ul>
                {/* <button className="btn btn-primary mt-4" onClick={showMore}>
                  Show More
                </button> */}
              </div>
            </div>
          </div>
          <div
            style={{ position: "fixed", left: "-500vw", top: 0 }}
            id="receipt"
          >
            <div className="bg-white p-3" ref={receiptRef}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <img
                    src={OCMPLogo}
                    alt="OCMP Logo"
                    style={{ height: "60px" }}
                  />
                  <p className="f10 ml-10 mb-0 lh-sm mt-2 fw-bold">
                    <span className="f14">Meal Purchases Statement </span>{" "}
                    <br />
                    School:{" "}
                    {currentStudentState.data?.data.studentData.schoolName}{" "}
                    <br />
                    Date: {moment(Date()).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="px-0 lh-sm fw-bold">
                  <p className="f8 mb-2">
                    132 Adam Street <br />
                    Suite #2 <br />
                    Newton, MA 02458
                  </p>
                  <p className="f8 mb-0">
                    888-211-6267 <br />
                    info@ocmp.com
                  </p>
                </div>
              </div>
              <table className="mt-3 transaction_download_table w100">
                <thead>
                  <tr className="f8" style={{backgroundColor:' #00529a'}}>
                    <th>Merchant</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Type</th>
                    <th>$ Amount</th>
                    <th>Tip</th>
                    <th>$ Total</th>
                    <th># Meals</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionsState?.data.transactions.map(
                    (transaction, key) => {
                      return (
                        <tr key={key} className="f8">
                          <td>
                            <div>{transaction.MerchantName}</div>
                          </td>
                          <td>
                            <div>
                              {moment(transaction.Stamp).format("MM.DD.YYYY")}
                            </div>
                          </td>
                          <td>
                            {moment(transaction.Stamp).format("hh:mm:ss a")}
                          </td>
                          <td>{transaction.TrxnType}</td>
                          <td>
                            {toNumberWithComma(
                              convertReportCurrency(transaction.Amount)
                            )}
                          </td>
                          <td>
                            {toNumberWithComma(
                              convertReportCurrency(transaction.TipAmount)
                            )}
                          </td>
                          <td>
                            {toNumberWithComma(
                              convertReportCurrency(
                                Number(transaction.Amount || 0) +
                                  Number(transaction.TipAmount || 0)
                              )
                            )}
                          </td>
                          <td>
                            {convertNegativeMealAmount(
                              (
                                Number(transaction.Meals || 0) +
                                Number(transaction.TipMeals || 0)
                              ).toFixed(2).toString()
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default TransactionsMealPurchases;
