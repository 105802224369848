import { APIResponse } from "./types";
import apiClient from "../utils/apiClient";

export type ProgramEntity = {
  program_id: string;
  program_name: string;
  admin_name: string;
  admin_email: string;
  admin_phone: string;
  start_date: string;
  end_date: string;
};

export type APIGetProgramDetailResponse = APIResponse<{
  data: ProgramEntity | undefined;
}>;

const getProgramDetail = (program_id: string) => {
  return apiClient.get<APIGetProgramDetailResponse>(
    `/v1/program.json/${program_id}`
  );
};

const programApi = {
  getProgramDetail,
};
export default programApi;
