import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Spinner } from "reactstrap";
import { LoadTransactionEntity } from "../../api/studentApi";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import OCMPLogo from "../../assets/img/ocmp.svg";
import receipt from "../../assets/img/receipt.svg";
import BreadCrumbLayout from "../../components/layout/BreadCrumbLayout";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {
  currentStudentSelector,
  loadTransactionsSelector,
} from "../../state/student/studentSelector";
import { loadTransactions } from "../../state/student/studentSlice";
import {
  convertCurrency,
  formatMethod,
  toMaskedCardNumberForDownloadTable,
  toMaskedCardNumberFromAsterisk,
  toNumberWithComma,
} from "../../utils/string";

const PlanReloads = () => {
  const dispatch = useAppDispatch();
  const loadTransactionsState = useSelector(loadTransactionsSelector);

  const orderRef = useRef<HTMLDivElement>(null);
  const receiptRef = useRef<HTMLDivElement>(null);

  const pageStyle = `{ 
    @page { 
      size: A4;
    }
  }`;
  const printOrder = useReactToPrint({
    content: () => orderRef.current,
    pageStyle,
  });

  const handlePrintPlanReload = (transaction: LoadTransactionEntity) => {
    setPrintedReceipt(transaction);
    setTimeout(() => {
      printOrder();
    }, 100);
  };

  const currentStudentState = useSelector(currentStudentSelector);
  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(moment().subtract(1, "d").format("YYYY-MM-DD")),
  //   endDate: new Date(),
  //   key: "selection",
  // });
  // const [pageNumber, setPageNumber] = useState(1);
  const [printedReceipt, setPrintedReceipt] =
    useState<LoadTransactionEntity | null>(null);
  useEffect(() => {
    dispatch(
      loadTransactions({
        data: {
          items_per_page: 1000,
          page_number: 1,
          // start_date: moment(selectionRange.startDate).format("YYYY-MM-DD"),
          // end_date: moment(selectionRange.endDate).format("YYYY-MM-DD"),
        },
      })
    );
  }, []);

  // const showMore = () => {
  //   setPageNumber(pageNumber + 1);
  // };

  // const handleSelect = (ranges?: any) => {
  //   setSelectionRange({
  //     startDate: ranges?.selection.startDate,
  //     endDate: ranges?.selection.endDate,
  //     key: "selection",
  //   });
  //   dispatch(
  //     loadTransactions({
  //       data: {
  //         items_per_page: 10,
  //         page_number: 1,
  //         start_date: moment(ranges?.selection.startDate).format("YYYY-MM-DD"),
  //         end_date: moment(ranges?.selection.endDate).format("YYYY-MM-DD"),
  //       },
  //     })
  //   );
  // };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plan Reloads | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Plan Reloads
        </li>
      </BreadCrumbLayout>

      {/* transaction */}
      <section className="transaction_history">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="row mb-3">
                  <div className="col-md-5">
                    {/* <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle
                        caret
                        className="nav-link px-0 cursor-pointer"
                        tag="a"
                      >
                        <div id="receiptRange">
                          <img src="/img/calendar.svg" alt="" />
                          &nbsp;
                          {`${moment(selectionRange.startDate).format(
                            "MM.DD.YYYY"
                          )} - ${moment(selectionRange.endDate).format(
                            "MM.DD.YYYY"
                          )}`}
                          <span />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DateRangePicker
                          ranges={[selectionRange]}
                          onChange={(selection) => handleSelect(selection)}
                          staticRanges={customRanges}
                        />
                      </DropdownMenu>
                    </UncontrolledDropdown>*/}
                  </div>
                  <div className="col-md-7 text-md-end">
                    <ul className="list-inline button-inline-list">
                      <li className="list-inline-item">
                        <Link
                          to={APP_ROUTES.MyCardAddFunds.path}
                          className="btn btn-primary"
                        >
                          Add Funds
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <button
                          className="btn btn-primary"
                          onClick={handlePrint}
                        >
                          Print Receipt
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive d-none d-md-block">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>DATE</th>
                        <th>Time</th>
                        <th>Name</th>
                        <th>Purchaser Name</th>
                        <th>Method</th>
                        <th>$ Amount</th>
                        <th>Receipt</th>
                      </tr>
                    </thead>
                    {loadTransactionsState.data.loadTransactions.length > 0 && (
                      <tbody>
                        {loadTransactionsState.data.loadTransactions.map(
                          (loadTransaction, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <div className="f500">
                                    {loadTransaction.first_name}{" "}
                                    {loadTransaction.last_name}
                                  </div>
                                </td>
                                <td>
                                  <div className="f300">
                                    {moment(loadTransaction.Stamp).format(
                                      "MM.DD.YYYY"
                                    )}
                                  </div>
                                </td>
                                <td className="f300">
                                  {moment(loadTransaction.Stamp).format(
                                    "hh:mm:ss a"
                                  )}
                                </td>
                                <td className="f300">
                                  {loadTransaction.plan_name.split("-")[0]}
                                  <br />
                                  {loadTransaction?.plan_name ===
                                  "Custom Meal Plan" ? (
                                    ""
                                  ) : (
                                    <>
                                      {loadTransaction?.plan_name
                                        ?.split("-")[1]
                                        ?.trimStart() && (
                                        <>
                                          (
                                          {loadTransaction?.plan_name
                                            ?.split("-")[1]
                                            ?.trimStart()}
                                          )
                                          <br />
                                        </>
                                      )}
                                    </>
                                  )}
                                  {loadTransaction.additional_meals !== "0" &&
                                    loadTransaction.additional_meals !== "" &&
                                    `+${loadTransaction.additional_meals} Meals`}
                                </td>
                                <td>
                                  <div className="f300">
                                    {loadTransaction.BillingName.trim() !== ""
                                      ? loadTransaction.BillingName
                                      : "N/A"}
                                  </div>
                                </td>
                                <td>
                                  {loadTransaction.payment_method_desc} <br />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: toMaskedCardNumberFromAsterisk(
                                        loadTransaction.CreditCardNumber
                                      ),
                                    }}
                                  />
                                </td>
                                <td>
                                  {loadTransaction.PaymentStatus ===
                                  "DECLINED" ? (
                                    "Declined"
                                  ) : (
                                    <>
                                      {toNumberWithComma(
                                        convertCurrency(loadTransaction.Amount)
                                      )}
                                      <br />
                                      {formatMethod(
                                        loadTransaction.PaymentStatus
                                      )}
                                    </>
                                  )}
                                </td>
                                {loadTransaction.PaymentStatus !==
                                "DECLINED" ? (
                                  <td
                                    onClick={() =>
                                      handlePrintPlanReload(loadTransaction)
                                    }
                                    className="cursor-pointer"
                                  >
                                    <img src={receipt} alt="receipt" />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
                <div
                  style={{
                    position: "fixed",
                    left: "-500vw",
                    top: 0,
                  }}
                >
                  <div className="bg-white px-54 py-40" ref={orderRef as any}>
                    <div className="d-flex align-items-center justify-content-between">
                      <img src={OCMPLogo} alt="OCMP Logo" className="h-86" />

                      <p className="text-end f14 m-0">
                        Off Campus Meal Plan <br />
                        Boston Office <br />
                        PO Box 129 <br />
                        Newton, MA 02456
                      </p>
                    </div>
                    <div className="mt-54">
                      <p className="f14">
                        Order# {printedReceipt?.order_number} <br />
                        {moment(printedReceipt?.Stamp).format("MM.DD.YYYY")}
                      </p>
                      <p className="f16 f500 mb-1">Payment Information</p>
                      {printedReceipt?.PaymentStatus === "DECLINED" ? (
                        <p className="mb-5">Declined</p>
                      ) : (
                        <div className="d-flex align-items-center mb-5">
                          <div className="w-50">
                            <p className="mb-0">
                              {printedReceipt?.payment_method_desc}
                            </p>
                            {printedReceipt?.payment_method === "CC" && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: toMaskedCardNumberFromAsterisk(
                                    printedReceipt?.CreditCardNumber
                                  ),
                                }}
                              />
                            )}
                          </div>
                          <div className="w-50">
                            {printedReceipt?.BillingName}
                            <br />
                            {printedReceipt?.BillingAddr_A}
                            {printedReceipt?.BillingAddr_B !== "" && (
                              <>
                                <br />
                                {printedReceipt?.BillingAddr_B}
                              </>
                            )}
                            <br />
                            {printedReceipt?.BillingCity !== ""
                              ? printedReceipt?.BillingCity + ", "
                              : ""}
                            {printedReceipt?.BillingState}{"  "}{printedReceipt?.BillingZip}
                          </div>
                        </div>
                      )}
                      <div className="d-flex align-items-center mb-30">
                        <div className="w-50">
                          <p className="f16 f500 mb-1">Shipping Address</p>
                          <p className="f14">
                            {printedReceipt?.MailToAddr_A !== "" ? (
                              <>
                                {printedReceipt?.NamePrintedOnCard} <br />
                                {printedReceipt?.MailToAddr_A}
                                {printedReceipt?.MailToAddr_B !== "" && (
                                  <>
                                    <br /> {printedReceipt?.MailToAddr_B}
                                  </>
                                )}
                                <br />
                                {printedReceipt?.MailToCity}{", "}
                                {printedReceipt?.MailToState}{"  "}
                                {printedReceipt?.MailToZip}
                              </>
                            ) : (
                              <>
                                <p>No card shipped</p>
                                If you do not have possession of your card,
                                please report it lost or stolen on the{" "}
                                <Link to="/my-card">My Card</Link> page
                              </>
                            )}
                          </p>
                        </div>
                        <div className="w-50 f14">
                          <p className="f500 m-0">
                            Name to be printed on the Card
                          </p>
                          <p>
                            {printedReceipt?.NamePrintedOnCard}
                            <br />
                            <span className="f500">Student Email:</span>{" "}
                            {
                              currentStudentState.data?.data?.studentData
                                .student_email
                            }
                            <br />
                            <span className="f500">School:</span>{" "}
                            {printedReceipt?.schoolName}
                          </p>
                        </div>
                      </div>
                      <p className="f16 f500 mb-1">Purchase Summary</p>
                      <div className="d-flex align-items-center mb-5">
                        <div className="w-50 f14">
                          <span className="f500">Plan:</span>{" "}
                          {printedReceipt?.plan_name?.split("-")[0]?.trimEnd()}
                          <br />
                          <span className="f500">Description:</span>{" "}
                          {printedReceipt?.plan_meals} Meals
                          <br />
                          <span className="f500">Additional Meals:</span>{" "}
                          {printedReceipt?.additional_meals === ""
                            ? 0
                            : printedReceipt?.additional_meals + " Meals"}
                        </div>
                      </div>
                      {printedReceipt?.PaymentStatus !== "DECLINED" && (
                        <p className="f16 f500">
                          <span className="primary-text">TOTAL:</span> $
                          {printedReceipt?.Amount}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <button className="btn btn-primary mt-4" onClick={showMore}>
                  Show More
                </button> */}
                </div>
                <ul className="list-unstyled transaction-list mb-0 d-block d-md-none">
                  {loadTransactionsState.data.loadTransactions.length > 0 &&
                    loadTransactionsState.data.loadTransactions.map(
                      (loadTransaction, idx) => (
                        <li key={idx}>
                          <div className="date-time dark-text f400 mb-1">
                            {`${moment(loadTransaction.Stamp).format(
                              "MM.DD.YYYY"
                            )} / ${moment(loadTransaction.Stamp).format(
                              "hh:mm:ss a"
                            )}`}
                          </div>
                          <ul className="list-unstyled">
                            <li>
                              name
                              <span>
                                {loadTransaction.plan_name.split("-")[0]}
                                <span className="f300">
                                  {loadTransaction?.plan_name ===
                                  "Custom Meal Plan" ? (
                                    ""
                                  ) : (
                                    <>
                                      {loadTransaction?.plan_name
                                        ?.split("-")[1]
                                        ?.trimStart() && (
                                        <>
                                          (
                                          {loadTransaction?.plan_name
                                            ?.split("-")[1]
                                            ?.trimStart()}
                                          )
                                          <br />
                                        </>
                                      )}
                                    </>
                                  )}
                                  {loadTransaction.additional_meals !== "0" &&
                                    loadTransaction.additional_meals !== "" &&
                                    `+${loadTransaction.additional_meals} Meals`}
                                </span>
                              </span>
                            </li>
                            <li>
                              $ Amount
                              <span>
                                {toNumberWithComma(
                                  convertCurrency(loadTransaction.Amount)
                                )}
                                <br />{" "}
                                {formatMethod(loadTransaction.PaymentStatus)}
                              </span>
                            </li>
                            <li>
                              Purchaser Name{" "}
                              <span>
                                {loadTransaction.BillingName.trim() !== ""
                                  ? loadTransaction.BillingName
                                  : "N/A"}
                              </span>
                            </li>
                            <li>
                              Method
                              <span>
                                {loadTransaction.payment_method_desc} <br />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: toMaskedCardNumberFromAsterisk(
                                      loadTransaction.CreditCardNumber
                                    ),
                                  }}
                                />
                              </span>
                            </li>
                          </ul>
                        </li>
                      )
                    )}
                </ul>
                {loadTransactionsState.isFetching ? (
                  <div className="d-flex w-100 align-items-center justify-content-center my-2">
                    <Spinner type="border" color="primary" />
                  </div>
                ) : (
                  loadTransactionsState.data.loadTransactions.length === 0 && (
                    <div className="empty">No data display here</div>
                  )
                )}
              </div>
            </div>
            <div style={{ position: "fixed", left: "-500vw", top: 0 }}>
              <div className="bg-white p-3" ref={receiptRef as any}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      src={OCMPLogo}
                      alt="OCMP Logo"
                      style={{ height: "60px" }}
                    />
                    <p className="f10 ml-10 mb-0 lh-sm fw-bold">
                      <span className="f14">Plan Reloads Statement </span>{" "}
                      <br />
                      School:{" "}
                      {
                        currentStudentState.data?.data?.studentData.schoolName
                      }{" "}
                      <br />
                      Date: {moment(Date()).format("MM/DD/YYYY")}
                    </p>
                  </div>
                  <div className="px-0 lh-sm fw-bold">
                    <p className="f8 mb-2">
                      132 Adam Street <br />
                      Suite #2 <br />
                      Newton, MA 02458
                    </p>
                    <p className="f8 mb-0">
                      888-211-6267 <br />
                      info@ocmp.com
                    </p>
                  </div>
                </div>
                <table
                  className="mt-3 transaction_download_table"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="f8">
                      <th>Name</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Details</th>
                      <th style={{ width: "20%" }}>Purchaser Name</th>
                      <th>Method</th>
                      <th>$ Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadTransactionsState?.data.loadTransactions.map(
                      (loadTransaction, key) => {
                        return (
                          <tr key={key} className="f8">
                            <td>
                              <div>
                                {loadTransaction.first_name}{" "}
                                {loadTransaction.last_name}
                              </div>
                            </td>
                            <td>
                              <div>
                                {moment(loadTransaction.Stamp).format(
                                  "MM.DD.YYYY"
                                )}
                              </div>
                            </td>
                            <td>
                              {moment(loadTransaction.Stamp).format(
                                "hh:mm:ss a"
                              )}
                            </td>
                            <td>
                              {loadTransaction.plan_name.split("-")[0]}
                              <br />
                              {loadTransaction?.plan_name ===
                              "Custom Meal Plan" ? (
                                ""
                              ) : (
                                <>
                                  {loadTransaction?.plan_name
                                    ?.split("-")[1]
                                    ?.trimStart() && (
                                    <>
                                      (
                                      {loadTransaction?.plan_name
                                        ?.split("-")[1]
                                        ?.trimStart()}
                                      )
                                      <br />
                                    </>
                                  )}
                                </>
                              )}
                              {loadTransaction.additional_meals !== "0" &&
                                loadTransaction.additional_meals !== "" &&
                                `+${loadTransaction.additional_meals} Meals`}
                            </td>
                            <td>
                              <div>
                                {loadTransaction.BillingName.trim() !== ""
                                  ? loadTransaction.BillingName
                                  : ""}
                              </div>
                            </td>
                            <td>
                              {loadTransaction.payment_method_desc} <br />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: toMaskedCardNumberForDownloadTable(
                                    loadTransaction.CreditCardNumber
                                  ),
                                }}
                                style={{ margin: "-4px 0" }}
                              />
                              {/* {loadTransaction.CreditCardNumber} */}
                            </td>
                            <td>
                              {toNumberWithComma(
                                convertCurrency(loadTransaction.Amount)
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default PlanReloads;
