import { APP_ROUTES } from "./../app/routes";
import Axios, { AxiosError } from "axios";
import { clearLocalAuthCredentials, getAccessToken } from "./auth";

const apiClient = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
apiClient.interceptors.request.use(
  function (config) {
    const accessToken = getAccessToken() || "";
    const apiKey = process.env.REACT_APP_API_KEY || "";

    if (config.headers) {
      config.headers["authorization"] = `Bearer ${accessToken || apiKey}`;
    }
    // if (apiKey) {
    //   if (!config.params) {
    //     config.params = {};
    //   }
    //   config.params["api_key"] = apiKey;
    // }

    return config;
  },
  function (error) {
    console.log("request error", error);
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log("server error", error);

    if ([401, 403].includes(error.response.status)) {
      clearLocalAuthCredentials();
      if (!window.location.pathname.includes(APP_ROUTES.Login.path))
        window.location.assign(APP_ROUTES.Login.path);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export interface onCompleteCB<D = any> {
  (error: AxiosError | null | undefined, data?: D): void;
}

export default apiClient;
