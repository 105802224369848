import apiClient from "../utils/apiClient";
import { APIResponse } from "./types";
// import { getAccessToken, getRefreshToken } from "../utils/auth";

export type APIRegisterInput = {
  username: string;
  first_name: string;
  last_name: string;
  password: string;
  account_type: "student" | "parent";
  // confirmPassword: string;
  SchoolID?: number;
  GraduationYear?: string;
  referral_code?: string;
};

export type APIRegisterResponse = APIResponse<{
  access_token: string;
  refresh_token: string;
  login_id: 10;
  user_role: string;
}>;

const register = (data: APIRegisterInput) => {
  return apiClient.post<APIRegisterResponse>(`/v1/account/register.json`, {
    ...data,
    client_id: process.env.REACT_APP_CLIENT_ID,
  });
};

export type APILoginInput = {
  username: string;
  password: string;
};

export type APILoginResponse = APIResponse<{
  access_token: string;
  refresh_token: string;
  login_id: 10;
  user_role: string;
}>;

const login = (data: APILoginInput) => {
  return apiClient.post(`/v1/auth/login.json`, {
    ...data,
    client_id: process.env.REACT_APP_CLIENT_ID,
  });
};

export type APIForgotPasswordInput = {
  username: string;
};

const forgotPassword = (data: APIForgotPasswordInput) => {
  return apiClient.post(`/v1/account/resetpassword.json`, {
    ...data,
  });
};

export type APIResetPasswordInput = {
  reset_password: string;
  confirm_reset_password: string;
};

const resetPassword = (data: APIResetPasswordInput) => {
  return apiClient.post(`/v1/account/password.json`, {
    ...data,
  });
};

const authAPI = {
  register,
  login,
  forgotPassword,
  resetPassword,
};

export default authAPI;
