import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Spinner } from "reactstrap";
import { LoadTransactionEntity } from "../../api/studentApi";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import OCMPLogo from "../../assets/img/ocmp.svg";
import receipt from "../../assets/img/receipt.svg";
import BreadCrumbLayout from "../../components/layout/BreadCrumbLayout";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {
  currentStudentSelector,
  loadTransactionsSelector,
  transactionsSelector,
} from "../../state/student/studentSelector";
import {
  getTransactions,
  loadTransactions,
} from "../../state/student/studentSlice";
import {
  convertCurrency,
  formatMethod,
  toMaskedCardNumberFromAsterisk,
  toNumberWithComma,
  convertNegativeMealAmount,
} from "../../utils/string";

const Transactions = () => {
  const dispatch = useAppDispatch();

  const orderRef = useRef<HTMLDivElement>(null);

  const transactionsState = useSelector(transactionsSelector);
  const currentStudentState = useSelector(currentStudentSelector);
  const loadTransactionsState = useSelector(loadTransactionsSelector);

  const [printedReceipt, setPrintedReceipt] =
    useState<LoadTransactionEntity | null>(null);

  useEffect(() => {
    dispatch(getTransactions({ data: { items_per_page: 10, page_number: 1 } }));
    dispatch(
      loadTransactions({ data: { items_per_page: 10, page_number: 1 } })
    );
  }, []);

  const pageStyle = `{ 
    @page { 
      size: A4;
    }
  }`;
  const printOrder = useReactToPrint({
    content: () => orderRef.current,
    pageStyle,
  });

  const handlePrintPlanReload = (transaction: LoadTransactionEntity) => {
    setPrintedReceipt(transaction);
    setTimeout(() => {
      printOrder();
    }, 100);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meal Purchases | OCMP</title>
      </Helmet>
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Transactions
        </li>
      </BreadCrumbLayout>
      <section className="transaction_history">
        <div className="container">
          <div className="row d-none d-md-flex">
            <div className="col-md-12">
              <div className="card">
                <h2 className="xl-title mb-30">Recent Meal Purchases</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Merchant</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>TYPE</th>
                        <th>$ Amount</th>
                        <th>TIP</th>
                        <th>$ Total</th>
                        <th># Meals</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionsState.data.transactions.map(
                        (transaction, key) => {
                          const total =
                            Number(transaction.Amount || 0) +
                            Number(transaction.TipAmount || 0);
                          const mealTotal =
                            Number(transaction.Meals || 0) +
                            Number(transaction.TipMeals || 0);
                          return (
                            <tr key={key}>
                              <td>
                                <div className="f500">
                                  {transaction.MerchantName}
                                </div>
                              </td>
                              <td>
                                <div className="f300">
                                  {moment(transaction.Stamp).format(
                                    "MM.DD.YYYY"
                                  )}
                                </div>
                              </td>
                              <td className="f300">
                                {moment(transaction.Stamp).format("hh:mm:ss a")}
                              </td>
                              <td>
                                <div className="f300">
                                  {transaction.TrxnType}
                                </div>
                              </td>
                              <td className="f300">
                                {toNumberWithComma(
                                  convertCurrency(transaction.Amount)
                                )}
                              </td>
                              <td className="f300">
                                {convertCurrency(transaction.TipAmount)}
                              </td>
                              <td>
                                {toNumberWithComma(convertCurrency(total))}
                              </td>
                              <td>{convertNegativeMealAmount(mealTotal.toFixed(2))}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
                {transactionsState.isFetching && (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Spinner type="border" color="primary" />
                  </div>
                )}
                <Link
                  to={APP_ROUTES.TransactionsMealPurchases.path}
                  className="btn btn-outline btn-view"
                >
                  View All Purchases
                </Link>
              </div>
              <div className="card">
                <h2 className="xl-title mb-30">Recent Plan Reloads</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>name</th>
                        <th>DATE</th>
                        <th>Time</th>
                        <th>Name</th>
                        <th>Purchaser Name</th>
                        <th>Method</th>
                        <th>$ Amount</th>
                        <th>Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadTransactionsState.data.loadTransactions.map(
                        (loadTransaction, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className="f500">
                                  {loadTransaction.first_name}{" "}
                                  {loadTransaction.last_name}
                                </div>
                              </td>
                              <td>
                                <div className="f300">
                                  {moment(loadTransaction.Stamp).format(
                                    "MM.DD.YYYY"
                                  )}
                                </div>
                              </td>
                              <td className="f300">
                                {moment(loadTransaction.Stamp).format(
                                  "hh:mm:ss a"
                                )}
                              </td>
                              <td className="f300">
                                {loadTransaction.plan_name.split("-")[0]}
                                <br />
                                {loadTransaction?.plan_name ===
                                "Custom Meal Plan" ? (
                                  ""
                                ) : (
                                  <>
                                    {loadTransaction?.plan_name
                                      ?.split("-")[1]
                                      ?.trimStart() && (
                                      <>
                                        (
                                        {loadTransaction?.plan_name
                                          ?.split("-")[1]
                                          ?.trimStart()}
                                        )
                                        <br />
                                      </>
                                    )}
                                  </>
                                )}
                                {loadTransaction.additional_meals &&
                                  loadTransaction.additional_meals !== "0" &&
                                  `+${loadTransaction.additional_meals} Meals`}
                              </td>
                              <td>
                                <div className="f300">
                                  {loadTransaction.BillingName.trim() !== ""
                                    ? loadTransaction.BillingName
                                    : "N/A"}
                                </div>
                              </td>
                              <td>
                                {loadTransaction.payment_method_desc} <br />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: toMaskedCardNumberFromAsterisk(
                                      loadTransaction.CreditCardNumber
                                    ),
                                  }}
                                />
                              </td>
                              <td>
                                {toNumberWithComma(
                                  convertCurrency(loadTransaction.Amount)
                                )}
                                <br />
                                {formatMethod(loadTransaction.PaymentStatus)}
                              </td>
                              <td
                                onClick={() =>
                                  handlePrintPlanReload(loadTransaction)
                                }
                                className="cursor-pointer"
                              >
                                <img src={receipt} alt="receipt" />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <div
                    style={{
                      position: "fixed",
                      left: "-500vw",
                      top: 0,
                    }}
                  >
                    <div className="bg-white px-54 py-40" ref={orderRef as any}>
                      <div className="d-flex align-items-center justify-content-between">
                        <img src={OCMPLogo} alt="OCMP Logo" className="h-86" />

                        <p className="text-end f14 m-0">
                          Off Campus Meal Plan <br />
                          Boston Office <br />
                          PO Box 129 <br />
                          Newton, MA 02456
                        </p>
                      </div>
                      <div className="mt-54">
                        <p className="f14">
                          Order# {printedReceipt?.order_number} <br />
                          {moment(printedReceipt?.Stamp).format("MM.DD.YYYY")}
                        </p>
                        <p className="f16 f500 mb-1">Payment Information</p>
                        {printedReceipt?.PaymentStatus === "DECLINED" ? (
                          <p className="mb-5">Declined</p>
                        ) : (
                          <div className="d-flex align-items-center mb-5">
                            <div className="w-50">
                              <p className="mb-0">
                                {printedReceipt?.payment_method_desc}
                              </p>
                              {printedReceipt?.payment_method === "CC" && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: toMaskedCardNumberFromAsterisk(
                                      printedReceipt?.CreditCardNumber
                                    ),
                                  }}
                                />
                              )}
                            </div>
                            <div className="w-50">
                              {printedReceipt?.BillingName}
                              <br />
                              {printedReceipt?.BillingAddr_A}
                              {printedReceipt?.BillingAddr_B !== "" && (
                                <>
                                  <br />
                                  {printedReceipt?.BillingAddr_B}
                                </>
                              )}
                              <br />
                              {printedReceipt?.BillingCity !== ""
                                ? printedReceipt?.BillingCity + ", "
                                : ""}
                              {printedReceipt?.BillingState}{"  "}{printedReceipt?.BillingZip}
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center mb-30">
                          <div className="w-50">
                            <p className="f16 f500 mb-1">Shipping Address</p>
                            <p className="f14">
                              {printedReceipt?.MailToAddr_A !== "" ? (
                                <>
                                  {printedReceipt?.NamePrintedOnCard} <br />
                                  {printedReceipt?.MailToAddr_A}
                                  {printedReceipt?.MailToAddr_B !== "" && (
                                    <>
                                      <br /> {printedReceipt?.MailToAddr_B}
                                    </>
                                  )}
                                  <br />
                                  {printedReceipt?.MailToCity}{", "}
                                  {printedReceipt?.MailToState}{"  "}
                                  {printedReceipt?.MailToZip}
                                </>
                              ) : (
                                <>
                                  <p>No card shipped</p>
                                  If you do not have possession of your card,
                                  please report it lost or stolen on the{" "}
                                  <Link to="/my-card">My Card</Link> page
                                </>
                              )}
                            </p>
                          </div>
                          <div className="w-50 f14">
                            <p className="f500 m-0">
                              Name to be printed on the Card
                            </p>
                            <p>
                              {printedReceipt?.NamePrintedOnCard}
                              <br />
                              <span className="f500">Student Email:</span>{" "}
                              {
                                currentStudentState.data?.data?.studentData
                                  .student_email
                              }
                              <br />
                              <span className="f500">School:</span>{" "}
                              {printedReceipt?.schoolName}
                            </p>
                          </div>
                        </div>
                        <p className="f16 f500 mb-1">Purchase Summary</p>
                        <div className="d-flex align-items-center mb-5">
                          <div className="w-50 f14">
                            <span className="f500">Plan:</span>{" "}
                            {printedReceipt?.plan_name
                              ?.split("-")[0]
                              ?.trimEnd()}
                            <br />
                            <span className="f500">Description:</span>{" "}
                            {printedReceipt?.plan_meals} Meals
                            <br />
                            <span className="f500">Additional Meals:</span>{" "}
                            {printedReceipt?.additional_meals === ""
                              ? 0
                              : printedReceipt?.additional_meals + " Meals"}
                          </div>
                        </div>
                        {printedReceipt?.PaymentStatus !== "DECLINED" && (
                          <p className="f16 f500">
                            <span className="primary-text">TOTAL:</span> $
                            {printedReceipt?.Amount}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <button className="btn btn-primary mt-4" onClick={showMore}>
                  Show More
                </button> */}
                  </div>
                </div>
                {loadTransactionsState.isFetching && (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Spinner type="border" color="primary" />
                  </div>
                )}
              </div>
              <Link
                to={APP_ROUTES.PlanReloads.path}
                className="btn btn-outline btn-view"
              >
                View All Reloads
              </Link>
            </div>
          </div>
        </div>
        <div className="row d-flex d-md-none">
          <div className="col-md-12">
            <div className="card">
              <h2 className="lg-title mb-4">Recent Meal Purchases</h2>
              <ul className="list-unstyled transaction-list mb-0">
                {transactionsState.data.transactions.map((transaction, key) => {
                  const total =
                    Number(transaction.Amount || 0) +
                    Number(transaction.TipAmount || 0);
                  const mealTotal =
                    Number(transaction.Meals || 0) +
                    Number(transaction.TipMeals || 0);
                  return (
                    <li key={key}>
                      <div className="date-time dark-text f400 mb-1">
                        {`${moment(transaction.Stamp).format(
                          "MM.DD.YYYY"
                        )} / ${moment(transaction.Stamp).format("hh:mm:ss a")}`}
                      </div>
                      <h4 className="md-title mb-4">
                        {transaction.MerchantName}
                      </h4>
                      <ul className="list-unstyled">
                        <li>
                          TYPE <span>{transaction.TrxnType}</span>
                        </li>
                        <li>
                          $ Amount
                          <span>
                            {toNumberWithComma(
                              convertCurrency(transaction.Amount)
                            )}
                          </span>
                        </li>
                        <li>
                          $ TIP
                          <span>{convertCurrency(transaction.TipAmount)}</span>
                        </li>
                        <li>
                          $ Total
                          <span>
                            {convertCurrency(total)} ({mealTotal.toFixed(2)})
                          </span>
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ul>
              {loadTransactionsState.isFetching && (
                <div className="d-flex w-100 align-items-center justify-content-center">
                  <Spinner type="border" color="primary" />
                </div>
              )}
              <Link
                to={APP_ROUTES.TransactionsMealPurchases.path}
                className="btn btn-outline btn-view mw-100"
              >
                View All Purchases
              </Link>
            </div>
            <div className="card">
              <h2 className="xl-title mb-4">Recent Plan Reloads</h2>
              <ul className="list-unstyled transaction-list mb-0">
                {loadTransactionsState.data.loadTransactions.map(
                  (loadTransaction, key) => {
                    return (
                      <li key={key}>
                        <div className="date-time dark-text f400 mb-1">
                          {`${moment(loadTransaction.Stamp).format(
                            "MM.DD.YYYY"
                          )} / ${moment(loadTransaction.Stamp).format(
                            "hh:mm:ss a"
                          )}`}
                        </div>
                        <ul className="list-unstyled">
                          <li>
                            name
                            <span>
                              {loadTransaction.plan_name.split("-")[0]}
                              <span className="f300">
                                {loadTransaction?.plan_name ===
                                "Custom Meal Plan" ? (
                                  ""
                                ) : (
                                  <>
                                    {loadTransaction?.plan_name
                                      ?.split("-")[1]
                                      ?.trimStart() && (
                                      <>
                                        (
                                        {loadTransaction?.plan_name
                                          ?.split("-")[1]
                                          ?.trimStart()}
                                        )
                                        <br />
                                      </>
                                    )}
                                  </>
                                )}
                                {loadTransaction.additional_meals !== "0" &&
                                  loadTransaction.additional_meals !== "" &&
                                  `+${loadTransaction.additional_meals} Meals`}
                              </span>
                            </span>
                          </li>
                          <li>
                            $ Amount
                            <span>
                              {convertCurrency(loadTransaction.Amount)}
                              <br />
                              {formatMethod(loadTransaction.PaymentStatus)}
                            </span>
                          </li>
                          <li>
                            Purchaser Name
                            <span>
                              {loadTransaction.BillingName.trim() !== ""
                                ? loadTransaction.BillingName
                                : "N/A"}
                            </span>
                          </li>
                          <li>
                            Method
                            <span>
                              {loadTransaction.payment_method_desc}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: toMaskedCardNumberFromAsterisk(
                                    loadTransaction.CreditCardNumber
                                  ),
                                }}
                              />
                            </span>
                          </li>
                        </ul>
                      </li>
                    );
                  }
                )}
              </ul>
              {loadTransactionsState.isFetching && (
                <div className="d-flex w-100 align-items-center justify-content-center">
                  <Spinner type="border" color="primary" />
                </div>
              )}
              <Link
                to={APP_ROUTES.PlanReloads.path}
                className="btn btn-outline btn-view mw-100"
              >
                View All Reloads
              </Link>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Transactions;
