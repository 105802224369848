import moment from "moment";
import React from "react";
import { ProgramEntity } from "../../api/programApi";

interface ProgramCardProps {
  programData: ProgramEntity | undefined;
  amountProvided: string | undefined;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  programData,
  amountProvided,
}) => {
  return (
    <div className="card justify-content-start">
      <h4 className="md-title text-uppercase mb-60">Program Information</h4>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <h4 className="md-title mb-3">{programData?.program_name}</h4>
          <div className="row">
            <div className="col-sm-6 admin-address">
              <p className="f500 mb-2">Your Program Administrator:</p>
              {programData?.admin_name}
              <a
                href={programData?.admin_email}
                className="text-decoration-underline"
              >
                {programData?.admin_email}
              </a>
              <a
                href={`tel:${programData?.admin_phone}`}
                className="text-decoration-underline"
              >
                {programData?.admin_phone}
              </a>
            </div>
            <div className="col-sm-6">
              <p className="f500 mb-2">Amount Provided:</p>${amountProvided}
            </div>
          </div>
        </div>

        <div className="active-card-toast">
          <span>
            OCMP card will be active from{" "}
            {moment(programData?.start_date).format("MMM Do, YYYY")} –{" "}
            {moment(programData?.end_date).format("MMM Do, YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ProgramCard;
