import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DashboardLayout from "../components/layout/DashboardLayout";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";

interface NotFoundProps {}

export const NotFoundPage: React.FC<NotFoundProps> = () => {
  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not Found | OCMP</title>
      </Helmet>
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          404 Error
        </li>
      </BreadCrumbLayout>
      <section className="page-404">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card d-flex justify-content-center text-center box-404">
                <h1 className="">
                  The page you’re looking for can’t be found.
                </h1>
                <Link to="/" className="btn btn-primary">
                  Go to the Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};
