import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";
import AuthLayout from "../../components/layout/AuthLayout";
import LoginForm from "../../modules/auth/LoginForm";

const Login = () => {
  const [error, setError] = useState<string>("");

  return (
    <AuthLayout
      pageName="auth-login"
      navItem={{ url: "/register", text: "Create Account" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | OCMP</title>
      </Helmet>
      <section>
        <div className="authentication_box">
          {error && (
            <div className="alert alert-danger p-2 mb-4" role="alert">
              {error}
            </div>
          )}
          <h1 className="xl-title text-center mb-4">Log In</h1>
          <LoginForm handleError={setError} />
          <Link to={APP_ROUTES.ForgotPassword.path} className="forgot_pwd">
            Forgot password?
          </Link>
          <Link to={APP_ROUTES.Register.path} className="register_link">
            Need to register? Get started!
          </Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default Login;
