import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contactUsAPI, {
  APIContactUsInput,
  APIContactUsResponse,
} from "../../api/siteApi";
import { onCompleteCB } from "../../utils/apiClient";

export const contactUs = createAsyncThunk(
  "site/contact-us",
  ({
    data,
    onComplete,
  }: {
    data: APIContactUsInput;
    onComplete: onCompleteCB<APIContactUsResponse>;
  }) => {
    return contactUsAPI
      .contactUs(data)
      .then(({ data }) => {
        onComplete(null, data);
        return data;
      })
      .catch((error) => {
        onComplete(error);
        throw new Error(error.response.data);
      });
  }
);

export interface siteState {
  contactUs: {
    fetching: boolean;
  };
}

const initialSiteState: siteState = {
  contactUs: { fetching: false },
};

export const siteSlice = createSlice({
  name: "site",
  initialState: initialSiteState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUs.pending, (state) => {
        state.contactUs.fetching = true;
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.contactUs.fetching = false;
      })
      .addCase(contactUs.rejected, (state) => {
        state.contactUs.fetching = false;
      });
  },
});

export const siteReducer = siteSlice.reducer;
export const siteActions = {
  ...siteSlice.actions,
  contactUs,
};
