import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { onCompleteCB } from "../../utils/apiClient";
import programApi, {
  APIGetProgramDetailResponse,
  ProgramEntity,
} from "./../../api/programApi";

export const getDetailProgram = createAsyncThunk<
  APIGetProgramDetailResponse,
  {
    data: {
      program_id: string;
    };
    onComplete?: onCompleteCB<APIGetProgramDetailResponse>;
  }
>("program/detail", ({ data, onComplete }) => {
  return programApi
    .getProgramDetail(data.program_id)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export interface ProgramState {
  detail: {
    isFetching: boolean;
    data: ProgramEntity | undefined;
  };
}

const initialProgramState: ProgramState = {
  detail: {
    isFetching: false,
    data: undefined,
  },
};

const programSlice = createSlice({
  name: "program",
  initialState: initialProgramState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetailProgram.pending, (state) => {
        state.detail.isFetching = true;
      })
      .addCase(getDetailProgram.fulfilled, (state, action) => {
        state.detail.isFetching = false;
        state.detail.data = action.payload.payload.data;
      })
      .addCase(getDetailProgram.rejected, (state) => {
        state.detail.isFetching = false;
      });
  },
});

export const programReducer = programSlice.reducer;

export const programActions = {
  ...programSlice.actions,
  getDetailProgram,
};
