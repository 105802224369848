import React from "react";
import Select from "react-select";
import { SelectProps } from "./AddressSelection";

const ReasonOptions = [
  { label: "Select a Reason", value: "" },
  { label: "Account Issue", value: "Account Issue" },
  { label: "New Membership Question", value: "New Membership Question" },
];

const ReasonSelection = ({ name, onChange, value, ...rest }: any) => {
  return (
    <Select
      {...rest}
      name={name}
      value={ReasonOptions.find((s) => s.value === value)}
      options={ReasonOptions}
      onChange={(selectedOption: SelectProps) => {
        onChange(selectedOption.value);
      }}
    />
  );
};
export default ReasonSelection;
