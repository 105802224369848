import { paymentReducer } from "./../state/payment/paymentSlice";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { authReducer } from "../state/auth/authSlice";
import { restaurantReducer } from "../state/restaurant/restaurantSlice";
import { schoolReducer } from "../state/school/schoolSlice";
import { siteReducer } from "../state/site/siteSlice";
import { studentReducer } from "../state/student/studentSlice";
import { transactionReducer } from "../state/transaction/transactionSlice";
import { mealPlanReducer } from "../state/mealPlan/mealPlanSlice";
import { programReducer } from "../state/program/programSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  transaction: transactionReducer,
  site: siteReducer,
  school: schoolReducer,
  restaurant: restaurantReducer,
  student: studentReducer,
  mealPlan: mealPlanReducer,
  payment: paymentReducer,
  program: programReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
