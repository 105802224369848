import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/layout/AuthLayout";

const ForgotPasswordLinkExpired = () => {
  return (
    <AuthLayout
      pageName="auth-forgot-password-link-expired"
      navItem={{ url: "/register", text: "Create Account" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Card | Expired</title>
      </Helmet>
      <section>
        <div className="authentication_box">
          <h1 className="xl-title text-center mb-3">Reset password</h1>
          <p className="text-center text-muted mb-5">
            The previous link has expired. Please try <br />
            reseting your password again.
          </p>
          <div className="d-grid">
            <Link to="forgot-pwd.html" className="btn btn-primary btn-block">
              Reset Password
            </Link>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ForgotPasswordLinkExpired;
