import React from "react";
import Select from "react-select";
import { generateNext12Years } from "../../utils/datetime";

const listNext12Years = generateNext12Years();

export type SelectProps = {
  value: string;
  label: string;
};

const next12YearsState = listNext12Years.map((year) => {
  return {
    value: year,
    label: year,
  };
});

const GraduationYearSelection = ({ name, onChange, value, ...rest }: any) => {
  return (
    <Select
      {...rest}
      name={name}
      value={next12YearsState.find((s) => s.value === value)}
      options={next12YearsState}
      onChange={(selectedOption: SelectProps) => {
        onChange(selectedOption.value);
      }}
    />
  );
};
export default GraduationYearSelection;
