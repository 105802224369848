import { SuggestBySchoolEntity } from "./../../api/restaurantApi";
import { APIResponse } from "./../../api/types";
import { onCompleteCB } from "./../../utils/apiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import restaurantApi, {
  APIListRestaurantsInput,
  APIListRestaurantsResponse,
  APIRestaurantDetailsResponse,
  APIRestaurantTypesResponse,
  RestaurantEntity,
  RestaurantTypeEntity,
  SuggestEntity,
} from "../../api/restaurantApi";

export const listRestaurant = createAsyncThunk<
  APIListRestaurantsResponse,
  {
    data: APIListRestaurantsInput;
    onComplete?: onCompleteCB<APIListRestaurantsResponse>;
  }
>("restaurant/list", (thunkArg) => {
  return restaurantApi
    .listRestaurants(thunkArg.data)
    .then(({ data }) => {
      thunkArg.onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      thunkArg.onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const getDetails = createAsyncThunk<
  APIRestaurantDetailsResponse,
  {
    data: { m_id: string };
    onComplete?: onCompleteCB<APIRestaurantDetailsResponse>;
  }
>("restaurant/details", ({ data, onComplete }) => {
  return restaurantApi
    .getDetails(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const getTypes = createAsyncThunk<
  APIRestaurantTypesResponse,
  onCompleteCB<APIRestaurantTypesResponse> | undefined
>("restaurant/types", (onComplete) => {
  return restaurantApi
    .getTypes()
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const requestRestaurant = createAsyncThunk<
  APIResponse<{}>,
  {
    data: SuggestEntity;
    onComplete?: onCompleteCB<APIResponse<{}>>;
  }
>("restaurant/request", ({ data, onComplete }) => {
  return restaurantApi
    .requestRestaurant(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const requestRestaurantBySchool = createAsyncThunk<
  APIResponse<{}>,
  {
    data: SuggestBySchoolEntity;
    onComplete?: onCompleteCB<APIResponse<{}>>;
  }
>("restaurant/requestbyschool", ({ data, onComplete }) => {
  return restaurantApi
    .requestRestaurantBySchool(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export interface RestaurantState {
  list: {
    isFetching: boolean;
    data: Array<RestaurantEntity>;
  };
  details: {
    isFetching: boolean;
    data?: APIRestaurantDetailsResponse["payload"];
  };
  types: {
    isFetching: boolean;
    data: Array<RestaurantTypeEntity>;
  };
  request: {
    isFetching: boolean;
  };
}

const initialRestaurantState: RestaurantState = {
  list: {
    isFetching: true,
    data: [],
  },
  details: {
    isFetching: false,
  },
  types: {
    isFetching: false,
    data: [],
  },
  request: {
    isFetching: false,
  },
};

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: initialRestaurantState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listRestaurant.pending, (state) => {
        state.list.isFetching = true;
      })
      .addCase(listRestaurant.fulfilled, (state, action) => {
        state.list.isFetching = false;
        state.list.data = action.payload.payload.data;
      })
      .addCase(listRestaurant.rejected, (state) => {
        state.list.isFetching = false;
      })
      .addCase(getDetails.pending, (state) => {
        state.details.isFetching = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.details.isFetching = false;
        state.details.data = action.payload.payload;
      })
      .addCase(getDetails.rejected, (state) => {
        state.details.isFetching = false;
      })
      .addCase(getTypes.pending, (state) => {
        state.types.isFetching = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.types.isFetching = false;
        state.types.data = action.payload.payload.data;
      })
      .addCase(getTypes.rejected, (state) => {
        state.types.isFetching = false;
      })
      .addCase(requestRestaurant.pending, (state) => {
        state.request.isFetching = true;
      })
      .addCase(requestRestaurant.fulfilled, (state) => {
        state.request.isFetching = false;
      })
      .addCase(requestRestaurant.rejected, (state) => {
        state.request.isFetching = false;
      })
      .addCase(requestRestaurantBySchool.pending, (state) => {
        state.request.isFetching = true;
      })
      .addCase(requestRestaurantBySchool.fulfilled, (state) => {
        state.request.isFetching = false;
      })
      .addCase(requestRestaurantBySchool.rejected, (state) => {
        state.request.isFetching = false;
      });
  },
});

export const restaurantReducer = restaurantSlice.reducer;

export const restaurantActions = {
  ...restaurantSlice.actions,
  listRestaurant,
  getDetails,
  getTypes,
  requestRestaurant,
  requestRestaurantBySchool,
};
