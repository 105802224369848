import React from "react";
import { Link } from "react-router-dom";
import AddExistingCardForm from "../../modules/student/AddExistingCardForm";

const DashboardNewAccount = () => {
  return (
    <section className="get-started">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="get-started-banner">
              <div>
                <h1>Get Started Here</h1>
                <h5>Choose an option below to obtain your OCMP Card</h5>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <Link
                      to="#addExistingCard"
                      className="btn btn-outline"
                      data-bs-toggle="modal"
                    >
                      Add an Existing Card
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/my-card-add-funds" className="btn btn-outline">
                      Create a New Card
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddExistingCardForm />
    </section>
  );
};

export default DashboardNewAccount;
