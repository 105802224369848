import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { useAppAlert } from "../../app/AlertContext";
import { APP_ROUTES } from "../../app/routes";
import { authActions } from "../../state/auth/authSlice";
import { getStudent } from "../../state/student/studentSlice";

type ResetPasswordFormValues = {
  resetPassword: string;
  confirmResetPassword: string;
};

const ResetPasswordFormValidateSchema = Yup.object({
  resetPassword: Yup.string()
    .required("Please provide a password.")
    .min(6, "Your password must be at least 6 characters long."),
  confirmResetPassword: Yup.string()
    .required("Please provide a confirm password.")
    .min(6, "Your password must be at least 6 characters long.")
    .oneOf(
      [Yup.ref("resetPassword"), null],
      "Confirm reset password must match with reset password"
    ),
});

const initialValues: ResetPasswordFormValues = {
  resetPassword: "",
  confirmResetPassword: "",
};

const ResetPasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pushMessage } = useAppAlert();

  const formHandlers = useFormik<ResetPasswordFormValues>({
    initialValues,
    validationSchema: ResetPasswordFormValidateSchema,
    onSubmit: ({ resetPassword, confirmResetPassword }) => {
      dispatch(
        authActions.resetPassword({
          data: {
            reset_password: resetPassword,
            confirm_reset_password: confirmResetPassword,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              dispatch(getStudent()).then(() => {
                navigate(APP_ROUTES.Dashboard.path);
              });
              return;
            }
            pushMessage({
              content:
                data?.message ||
                "Something went wrong on our end. Please try again",
              kind: "error",
              autoDismiss: true,
            });
            return;
          },
        })
      );
    },
  });

  return (
    <form
      id="reset-password-form"
      noValidate
      onSubmit={formHandlers.handleSubmit}
    >
      <div className="form-group">
        <label>New Password *</label>
        <input
          type="password"
          name="resetPassword"
          placeholder="New password"
          className="form-control"
          onChange={formHandlers.handleChange}
          onBlur={formHandlers.handleBlur}
          value={formHandlers.values.resetPassword}
        />
        {formHandlers.touched.resetPassword &&
        formHandlers.errors.resetPassword ? (
          <label className="error">{formHandlers.errors.resetPassword}</label>
        ) : null}
      </div>
      <div className="form-group">
        <label>Confirm New Password *</label>
        <input
          type="password"
          name="confirmResetPassword"
          placeholder="Confirm new password"
          className="form-control"
          onChange={formHandlers.handleChange}
          onBlur={formHandlers.handleBlur}
          value={formHandlers.values.confirmResetPassword}
        />
        {formHandlers.touched.confirmResetPassword &&
        formHandlers.errors.confirmResetPassword ? (
          <label className="error">
            {formHandlers.errors.confirmResetPassword}
          </label>
        ) : null}
      </div>
      <div className="d-grid">
        <button type="submit" className="btn btn-primary btn-block">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
