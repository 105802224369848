import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppDispatch } from "../../app/hooks";
import BreadCrumbLayout from "../../components/layout/BreadCrumbLayout";
import DashboardLayout from "../../components/layout/DashboardLayout";
import ChangePINForm from "../../modules/mycard/ChangePINForm";
import { getStudent } from "../../state/student/studentSlice";
import { currentStudentSelector } from "../../state/student/studentSelector";
import { APP_ROUTES } from "../../app/routes";
import ReportCard from "../../modules/mycard/ReportCard";
import Card from "../../modules/mycard/Card";
import PrintTempCard from "../../modules/mycard/PrintTempCard";

const MyCardInstitutionFunded: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentStudentState = useSelector(currentStudentSelector);
  useEffect(() => {
    dispatch(getStudent());
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Card | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          My Card
        </li>
      </BreadCrumbLayout>
      {/* transaction */}
      <section className="transaction mycard">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Card
                cardData={currentStudentState.data?.data.cardData}
                cardLoading={currentStudentState.isFetching}
                studentData={currentStudentState.data?.data?.studentData}
              />
            </div>
            <div className="col-md-6">
              <div
                className="card mini-card justify-content-md-start h-100 mt-4 mt-md-0"
                style={{ minHeight: 0 }}
              >
                <Link
                  to={APP_ROUTES.MyCardAddFunds.path}
                  className="btn btn-primary w100 d-flex align-items-center justify-content-center mb-2"
                >
                  <span className="me-1">Add Funds</span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 12H20.25"
                      stroke="#ffffff"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 3.75V20.25"
                      stroke="#ffffff"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <Link
                  to="#changePin"
                  data-bs-toggle="modal"
                  className="btn btn-outline"
                >
                  Change My Pin
                </Link>
                <Link
                  to="#stolenCard"
                  data-bs-toggle="modal"
                  className="btn btn-outline"
                >
                  Report Lost / Stolen card
                </Link>
                {(currentStudentState.data?.data.cardData?.TempCardEnabled ===
                  "1" ||
                  currentStudentState.data?.data.cardData?.TempCardEnabled ===
                    "0") && (
                  <Link
                    to="#printTempCard"
                    className="btn btn-outline-danger"
                    data-bs-toggle="modal"
                  >
                    Print Temp Card
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* changePin */}
      <ChangePINForm />
      {/* stolenCard */}
      <ReportCard reportData={currentStudentState.data?.data?.studentData} />
      {/* tempCart */}
      <PrintTempCard
        cardData={currentStudentState.data?.data.cardData}
        studentData={currentStudentState.data?.data?.studentData}
      />
    </DashboardLayout>
  );
};

export default MyCardInstitutionFunded;
