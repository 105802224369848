import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";
import DashboardLayout from "../components/layout/DashboardLayout";
import { listMessages } from "../state/student/studentSlice";
import { listMessagesSelector } from "../state/student/studentSelector";
import { Spinner } from "reactstrap";
import { Helmet } from "react-helmet";

const Messages = () => {
  const dispatch = useAppDispatch();
  const listMessagesState = useSelector(listMessagesSelector);
  const [messageData, setMessageData] = useState(listMessagesState?.data);

  const listMessageType = listMessagesState.data.transactions.filter(
    (message, index, array) => {
      return (
        array.findIndex((t) => t.message_type === message.message_type) ===
        index
      );
    }
  );

  useEffect(() => {
    dispatch(listMessages());
  }, []);

  useEffect(() => {
    setMessageData(listMessagesState?.data);
  }, [listMessagesState]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "all") {
      setMessageData(listMessagesState.data);
    } else {
      const newMessageData = listMessagesState.data.transactions.filter(
        (message) => {
          return message.message_type === event.target.value;
        }
      );
      setMessageData({
        transactions: newMessageData,
      });
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Messages | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Messages
        </li>
      </BreadCrumbLayout>
      {/* messages */}
      <section className="messages">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="form-group">
                  <select
                    name="type"
                    id="type"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="all">All</option>
                    {listMessageType?.map((message, key) => {
                      return (
                        <option key={key} value={message.message_type}>
                          {message.message_type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="table-responsive">
                  <table className="table message-table">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messageData.transactions.map((message, key) => {
                        return (
                          <tr key={key}>
                            <td>{message.message_type}</td>
                            <td>{message.message}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {listMessagesState.isFetching && (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Spinner type="border" color="primary" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Messages;
