import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { useAppDispatch } from "../app/hooks";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";
import DashboardLayout from "../components/layout/DashboardLayout";
// import EditAutoReloadModal from "../modules/settings/EditAutoReloadModal";
import EditNotificationModal from "../modules/settings/EditNotificationModal";
import EditPaymentModal from "../modules/settings/EditPaymentModal";
import EditProfileModal from "../modules/settings/EditProfileModal";
// import EditRenewModal from "../modules/settings/EditRenewModal";
import EditSecurityModal from "../modules/settings/EditSecurityModal";
import { paymentSelector } from "../state/payment/paymentSelector";
import { listStoredPaymentOptions } from "../state/payment/paymentSlice";
import {
  currentStudentSelector,
  getSettingSMSSelector,
} from "../state/student/studentSelector";
import { getSettingSMS, getStudent } from "../state/student/studentSlice";
import { getCardLogo } from "../utils/logo";
import { toMaskedCardNumber, toPhoneNumber } from "../utils/string";

const Settings = () => {
  const dispatch = useAppDispatch();
  const settingSMSState = useSelector(getSettingSMSSelector);
  const studentState = useSelector(currentStudentSelector);
  const paymentState = useSelector(paymentSelector);

  // Reload page if AcceptUI is already loaded
  useEffect(() => {
    if (window.AcceptUI) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    dispatch(getSettingSMS());
    dispatch(getStudent());
    dispatch(listStoredPaymentOptions());
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Settings
        </li>
      </BreadCrumbLayout>
      {/* settings */}
      <section className="settings">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="row">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/profile.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Profile</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row">
                      <div className="col-sm-10">
                        {studentState.isFetching && (
                          <div className="center-spinner">
                            <Spinner type="border" color="primary" />
                          </div>
                        )}{" "}
                        {!studentState.isFetching &&
                          studentState.data?.data?.studentData && (
                            <>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="f500">Full Name</div>
                                  <div>{`${studentState.data?.data?.studentData.first_name} ${studentState.data?.data?.studentData.last_name}`}</div>
                                  <div className="divider" />
                                </div>
                                <div className="col-sm-6">
                                  <div className="f500">Email Address</div>
                                  <div>
                                    {
                                      studentState.data?.data?.studentData
                                        .EmailAddress
                                    }
                                  </div>
                                  <div className="divider" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="f500">School</div>
                                  <div>
                                    {
                                      studentState.data?.data?.studentData
                                        .schoolName
                                    }
                                  </div>
                                  <div className="divider" />
                                </div>
                                <div className="col-sm-6">
                                  <div className="f500">
                                    Expected Graduation Year
                                  </div>
                                  <div>
                                    {
                                      studentState.data?.data?.studentData
                                        .GraduationYear
                                    }
                                  </div>
                                  <div className="divider" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="f500">
                                    Permanent Address
                                  </div>
                                  <div>
                                    {
                                      studentState.data?.data?.studentData
                                        .HomeAddr_A
                                    }
                                    <br />
                                    {studentState.data?.data?.studentData
                                      .HomeAddr_B !== "" && (
                                      <>
                                        {
                                          studentState.data?.data?.studentData
                                            .HomeAddr_B
                                        }
                                        <br />
                                      </>
                                    )}
                                    {`${studentState.data?.data?.studentData.HomeCity}, ${studentState.data?.data?.studentData.HomeState} ${studentState.data?.data?.studentData.HomeZip}`}
                                    <br />
                                    <br />
                                    {toPhoneNumber(
                                      studentState.data?.data?.studentData
                                        .HomePhone
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="f500">School Address</div>
                                  <div>
                                    {
                                      studentState.data?.data?.studentData
                                        .SchoolAddr_A
                                    }
                                    <br />
                                    {studentState.data?.data?.studentData
                                      .SchoolAddr_B !== "" && (
                                      <>
                                        {
                                          studentState.data?.data?.studentData
                                            .SchoolAddr_B
                                        }
                                        <br />
                                      </>
                                    )}

                                    {`${studentState.data?.data?.studentData.SchoolCity}, ${studentState.data?.data?.studentData.SchoolState} ${studentState.data?.data?.studentData.SchoolZip}`}
                                    <br />
                                    <br />
                                    {toPhoneNumber(
                                      studentState.data?.data?.studentData
                                        .SchoolPhone
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editProfile"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ minHeight: 0 }}>
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/security.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Security</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row align-items-center">
                      <div className="col-sm-10">
                        <div className="md-title">
                          Click here to change your password
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editSecurity"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ minHeight: 0 }}>
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/notification.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Notification</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row align-items-center">
                      <div className="col-sm-10">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="f500">SMS Phone Number</div>
                            <div>
                              {toPhoneNumber(
                                settingSMSState.data?.data.sms_number
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="f500">Notifications</div>
                            <div className="pe-none">
                              <input
                                type="checkbox"
                                className="switchToggle"
                                id="NotificationsOption"
                                disabled
                                checked={
                                  settingSMSState.data?.data.enabled === "1"
                                }
                              />
                              <label htmlFor="NotificationsOption" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editNotification"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ minHeight: 0 }}>
                <div className="row">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/payment.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Payment</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row">
                      <div className="col-sm-10">
                        {paymentState.isFetching && (
                          <div className="center-spinner">
                            <Spinner type="border" color="primary" />
                          </div>
                        )}

                        {!paymentState.isFetching &&
                          paymentState.data.length > 0 && (
                            <>
                              <div className="row f500 mb-3">
                                <div className="col-sm-6">Type</div>
                                <div className="col-sm-6">Number</div>
                              </div>
                              {paymentState.data.map((p) => (
                                <div
                                  className="row mb-3"
                                  key={p.payment_profile_id}
                                >
                                  <div className="col-sm-6 d-flex gap-2 align-items-center">
                                    <img
                                      src={getCardLogo(p.CardType)}
                                      alt="card-type"
                                      className="border rounded"
                                      width={36}
                                    />
                                    <div className="f500">{p.CardType}</div>
                                  </div>
                                  <div
                                    className="col-sm-6 d-inline-block f300"
                                    dangerouslySetInnerHTML={{
                                      __html: toMaskedCardNumber(
                                        p.CreditCardNumber
                                      ),
                                    }}
                                  />
                                </div>
                              ))}
                            </>
                          )}

                        {!paymentState.isFetching &&
                          paymentState.data.length === 0 && (
                            <span className="mb-2">
                              There are no saved credit cards.
                            </span>
                          )}

                        {/* <li className="list-inline-item f500">
                          <span className="badge badge-primary">PRIMARY</span>
                        </li> */}
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editPayment"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card" style={{ minHeight: 0 }}>
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/auto-renew.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Auto Renew</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row align-items-center">
                      <div className="col-sm-10">
                        <div className="f500">
                          Get your plan automatically renewed each semester?
                        </div>
                        <div className="mt-2">
                          <input
                            type="checkbox"
                            className="switchToggle"
                            defaultChecked
                            id="autoRenewOption"
                          />
                          <label htmlFor="autoRenewOption" />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editAutoRenew"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ minHeight: 0 }}>
                <div className="row">
                  <div className="col-xl-4 col-lg-3">
                    <div className="d-flex align-items-center">
                      <img src="img/auto-reload.svg" alt="" />
                      <h4 className="xl-title ms-3 mb-0">Auto Reload</h4>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9">
                    <div className="row">
                      <div className="col-sm-10">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="f500">Auto Reload Card</div>
                            <div className="mt-2">
                              <input
                                type="checkbox"
                                className="switchToggle"
                                defaultChecked
                                id="autoReloadOption"
                              />
                              <label htmlFor="autoReloadOption" />
                            </div>
                            <div className="divider" />
                          </div>
                          <div className="col-sm-6">
                            <div className="f500">
                              Automatically Reload when card balance reaches:
                            </div>
                            <div>$100</div>
                            <div className="divider" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="f500">
                              Amount of Meals To Reload
                            </div>
                            <div>100</div>
                          </div>
                          <div className="col-sm-6">
                            <div className="f500">Amount to Reload</div>
                            <div>$1035.00</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn btn-outline btn-edit w100 d-block"
                          data-bs-target="#editAutoReload"
                          data-bs-toggle="modal"
                        >
                          Edit
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.19063 20.2499H5C4.80109 20.2499 4.61033 20.1709 4.46967 20.0302C4.32902 19.8896 4.25 19.6988 4.25 19.4999V15.3093C4.24966 15.2119 4.26853 15.1154 4.30553 15.0253C4.34253 14.9352 4.39694 14.8533 4.46563 14.7843L15.7156 3.53429C15.7854 3.46343 15.8686 3.40715 15.9603 3.36874C16.0521 3.33033 16.1505 3.31055 16.25 3.31055C16.3495 3.31055 16.4479 3.33033 16.5397 3.36874C16.6314 3.40715 16.7146 3.46343 16.7844 3.53429L20.9656 7.71554C21.0365 7.78533 21.0928 7.86851 21.1312 7.96026C21.1696 8.052 21.1894 8.15046 21.1894 8.24992C21.1894 8.34938 21.1696 8.44784 21.1312 8.53958C21.0928 8.63132 21.0365 8.71451 20.9656 8.78429L9.71563 20.0343C9.6466 20.103 9.56469 20.1574 9.4746 20.1944C9.38452 20.2314 9.28802 20.2503 9.19063 20.2499V20.2499Z"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.25 6L18.5 11.25"
                              stroke="#00529A"
                              strokeWidth="1.125"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <EditProfileModal
        studentData={studentState.data?.data?.studentData}
        cardData={studentState.data?.data.cardData}
      />
      <EditSecurityModal />
      <EditNotificationModal notificationData={settingSMSState.data?.data} />
      <EditPaymentModal storedPayments={paymentState.data} />
      {/* <EditRenewModal />
      <EditAutoReloadModal /> */}
    </DashboardLayout>
  );
};

export default Settings;
