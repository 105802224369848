import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAPI, {
  APILoginInput,
  APILoginResponse,
  APIRegisterInput,
  APIForgotPasswordInput,
  APIResetPasswordInput,
  APIRegisterResponse,
} from "../../api/authApi";
import { APIResponse } from "../../api/types";
import { onCompleteCB } from "../../utils/apiClient";
import {
  clearLocalAuthCredentials,
  localAuthenticate,
  setLocalAuthCredentials,
} from "../../utils/auth";

export const register = createAsyncThunk<
  APIRegisterResponse,
  {
    data: APIRegisterInput;
    onComplete: onCompleteCB<APIRegisterResponse>;
  }
>("auth/register", ({ data, onComplete }) => {
  return authAPI
    .register(data)
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export const login = createAsyncThunk<
  APILoginResponse,
  { data: APILoginInput; onComplete: onCompleteCB<APILoginResponse> }
>("auth/login", ({ data, onComplete }) => {
  return authAPI
    .login(data)
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  ({
    data,
    onComplete,
  }: {
    data: APIForgotPasswordInput;
    onComplete: onCompleteCB<APIResponse<{}>>;
  }) => {
    return authAPI
      .forgotPassword(data)
      .then(({ data }) => {
        onComplete(null, data);
        return data;
      })
      .catch((error) => {
        onComplete(error);
        throw new Error(error.response.data);
      });
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  ({
    data,
    onComplete,
  }: {
    data: APIResetPasswordInput;
    onComplete: onCompleteCB<APIResponse<{}>>;
  }) => {
    return authAPI
      .resetPassword(data)
      .then(({ data }) => {
        onComplete(null, data);
        return data;
      })
      .catch((error) => {
        onComplete(error);
        throw new Error(error.response.data);
      });
  }
);

export interface authState {
  isAuthenticated: boolean;
  user?: {
    id: string;
    email: string;
    name: string;
  };
  login: {
    fetching: boolean;
  };
  register: {
    fetching: boolean;
  };
  forgotPassword: {
    fetching: boolean;
  };
  resetPassword: {
    fetching: boolean;
  };
}

const initialAuthState: authState = {
  isAuthenticated: localAuthenticate(),
  login: { fetching: false },
  register: { fetching: false },
  forgotPassword: { fetching: false },
  resetPassword: { fetching: false },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      clearLocalAuthCredentials();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.login.fetching = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.status || action.payload.action_code === 1502) {
          setLocalAuthCredentials(
            action.payload.payload.access_token,
            action.payload.payload.refresh_token,
            action.payload.payload.login_id
          );
          state.isAuthenticated = true;
        }

        state.login.fetching = false;
      })
      .addCase(login.rejected, (state) => {
        state.isAuthenticated = false;
        state.login.fetching = false;
      })
      .addCase(register.pending, (state) => {
        state.register.fetching = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        if (action.payload.payload) {
          setLocalAuthCredentials(
            action.payload.payload.access_token,
            action.payload.payload.refresh_token,
            action.payload.payload.login_id
          );
          state.isAuthenticated = true;
        }

        state.register.fetching = false;
      })
      .addCase(register.rejected, (state) => {
        state.isAuthenticated = false;
        state.register.fetching = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.fetching = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.fetching = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPassword.fetching = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.fetching = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.fetching = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPassword.fetching = false;
      });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = {
  ...authSlice.actions,
  register,
  login,
  forgotPassword,
  resetPassword,
};
