import { loadDetailsTransaction } from "./studentSlice";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const currentStudentSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.currentStudent
);

export const studentFavoritesSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.getStudentFavorites
);

export const getSettingSMSSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.getSettingSMS
);

export const getQRCodeSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.getQRCode
);

export const listMessagesSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.listMessages
);

export const loadTransactionsSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.loadTransactions
);

export const loadDetailsTransactionSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.loadDetailsTransaction
);

export const transactionsSelector = createSelector(
  (state: RootState) => state.student,
  (state) => state.getTransactions
);
