import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { studentActions } from "../../state/student/studentSlice";
import { useAppAlert } from "../../app/AlertContext";

type RestaurantListProps = {
  studentFavorite: string | undefined;
  mID: string;
  checkboxID: string;
  handleChangeFavorite: any;
};

const FavoriteCheckbox: React.FC<RestaurantListProps> = ({
  studentFavorite,
  mID,
  checkboxID,
  handleChangeFavorite,
}) => {
  const dispatch = useAppDispatch();
  const { pushMessage } = useAppAlert();
  const [checked, setChecked] = useState<boolean>(() =>
    studentFavorite === "1" ? true : false
  );

  const postFavorite = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(
        studentActions.postStudentFavorite({
          data: {
            m_id: mID,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setChecked(true);
              handleChangeFavorite();
              return;
            }
            pushMessage({
              content:
                data?.message ||
                "Something went wrong on our end. Please try again",
              kind: "error",
              autoDismiss: true,
            });
            return;
          },
        })
      );
    } else {
      dispatch(
        studentActions.deleteStudentFavorite({
          data: {
            m_id: mID,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setChecked(false);
              handleChangeFavorite();
              return;
            }
            pushMessage({
              content:
                data?.message ||
                "Something went wrong on our end. Please try again",
              kind: "error",
              autoDismiss: true,
            });
            return;
          },
        })
      );
    }
  };

  useEffect(() => {
    if (studentFavorite === "1") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [studentFavorite, mID]);

  return (
    <React.Fragment>
      <input
        type="checkbox"
        className="favRestaurant"
        id={`fav${checkboxID}`}
        checked={checked}
        onChange={postFavorite}
      />
      <label htmlFor={`fav${checkboxID}`} className="m-0 ms-3" />
    </React.Fragment>
  );
};

export default FavoriteCheckbox;
