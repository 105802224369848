import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { HostedForm } from "react-acceptjs";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import * as Yup from "yup";
import { MealPlanEntity } from "../../api/mealPlanApi";
import { PurchaseEntity } from "../../api/studentApi";
import { useAppDispatch } from "../../app/hooks";
import AddressSelection from "../../components/common/AddressSelection";
import GraduationYearSelection from "../../components/common/GraduationYearSelection";
import BreadCrumbLayout from "../../components/layout/BreadCrumbLayout";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { authData } from "../../constants/authdata";
import PlanList, { getMealPrice } from "../../modules/addFunds/PlanList";
import ProgramCard from "../../modules/mycard/ProgramCard";
import { getPlanDescriptions } from "../../services/service-data";
import { listMealPlanSelector } from "../../state/mealPlan/mealPlanSelector";
import { listMealPlans } from "../../state/mealPlan/mealPlanSlice";
import { paymentSelector } from "../../state/payment/paymentSelector";
import { listStoredPaymentOptions } from "../../state/payment/paymentSlice";
import { programDetailSelector } from "../../state/program/programSelector";
import { getDetailProgram } from "../../state/program/programSlice";
import { currentStudentSelector } from "../../state/student/studentSelector";
import { getStudent, studentActions } from "../../state/student/studentSlice";
import { getCardLogo } from "../../utils/logo";
import {
  formatNumber,
  fromPhoneNumberToString,
  toMaskedCardNumber,
  toPhoneNumber,
} from "../../utils/string";

type AddFundFormValues = {
  first_name?: string;
  last_name?: string;
  EmailAddress?: string;
  HomeAddr_A?: string;
  HomeAddr_B?: string;
  HomeCity?: string;
  HomeState?: string;
  HomeZip?: string;
  HomePhone?: string;
  SchoolAddr_A?: string;
  SchoolAddr_B?: string;
  SchoolCity?: string;
  SchoolState?: string;
  SchoolZip?: string;
  SchoolPhone?: string;
  BillingFirstName?: string;
  BillingLastName?: string;
  BillingAddr_A?: string;
  BillingAddr_B?: string;
  BillingCity?: string;
  BillingState?: string;
  BillingZip?: string;
  Mailto?: string;
  PIN?: number;
  GraduationYear?: string;
  name_on_card?: string;
  student_email?: string;
  additionalMeals: number;
  plan_id?: string;
  plan_meals_added?: number;
  amount?: string;
  payment_method?: string;
  AuthDataValue?: string;
  AuthDataDescriptor?: string;
  store_payment_info?: number;
  payment_profile_id?: string;
};

const AddFundsByCCFormValidateSchema = Yup.object({
  first_name: Yup.string().nullable().required("Please provide first name."),
  last_name: Yup.string().nullable().required("Please provide last name."),
  EmailAddress: Yup.string()
    .nullable()
    .email()
    .required("Please provide email address."),
  student_email: Yup.string()
    .nullable()
    .email("Please provide valid student email address.")
    .required("Please provide student email address."),
  name_on_card: Yup.string()
    .nullable()
    .required("Please provide name on card."),
  HomeAddr_A: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  HomeAddr_B: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable(),
  HomeCity: Yup.string().nullable().required("Please provide home city."),
  HomeState: Yup.string().nullable().required("Please provide home state."),
  HomeZip: Yup.string().nullable().required("Please provide home zip."),
  HomePhone: Yup.string().nullable().required("Please provide home phone."),
  SchoolAddr_A: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  SchoolAddr_B: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable(),
  SchoolCity: Yup.string().nullable().required("Please provide school city."),
  SchoolState: Yup.string().nullable().required("Please provide school state."),
  SchoolZip: Yup.string().nullable().required("Please provide school zip."),
  SchoolPhone: Yup.string().nullable().required("Please provide school phone."),
  PIN: Yup.number()
    .nullable()
    .min(1000)
    .max(9999)
    .required("Please provide a PIN."),
  GraduationYear: Yup.string()
    .nullable()
    .required("Please provide expected graduation year."),
  additionalMeals: Yup.number()
    .nullable()
    .min(0, "Please provide valid number of additional meals.")
    .required("Please provide additional meals."),
  Mailto: Yup.string().required("Please provide a shipping option."),
  BillingAddr_A: Yup.string()
    .nullable()
    .required("Please provide billing address."),
  BillingCity: Yup.string().nullable().required("Please provide billing city."),
  BillingState: Yup.string()
    .nullable()
    .required("Please provide billing state."),
  BillingZip: Yup.string().nullable().required("Please provide billing zip."),
  BillingFirstName: Yup.string()
    .nullable()
    .required("Please provide billing first name."),
  BillingLastName: Yup.string()
    .nullable()
    .required("Please provide billing last name."),
});

const AddFundsBySavedCreditCardFormValidateSchema = Yup.object({
  first_name: Yup.string().nullable().required("Please provide first name."),
  last_name: Yup.string().nullable().required("Please provide last name."),
  EmailAddress: Yup.string()
    .nullable()
    .email()
    .required("Please provide email address."),
  HomeAddr_A: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  HomeAddr_B: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable(),
  HomeCity: Yup.string().nullable().required("Please provide home city."),
  HomeState: Yup.string().nullable().required("Please provide home state."),
  HomeZip: Yup.string().nullable().required("Please provide home zip."),
  HomePhone: Yup.string().nullable().required("Please provide home phone."),
  SchoolAddr_A: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  SchoolAddr_B: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable(),
  SchoolCity: Yup.string().nullable().required("Please provide school city."),
  SchoolState: Yup.string().nullable().required("Please provide school state."),
  SchoolZip: Yup.string().nullable().required("Please provide school zip."),
  SchoolPhone: Yup.string().nullable().required("Please provide school phone."),
  PIN: Yup.number()
    .nullable()
    .min(1000)
    .max(9999)
    .required("Please provide a PIN."),
  GraduationYear: Yup.string()
    .nullable()
    .required("Please provide expected graduation year."),
  additionalMeals: Yup.number()
    .nullable()
    .min(0, "Please provide valid number of additional meals.")
    .required("Please provide additional meals."),
  Mailto: Yup.string().required("Please provide a shipping option."),
  payment_profile_id: Yup.string().required(
    "Please provide a saved credit card."
  ),
});

const MyCardAddFunds = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const listMealPlanState = useSelector(listMealPlanSelector);
  const currentStudentState = useSelector(currentStudentSelector);
  const paymentState = useSelector(paymentSelector);
  const programDetailState = useSelector(programDetailSelector);

  const [planDescriptions, setPlanDescriptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState<MealPlanEntity | null>(null);
  const [errors, setErrors] = useState<any>([]);
  const [weeks, setWeeks] = useState<number>(0);
  const [mealsPerWeek, setMealsPerWeek] = useState<number>(0);
  const [usingAddress, setUsingAddress] = useState<"home" | "school" | "">("");
  const [usingMethod, setUsingMethod] = useState<
    "credit" | "savedCredit" | "check"
  >("credit");
  const [loading, setLoading] = useState(true);

  const { plan_name, activation_fee, base_cost, multiplier, meal_cost } =
    selectedPlan || {};

  const {
    first_name,
    last_name,
    EmailAddress,
    HomeAddr_A,
    HomeAddr_B,
    HomeCity,
    HomeZip,
    HomeState,
    HomePhone,
    SchoolAddr_A,
    SchoolAddr_B,
    SchoolCity,
    SchoolZip,
    SchoolState,
    SchoolPhone,
    GraduationYear,
  } = currentStudentState?.data?.data?.studentData || {};

  const { PIN } = currentStudentState?.data?.data.cardData || {};

  const initialValues = useMemo<AddFundFormValues>(() => {
    return {
      first_name,
      last_name,
      EmailAddress,
      HomeAddr_A,
      HomeAddr_B,
      HomeCity,
      HomeState,
      HomeZip,
      HomePhone,
      SchoolAddr_A,
      SchoolAddr_B,
      SchoolCity,
      SchoolState,
      SchoolZip,
      SchoolPhone,
      additionalMeals: 0,
      Mailto:
        currentStudentState.data?.data.cardData.CardNumber !== "" ? "N" : "",
      payment_method: "CC",
      store_payment_info: 0,
      payment_profile_id: "",
      BillingFirstName: "",
      BillingLastName: "",
      BillingAddr_A: "",
      BillingAddr_B: "",
      BillingCity: "",
      BillingState: "",
      BillingZip: "",
      AuthDataValue: "",
      AuthDataDescriptor: "",
    };
  }, [currentStudentState]);

  const paymentAuth = useMemo<{
    AuthDataValue: string;
    AuthDataDescriptor: string;
  }>(
    () => ({
      AuthDataValue: "",
      AuthDataDescriptor: "",
    }),
    []
  );

  // Reload page if AcceptUI is already loaded
  useEffect(() => {
    if (window.AcceptUI) {
      window.location.reload();
    }
  }, []);

  const formHandlers = useFormik<AddFundFormValues>({
    initialValues,
    validationSchema:
      usingMethod === "savedCredit"
        ? AddFundsBySavedCreditCardFormValidateSchema
        : AddFundsByCCFormValidateSchema,
    onSubmit: ({
      first_name,
      last_name,
      EmailAddress,
      HomeAddr_A,
      HomeAddr_B,
      HomeCity,
      HomeState,
      HomeZip,
      HomePhone,
      SchoolAddr_A,
      SchoolAddr_B,
      SchoolCity,
      SchoolState,
      SchoolZip,
      SchoolPhone,
      BillingFirstName,
      BillingLastName,
      BillingAddr_A,
      BillingAddr_B,
      BillingCity,
      BillingState,
      BillingZip,
      Mailto,
      PIN,
      GraduationYear,
      name_on_card,
      student_email,
      payment_method,
      store_payment_info,
      additionalMeals,
      payment_profile_id,
    }) => {
      if (!selectedPlan) return;
      if (
        !paymentAuth.AuthDataValue &&
        !paymentAuth.AuthDataDescriptor &&
        usingMethod === "credit"
      ) {
        return document
          .querySelector<HTMLButtonElement>(".btn-payment")
          ?.click();
      }
      setErrors([]);
      const payload: PurchaseEntity = {
        first_name,
        last_name,
        EmailAddress,
        HomeAddr_A,
        HomeAddr_B,
        HomeCity,
        HomeState,
        HomeZip,
        HomePhone: fromPhoneNumberToString(HomePhone),
        SchoolAddr_A,
        SchoolAddr_B,
        SchoolCity,
        SchoolState,
        SchoolZip,
        SchoolPhone: fromPhoneNumberToString(SchoolPhone),
        BillingFirstName,
        BillingLastName,
        BillingAddr_A,
        BillingAddr_B,
        BillingCity,
        BillingState,
        BillingZip,
        GraduationYear,
        PIN: PIN?.toString(),
        name_on_card,
        student_email,
        Mailto,
        plan_id: parseInt(selectedPlan.plan_id),
        plan_meals_added:
          selectedPlan?.plan_id === "0"
            ? weeks * mealsPerWeek
            : parseFloat(selectedPlan?.meals_added || "0"),
        additional_meals: additionalMeals || 0,
        amount: total,
        payment_method,
        AuthDataValue: paymentAuth.AuthDataValue,
        AuthDataDescriptor: paymentAuth.AuthDataDescriptor,
        store_payment_info,
        payment_profile_id,
        parent: 0,
      };

      if (usingMethod === "savedCredit" && payment_profile_id === "") return;
      dispatch(
        studentActions.purchaseMeals({
          data: payload,
          onComplete: (error, data) => {},
        })
      )
        .unwrap()
        .then((data) => {
          if (data?.status) {
            navigate(`/order-complete/${data.payload.data.order_number}`);
            dispatch(getStudent());
            return;
          }
          if (
            data?.payload?.response_data &&
            !data?.payload?.response_data?.approved
          ) {
            setErrors([data.payload.response_data.response]);
          }
          if (data?.payload?.errors && data?.payload?.errors[0]?.length > 0) {
            setErrors(data?.payload.errors[0]);
          }

          return scroller.scrollTo("error-area", {
            duration: 500,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -150,
          });
        });
    },
  });

  useEffect(() => {
    dispatch(listStoredPaymentOptions());
    dispatch(getStudent());
  }, []);

  useEffect(() => {
    async function fetchPlanDescriptions() {
      const res = await getPlanDescriptions();
      setPlanDescriptions(res.data);
    }
    fetchPlanDescriptions();
  }, []);

  const scrollToMealPlanBox = () => {
    scroller.scrollTo("meal_plan_box", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -150,
    });
  };

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      if (currentStudentState.data?.data?.studentData.SpecialProgram === "1") {
        dispatch(
          getDetailProgram({
            data: {
              program_id:
                currentStudentState.data?.data?.studentData.program_id || "",
            },
          })
        );
      }
    }
  }, [currentStudentState]);

  useEffect(() => {
    formHandlers.setFieldValue("additionalMeals", 0);
    setWeeks(0);
    setMealsPerWeek(0);
    scrollToMealPlanBox();
  }, [selectedPlan]);

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      setLoading(true);
      dispatch(
        listMealPlans({
          data: {
            SchoolID: currentStudentState.data?.data?.studentData.SchoolID,
          },
          onComplete: () => {
            setLoading(false);
          },
        })
      );
      return;
    }
    setLoading(false);
  }, [currentStudentState]);

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData) {
      formHandlers.setValues((values) => ({
        ...values,
        first_name: currentStudentState.data?.data?.studentData.first_name,
        last_name: currentStudentState.data?.data?.studentData.last_name,
        EmailAddress: currentStudentState.data?.data?.studentData.EmailAddress,
        HomeAddr_A: currentStudentState.data?.data?.studentData.HomeAddr_A,
        HomeAddr_B: currentStudentState.data?.data?.studentData.HomeAddr_B,
        HomeCity: currentStudentState.data?.data?.studentData.HomeCity,
        HomeState: currentStudentState.data?.data?.studentData.HomeState,
        HomeZip: currentStudentState.data?.data?.studentData.HomeZip,
        HomePhone: currentStudentState.data?.data?.studentData.HomePhone,
        SchoolAddr_A: currentStudentState.data?.data?.studentData.SchoolAddr_A,
        SchoolAddr_B: currentStudentState.data?.data?.studentData.SchoolAddr_B,
        SchoolCity: currentStudentState.data?.data?.studentData.SchoolCity,
        SchoolState: currentStudentState.data?.data?.studentData.SchoolState,
        SchoolZip: currentStudentState.data?.data?.studentData.SchoolZip,
        SchoolPhone: currentStudentState.data?.data?.studentData.SchoolPhone,
        GraduationYear:
          currentStudentState.data?.data?.studentData.GraduationYear,
        PIN: parseInt(currentStudentState.data?.data.cardData?.PIN || "0"),
        name_on_card: currentStudentState.data?.data?.studentData?.name_on_card,
        student_email:
          currentStudentState.data?.data?.studentData?.student_email,
      }));
    }
  }, [currentStudentState.data?.data]);

  const totalMeals = useMemo<number | undefined>(() => {
    if (selectedPlan) {
      if (selectedPlan?.plan_id === "0") {
        return (
          weeks * mealsPerWeek + (formHandlers?.values?.additionalMeals || 0)
        );
      }
      return (
        parseFloat(selectedPlan?.meals_added) +
        (formHandlers?.values?.additionalMeals || 0)
      );
    }
  }, [
    weeks,
    mealsPerWeek,
    selectedPlan?.meals_added,
    formHandlers?.values?.additionalMeals,
  ]);

  const total = useMemo<number | undefined>(() => {
    let totalPrice = 0;
    if (base_cost && activation_fee && multiplier && meal_cost) {
      if (selectedPlan?.plan_id === "0") {
        // Custom Meal Plan
        const mealCount =
          weeks * mealsPerWeek + (formHandlers?.values?.additionalMeals || 0);
        totalPrice = mealCount
          ? (mealCount * meal_cost +
              (currentStudentState?.data?.data.cardData.Active === "0"
                ? +activation_fee
                : 0)) *
            multiplier
          : 0;
      } else
        totalPrice =
          +base_cost +
          (formHandlers?.values?.additionalMeals * +meal_cost +
            (currentStudentState?.data?.data.cardData.Active === "0"
              ? +activation_fee
              : 0)) *
            +multiplier;
    }

    const format = (num: number) =>
      num.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    return parseFloat(
      format(parseFloat(totalPrice.toFixed(3))).replace(",", "")
    );
  }, [
    weeks,
    mealsPerWeek,
    base_cost,
    activation_fee,
    multiplier,
    meal_cost,
    formHandlers.values.additionalMeals,
  ]);

  const onSelectPlan = (plan: MealPlanEntity) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    if (usingAddress === "home") {
      formHandlers.setValues((values) => ({
        ...values,
        BillingAddr_A: formHandlers.values.HomeAddr_A,
        BillingAddr_B: formHandlers.values.HomeAddr_B,
        BillingCity: formHandlers.values.HomeCity,
        BillingState: formHandlers.values.HomeState,
        BillingZip: formHandlers.values.HomeZip,
      }));
      return;
    } else if (usingAddress === "school") {
      formHandlers.setValues((values) => ({
        ...values,
        BillingAddr_A: formHandlers.values.SchoolAddr_A,
        BillingAddr_B: formHandlers.values.SchoolAddr_B,
        BillingCity: formHandlers.values.SchoolCity,
        BillingState: formHandlers.values.SchoolState,
        BillingZip: formHandlers.values.SchoolZip,
      }));
    }
    return;
  }, [
    usingAddress,
    formHandlers.values.HomeAddr_A,
    formHandlers.values.HomeAddr_B,
    formHandlers.values.HomeCity,
    formHandlers.values.HomeState,
    formHandlers.values.HomeZip,
    formHandlers.values.SchoolAddr_A,
    formHandlers.values.SchoolAddr_B,
    formHandlers.values.SchoolCity,
    formHandlers.values.SchoolState,
    formHandlers.values.SchoolZip,
  ]);

  const resetBillingAddressForm = () => {
    formHandlers.setValues((values) => ({
      ...values,
      BillingFirstName: "",
      BillingLastName: "",
      BillingAddr_A: "",
      BillingAddr_B: "",
      BillingCity: "",
      BillingState: "",
      BillingZip: "",
    }));
    setTimeout(() => formHandlers.setErrors({}), 0);
  };

  const changeBillingAddress = (e: any, method: "home" | "school" | "") => {
    if (e.target.checked && usingAddress === method) {
      return setUsingAddress("");
    }
    return setUsingAddress(method);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Funds | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item">
          <Link to="/my-card">My Card</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Add Funds
        </li>
      </BreadCrumbLayout>
      {currentStudentState.data?.data?.studentData.SpecialProgram === "1" ? (
        <section className="transaction mycard">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="card text-center justify-content-center">
                  <h2 className="no_fund_title">No Funds Can Be Added</h2>
                  <p className="dark-text mb-0">
                    Please contact your program representitive to add funds to
                    your card
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <ProgramCard
                  programData={programDetailState.data}
                  amountProvided={
                    currentStudentState.data.data.cardData.Balance
                      .StartingAmount
                  }
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <PlanList
            loading={loading}
            mealData={listMealPlanState.data}
            handleSelectPlan={onSelectPlan}
            hasCard={currentStudentState?.data?.data.cardData.Active !== "0"}
            planDescriptions={planDescriptions}
          />
          {/* mealPlan */}
          {selectedPlan && (
            <section className="mealPlan">
              <form onSubmit={formHandlers.handleSubmit} className="container">
                <div className="row">
                  <div className="col-xl-9">
                    {selectedPlan.plan_id !== "0" && (
                      <div className="card min-h-0 meal_plan_box">
                        <div className="row">
                          <div className="col-lg-6">
                            <h2 className="xl-title mt-3">{plan_name}</h2>
                            <p>
                              {planDescriptions &&
                                _.get(
                                  planDescriptions,
                                  `${selectedPlan.plan_id}.description`
                                )}
                            </p>

                            <span>
                              {formatNumber(
                                getMealPrice(
                                  selectedPlan,
                                  currentStudentState?.data?.data.cardData
                                    .Active !== "0"
                                )
                              )}
                            </span>
                          </div>
                          <div className="col-lg-6">
                            <p className="f500 mt-3 mb-2">Add Meals</p>
                            <p className="dark-text f400">
                              Enter any number of additional meals that you
                              would like add to your plan
                            </p>
                            <input
                              type="text"
                              required
                              className="form-control max-width-172"
                              value={formHandlers.values.additionalMeals}
                              onChange={(e) => {
                                let inputNumber = Number(e.target.value) || 0;
                                if (!formHandlers.values.additionalMeals) {
                                  if (
                                    inputNumber.toString().length === 2 &&
                                    inputNumber.toString()[
                                      inputNumber.toString().length - 1
                                    ] === "0"
                                  ) {
                                    formHandlers.setFieldValue(
                                      "additionalMeals",
                                      inputNumber / 10
                                    );
                                    return;
                                  }
                                  formHandlers.setFieldValue(
                                    "additionalMeals",
                                    inputNumber
                                  );
                                  return;
                                }

                                formHandlers.setFieldValue(
                                  "additionalMeals",
                                  parseFloat(e.target.value) || 0
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-lg-none">
                          <hr className="mb-20 mt-30" />
                          <div className="md-title fw-bold primary-text">
                            Total: {formatNumber(total || 0)}
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedPlan.plan_id === "0" && (
                      <div className="card min-h-0 meal_plan_box">
                        <h2 className="xl-title mt-3">Custom Meal Plan</h2>
                        <p className="dark-text f400">
                          Fill in the number of weeks you will be attending
                          school and the amount of time you
                          <br /> see yourself eating off-campus and we will
                          calculate a meal plan to fit your needs
                        </p>
                        <div className="row">
                          <div className="col-lg-4">
                            <p className="f500">
                              How many weeks will you be attending school?
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              value={weeks}
                              onChange={(e) =>
                                setWeeks(parseInt(e.target.value) || 0)
                              }
                            />
                          </div>
                          <div className="col-lg-4">
                            <p className="f500">
                              How many times per week do you see yourself eating
                              off campus?
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              value={mealsPerWeek}
                              onChange={(e) =>
                                setMealsPerWeek(parseInt(e.target.value) || 0)
                              }
                            />
                          </div>
                          <div className="col-lg-4">
                            <p className="f500">
                              Enter any number of additional meals that you
                              would like add to your plan
                            </p>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="additionalMeals"
                              value={formHandlers.values.additionalMeals || 0}
                              onBlur={formHandlers.handleBlur}
                              onChange={(e) => {
                                formHandlers.setFieldValue(
                                  "additionalMeals",
                                  +e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-lg-none">
                          <hr className="mb-20 mt-30" />
                          <div className="md-title fw-bold primary-text">
                            Total: {formatNumber(total || 0)}
                          </div>
                        </div>
                      </div>
                    )}

                    {(!PIN || !parseInt(PIN) || !GraduationYear) && (
                      <div className="card min-h-0 meal_plan_box">
                        <h2 className="xl-title mt-3">New Account Details</h2>
                        <div className="row">
                          <div className="col-lg-6">
                            <h2 className="lg-title mt-3">Student Name to Appear On Card</h2>
                          </div>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              name="name_on_card"
                              required={
                                currentStudentState.data?.data.cardData
                                  .CardNumber === ""
                              }
                              disabled={
                                currentStudentState.data?.data.cardData
                                  .CardNumber !== ""
                              }
                              className="form-control max-width-172"
                              value={formHandlers.values.name_on_card}
                              onBlur={formHandlers.handleBlur}
                              onChange={formHandlers.handleChange}
                            />
                            {formHandlers.touched.name_on_card &&
                            formHandlers.errors.name_on_card ? (
                              <label className="error">
                                {formHandlers.errors.name_on_card}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <h2 className="lg-title mt-3">Student Email Address</h2>
                          </div>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              name="student_email"
                              required={
                                currentStudentState.data?.data.cardData
                                  .CardNumber === ""
                              }
                              disabled={
                                currentStudentState.data?.data.cardData
                                  .CardNumber !== ""
                              }
                              className="form-control max-width-172"
                              value={formHandlers.values.student_email}
                              onBlur={formHandlers.handleBlur}
                              onChange={formHandlers.handleChange}
                            />
                            {formHandlers.touched.student_email &&
                            formHandlers.errors.student_email ? (
                              <label className="error">
                                {formHandlers.errors.student_email}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        {(!PIN || !parseInt(PIN)) && (
                          <div className="row">
                            <div className="col-lg-6">
                              <h2 className="lg-title mt-3">Create a 4 Digit Student Card PIN</h2>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                name="PIN"
                                required
                                className="form-control max-width-172"
                                value={formHandlers.values.PIN}
                                onBlur={formHandlers.handleBlur}
                                onChange={(e) => {
                                  formHandlers.setFieldValue(
                                    "PIN",
                                    parseInt(e.target.value) || 0
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {!GraduationYear && (
                          <div className="row">
                            <div className="col-lg-6">
                              <h2 className="lg-title mt-3">Graduation Year</h2>
                            </div>
                            <div className="col-lg-6">
                              <GraduationYearSelection
                                name="GraduationYear"
                                id="year"
                                className="form-control p-0 max-width-172"
                                onChange={(value: string) =>
                                  formHandlers.setFieldValue(
                                    "GraduationYear",
                                    value
                                  )
                                }
                                onBlur={formHandlers.handleBlur}
                                value={formHandlers.values.GraduationYear}
                              />

                              {formHandlers.touched.GraduationYear &&
                              formHandlers.errors.GraduationYear ? (
                                <label className="error">
                                  {formHandlers.errors.GraduationYear}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="card meal_plan_box">
                      <h2 className="xl-title mt-3 mb-28">
                        Student Home Address
                      </h2>
                      <div>
                        <div className="form-group mb-4">
                          <label htmlFor="addressA">Address 1*</label>
                          <input
                            type="text"
                            name="HomeAddr_A"
                            className="form-control"
                            placeholder="Street address"
                            onChange={formHandlers.handleChange}
                            onBlur={formHandlers.handleBlur}
                            value={formHandlers.values.HomeAddr_A}
                          />
                          {formHandlers.touched.HomeAddr_A &&
                          formHandlers.errors.HomeAddr_A ? (
                            <label className="error">
                              {formHandlers.errors.HomeAddr_A}
                            </label>
                          ) : null}
                        </div>
                        <div className="form-group mb-4">
                          <label htmlFor="addressA">Address 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Apartment #,  Suite etc."
                            name="HomeAddr_B"
                            onBlur={formHandlers.handleBlur}
                            onChange={formHandlers.handleChange}
                            value={formHandlers.values.HomeAddr_B}
                          />
                          {formHandlers.touched.HomeAddr_B &&
                          formHandlers.errors.HomeAddr_B ? (
                            <label className="error">
                              {formHandlers.errors.HomeAddr_B}
                            </label>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-sm-4 col-12 form-group mb-4">
                            <label htmlFor="city">City*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="HomeCity"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.HomeCity}
                            />
                            {formHandlers.touched.HomeCity &&
                            formHandlers.errors.HomeCity ? (
                              <label className="error">
                                {formHandlers.errors.HomeCity}
                              </label>
                            ) : null}
                          </div>
                          <div className="col-sm-4 col-12 form-group mb-4">
                            <label htmlFor="city">State*</label>
                            <AddressSelection
                              name="HomeState"
                              id="HomeState"
                              className="form-control p-0"
                              placeholder="Select a state"
                              defaultValue={formHandlers.values.HomeState}
                              onChange={(value: string) =>
                                formHandlers.setFieldValue("HomeState", value)
                              }
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.HomeState}
                            />

                            {formHandlers.errors.HomeState ? (
                              <label className="error">
                                {formHandlers.errors.HomeState}
                              </label>
                            ) : null}
                          </div>
                          <div className="col-sm-4 col-12 form-group mb-4">
                            <label htmlFor="zipCode">ZIP Code*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              name="HomeZip"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.HomeZip}
                            />
                            {formHandlers.touched.HomeZip &&
                            formHandlers.errors.HomeZip ? (
                              <label className="error">
                                {formHandlers.errors.HomeZip}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-4 col-12">
                            <label htmlFor="HomePhone">
                              Home Phone Number*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Home Phone Number"
                              name="HomePhone"
                              onBlur={formHandlers.handleBlur}
                              onChange={formHandlers.handleChange}
                              value={toPhoneNumber(
                                formHandlers.values.HomePhone
                              )}
                            />
                            {formHandlers.touched.HomePhone &&
                            formHandlers.errors.HomePhone ? (
                              <label className="error">
                                {formHandlers.errors.HomePhone}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card meal_plan_box">
                      <h2 className="xl-title mt-3 mb-28">
                        Student School Address
                      </h2>
                      <div>
                        <div className="form-group mb-4">
                          <label htmlFor="SchoolAddr_A">Address 1*</label>
                          <input
                            type="text"
                            name="SchoolAddr_A"
                            className="form-control"
                            placeholder="Street address"
                            onChange={formHandlers.handleChange}
                            onBlur={formHandlers.handleBlur}
                            value={formHandlers.values.SchoolAddr_A}
                          />
                          {formHandlers.touched.SchoolAddr_A &&
                          formHandlers.errors.SchoolAddr_A ? (
                            <label className="error">
                              {formHandlers.errors.SchoolAddr_A}
                            </label>
                          ) : null}
                        </div>
                        <div className="form-group mb-4">
                          <label htmlFor="SchoolAddr_B">Address 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Apartment #,  Suite etc."
                            name="SchoolAddr_B"
                            onBlur={formHandlers.handleBlur}
                            onChange={formHandlers.handleChange}
                            value={formHandlers.values.SchoolAddr_B}
                          />
                          {formHandlers.touched.SchoolAddr_B &&
                          formHandlers.errors.SchoolAddr_B ? (
                            <label className="error">
                              {formHandlers.errors.SchoolAddr_B}
                            </label>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-sm-4 col-12">
                            <div className="form-group mb-4">
                              <label htmlFor="SchoolCity">City*</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="SchoolCity"
                                onChange={formHandlers.handleChange}
                                onBlur={formHandlers.handleBlur}
                                value={formHandlers.values.SchoolCity}
                              />
                              {formHandlers.touched.SchoolCity &&
                              formHandlers.errors.SchoolCity ? (
                                <label className="error">
                                  {formHandlers.errors.SchoolCity}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-4 col-12">
                            <div className="form-group mb-4">
                              <label htmlFor="SchoolState">State*</label>
                              <AddressSelection
                                name="SchoolState"
                                id="SchoolState"
                                className="form-control p-0"
                                placeholder="Select a state"
                                onChange={(value: string) =>
                                  formHandlers.setFieldValue(
                                    "SchoolState",
                                    value
                                  )
                                }
                                onBlur={formHandlers.handleBlur}
                                value={formHandlers.values.SchoolState}
                              />
                              {formHandlers.errors.SchoolState ? (
                                <label className="error">
                                  {formHandlers.errors.SchoolState}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-4 col-12">
                            <div className="form-group mb-4">
                              <label htmlFor="SchoolZip">ZIP Code*</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Zip Code"
                                name="SchoolZip"
                                onChange={formHandlers.handleChange}
                                onBlur={formHandlers.handleBlur}
                                value={formHandlers.values.SchoolZip}
                              />
                              {formHandlers.touched.SchoolZip &&
                              formHandlers.errors.SchoolZip ? (
                                <label className="error">
                                  {formHandlers.errors.SchoolZip}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-4 col-12">
                            <label htmlFor="SchoolPhone">
                              School Phone Number*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="School Phone Number"
                              name="SchoolPhone"
                              onBlur={formHandlers.handleBlur}
                              onChange={formHandlers.handleChange}
                              value={toPhoneNumber(
                                formHandlers.values.SchoolPhone
                              )}
                            />
                            {formHandlers.touched.SchoolPhone &&
                            formHandlers.errors.SchoolPhone ? (
                              <label className="error">
                                {formHandlers.errors.SchoolPhone}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {currentStudentState.data?.data?.cardData?.CardNumber ===
                      "" && (
                      <div className="card min-h-0 meal_plan_box">
                        <div className="row">
                          <div className="col-12 col-md-5">
                            <h2 className="xl-title">Shipping Options</h2>
                            <p className="mt-3">
                              Select no if you would like a replacement card
                              shipped directly to you
                            </p>
                          </div>
                          <ul className="list-inline radioButton col-12 col-md-7 mb-0 row align-items-center justify-content-center">
                            <li className="list-inline-item col-12 col-md-4 me-0">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="Mailto"
                                  id="H"
                                  value="H"
                                  onChange={formHandlers.handleChange}
                                />
                                <label htmlFor="H">Ship to Home</label>
                              </div>
                            </li>
                            <li className="list-inline-item col-12 col-md-4 me-0">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="Mailto"
                                  id="S"
                                  value="S"
                                  onChange={formHandlers.handleChange}
                                />
                                <label htmlFor="S">Ship to School</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        {formHandlers.touched.Mailto &&
                        formHandlers.errors.Mailto ? (
                          <label className="error text-center">
                            {formHandlers.errors.Mailto}
                          </label>
                        ) : null}
                      </div>
                    )}
                    <div className="card min-h-0 meal_plan_box">
                      <div className="row">
                        <div className="col-xl-7">
                          <h2 className="xl-title">Payment Method</h2>
                          <p className="mt-3">
                            Select your payment method below
                          </p>
                          <ul className="list-inline radioButton row align-items-center mb-0">
                            <li className="list-inline-item me-0 col-12 col-md-4">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="payment_method"
                                  defaultChecked
                                  id="CC"
                                  value="CC"
                                  onChange={(e) => {
                                    formHandlers.handleChange(e);
                                    setUsingMethod("credit");
                                    resetBillingAddressForm();
                                  }}
                                />
                                <label
                                  htmlFor="CC"
                                  className="mb-2 mb-md-0 w100 px-2"
                                >
                                  Credit Card
                                </label>
                              </div>
                            </li>
                            <li className="list-inline-item me-0 col-12 col-md-4">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="payment_method"
                                  id="savedCredit"
                                  value="CC"
                                  onChange={(e) => {
                                    setUsingMethod("savedCredit");
                                    resetBillingAddressForm();
                                    formHandlers.setValues((values) => ({
                                      ...values,
                                      payment_method: "CC",
                                      store_payment_info: 0,
                                      payment_profile_id:
                                        paymentState.data[0]
                                          ?.payment_profile_id || "",
                                    }));
                                  }}
                                />
                                <label
                                  htmlFor="savedCredit"
                                  className="px-0 mb-2 mb-md-0 w100 px-2"
                                >
                                  Saved Credit Card
                                </label>
                              </div>
                            </li>
                            {/* <li className="list-inline-item col-12 col-md-4">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="payment_method"
                                  id="CHECK"
                                  value="CHECK"
                                  onChange={(e) => {
                                    setUsingMethod("check");
                                    resetBillingAddressForm();
                                    formHandlers.handleChange(e);
                                    formHandlers.setValues((values) => ({
                                      ...values,
                                      payment_method: "CHECK",
                                      store_payment_info: 0,
                                      payment_profile_id: "",
                                      AuthDataValue: "",
                                      AuthDataDescriptor: "",
                                    }));
                                  }}
                                />
                                <label
                                  htmlFor="CHECK"
                                  className="mb-2 mb-md-0 w100 px-2"
                                >
                                  Check
                                </label>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-xl-5 d-flex align-items-end mt-xl-0 mt-4">
                          {/* {usingMethod === "credit" && (
                            <div className="d-flex align-items-center gap-2 mb-2">
                              <input
                                type="checkbox"
                                className="switchToggle"
                                name="store_payment_info"
                                id="store_payment_info"
                                defaultChecked={
                                  formHandlers.values.store_payment_info === 1
                                }
                                onChange={(e) =>
                                  formHandlers.setFieldValue(
                                    "store_payment_info",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <label
                                htmlFor="store_payment_info"
                                className="d-inline-block v-top mb-0"
                              />
                              <span>Save this card for later use.</span>
                            </div>
                          )} */}
                          {usingMethod === "savedCredit" && (
                            <div className="row saved-credit">
                              <div className="d-flex flex-column gap-1">
                                {paymentState.data.length > 0 ? (
                                  paymentState.data.map((p, i) => (
                                    <div
                                      key={i}
                                      className="d-flex align-items-center gap-3"
                                    >
                                      <input
                                        className="d-block"
                                        type="radio"
                                        name="payment_profile_id"
                                        value={p.payment_profile_id}
                                        defaultChecked={i === 0}
                                        id={p.CreditCardNumber}
                                        onChange={formHandlers.handleChange}
                                      />
                                      <div className="d-flex align-items-center gap-2">
                                        <img
                                          src={getCardLogo(p.CardType)}
                                          alt="card"
                                          width={24}
                                        />
                                        <label
                                          className="mb-0"
                                          htmlFor={p.CreditCardNumber}
                                          dangerouslySetInnerHTML={{
                                            __html: toMaskedCardNumber(
                                              p.CreditCardNumber
                                            ),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <span className="mb-2">
                                    There are no saved credit cards.
                                  </span>
                                )}

                                <span>
                                  To add a stored payment, go to settings and
                                  edit your stored payments.
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {(usingMethod === "check" || usingMethod === "credit") && (
                      <div className="card meal_plan_box">
                        <div className="row align-items-center mb-2">
                          <h2 className="xl-title my-3 col-12 col-md-6">
                            Billing Address
                          </h2>

                          <ul className="col-md-6 list-inline radioButton row align-items-center justify-content-end mb-0 gap-3">
                            <li className="list-inline-item me-0 col-12 col-md-4">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="using_address"
                                  id="home"
                                  value="home"
                                  checked={usingAddress === "home"}
                                  onClick={(e: any) => {
                                    changeBillingAddress(e, "home");
                                  }}
                                />
                                <label htmlFor="home" className="mb-2 mb-md-0">
                                  Use Home
                                </label>
                              </div>
                            </li>
                            <li className="list-inline-item me-0 col-12 col-md-4">
                              <div className="radio">
                                <input
                                  type="radio"
                                  name="using_address"
                                  id="school"
                                  value="school"
                                  checked={usingAddress === "school"}
                                  onClick={(e: any) => {
                                    changeBillingAddress(e, "school");
                                  }}
                                />
                                <label
                                  htmlFor="school"
                                  className="mb-2 mb-md-0"
                                >
                                  Use School
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div>
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="form-group mb-4">
                                <label htmlFor="billing_first_name">
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  name="BillingFirstName"
                                  onChange={formHandlers.handleChange}
                                  onBlur={formHandlers.handleBlur}
                                  value={formHandlers.values.BillingFirstName}
                                />
                                {formHandlers.touched.BillingFirstName &&
                                formHandlers.errors.BillingFirstName ? (
                                  <label className="error text-center">
                                    {formHandlers.errors.BillingFirstName}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group mb-4">
                                <label htmlFor="billing_last_name">
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="BillingLastName"
                                  onChange={formHandlers.handleChange}
                                  onBlur={formHandlers.handleBlur}
                                  value={formHandlers.values.BillingLastName}
                                />
                                {formHandlers.touched.BillingLastName &&
                                formHandlers.errors.BillingLastName ? (
                                  <label className="error text-center">
                                    {formHandlers.errors.BillingLastName}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-4">
                            <label htmlFor="BillingAddr_A">Address 1*</label>
                            <input
                              type="text"
                              name="BillingAddr_A"
                              className="form-control"
                              placeholder="Address 1"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.BillingAddr_A}
                            />
                            {formHandlers.touched.BillingAddr_A &&
                            formHandlers.errors.BillingAddr_A ? (
                              <label className="error text-center">
                                {formHandlers.errors.BillingAddr_A}
                              </label>
                            ) : null}
                          </div>
                          <div className="form-group mb-4">
                            <label htmlFor="BillingAddr_B">Address 2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address 2"
                              name="BillingAddr_B"
                              onBlur={formHandlers.handleBlur}
                              onChange={formHandlers.handleChange}
                              value={formHandlers.values.BillingAddr_B}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-4">
                              <div className="form-group">
                                <label htmlFor="BillingCity">City*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  name="BillingCity"
                                  onChange={formHandlers.handleChange}
                                  onBlur={formHandlers.handleBlur}
                                  value={formHandlers.values.BillingCity}
                                />
                                {formHandlers.touched.BillingCity &&
                                formHandlers.errors.BillingCity ? (
                                  <label className="error text-center">
                                    {formHandlers.errors.BillingCity}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <div className="form-group">
                                <label htmlFor="BillingState">State*</label>
                                <AddressSelection
                                  name="BillingState"
                                  id="BillingState"
                                  className="form-control p-0"
                                  placeholder="Select a state"
                                  defaultValue={
                                    formHandlers.values.BillingState
                                  }
                                  onChange={(value: string) =>
                                    formHandlers.setFieldValue(
                                      "BillingState",
                                      value
                                    )
                                  }
                                  onBlur={formHandlers.handleBlur}
                                  value={formHandlers.values.BillingState}
                                />
                                {formHandlers.errors.BillingState ? (
                                  <label className="error text-center">
                                    {formHandlers.errors.BillingState}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <div className="form-group">
                                <label htmlFor="BillingZip">ZIP Code*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Zip Code"
                                  name="BillingZip"
                                  onChange={formHandlers.handleChange}
                                  onBlur={formHandlers.handleBlur}
                                  value={formHandlers.values.BillingZip}
                                />
                                {formHandlers.touched.BillingZip &&
                                formHandlers.errors.BillingZip ? (
                                  <label className="error text-center">
                                    {formHandlers.errors.BillingZip}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div id="error-area">
                      {errors.length > 0 && (
                        <div className="mb-4">
                          {errors?.map((err: any) => (
                            <label className="error text-center w100">
                              {err}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                    <HostedForm
                      environment={
                        process.env.REACT_APP_ENVIRONMENT === "production"
                          ? "PRODUCTION"
                          : "SANDBOX"
                      }
                      buttonClassName="btn-payment d-none"
                      authData={authData}
                      onSubmit={(res) => {
                        paymentAuth.AuthDataValue = res.opaqueData.dataValue;
                        paymentAuth.AuthDataDescriptor =
                          res.opaqueData.dataDescriptor;
                        formHandlers.handleSubmit();
                      }}
                      errorTextClassName="authorize-error"
                    />
                    <div
                      className={
                        usingMethod === "credit" &&
                        formHandlers.values.AuthDataValue === "" &&
                        formHandlers.values.AuthDataDescriptor === ""
                          ? "d-block"
                          : "d-none"
                      }
                    >
                      <button
                        type="submit"
                        className="btn btn-primary w-100 my-2"
                      >
                        Next
                      </button>
                    </div>

                    <div
                      className={
                        usingMethod === "credit" &&
                        formHandlers.values.AuthDataValue === "" &&
                        formHandlers.values.AuthDataDescriptor === ""
                          ? "d-none"
                          : "d-block"
                      }
                    >
                      <button
                        disabled={
                          totalMeals === 0 ||
                          (usingMethod === "savedCredit" &&
                            formHandlers.values.payment_profile_id === "")
                        }
                        type="submit"
                        className="btn btn-primary w-100 my-2"
                      >
                        Complete Order
                      </button>
                    </div>
                  </div>

                  <div className="col-xl-3">
                    <div
                      className="ocmp_ad_box blue_box sticky-top"
                      style={{ top: 100, zIndex: 1 }}
                    >
                      <div className="row">
                        <div className="col-md-6 col-xl-12 mb-3">
                          <h3 className="lg-title mb-0">
                            Meals Included with your plan
                          </h3>
                          <div className="meal-title">
                            {selectedPlan?.plan_id === "0"
                              ? weeks * mealsPerWeek
                              : parseInt(selectedPlan.meals_added)}{" "}
                            Meals
                          </div>
                        </div>
                        <div className="col-md-6 col-xl-12 mb-4">
                          <h3 className="lg-title mb-0">Additional Meals:</h3>
                          <div className="meal-title">
                            {formHandlers.values.additionalMeals || 0} Meals
                          </div>
                        </div>
                        <div className="col-md-6 col-xl-12 mb-4">
                          <h3 className="lg-title mb-0">Total Meals</h3>
                          <div className="meal-title">{totalMeals} Meals</div>
                        </div>
                        <div className="col-md-6 col-xl-12 mb-4">
                          <h3 className="lg-title mb-1">Order Total</h3>
                          <div className="meal-title fs-1 fw-bold">
                            {formatNumber(total || 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          )}
        </>
      )}

      {/* transaction */}
    </DashboardLayout>
  );
};

export default MyCardAddFunds;
