import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { CardEntity, StudentEntity } from "../../api/studentApi";
import { useAppDispatch } from "../../app/hooks";
import tickAnimate from "../../assets/img/tick-animation.gif";
import AddressSelection from "../../components/common/AddressSelection";
import GraduationYearSelection from "../../components/common/GraduationYearSelection";
import useOutsideClick from "../../hooks/useOutsideClick";
import { currentStudentSelector } from "../../state/student/studentSelector";
import { getStudent, studentActions } from "../../state/student/studentSlice";
import { fromPhoneNumberToString, toPhoneNumber } from "../../utils/string";

type EditProfileProps = {
  studentData:
    | (StudentEntity & {
        login_id: number;
      })
    | undefined;
  cardData: CardEntity | undefined;
};

type EditProfileFormValues = {
  firstName: string;
  lastName: string;
  homeAddr1?: string;
  homeAddr2?: string;
  homeCity?: string;
  homeState?: string;
  homeZip?: string;
  homePhone?: string;
  schoolAddr1?: string;
  schoolAddr2?: string;
  schoolCity?: string;
  schoolState?: string;
  schoolZip?: string;
  schoolPhone?: string;
  emailAddress?: string;
  graduationYear?: string;
  schoolName?: string;
};

const EditProfileFormValidateSchema = Yup.object({
  firstName: Yup.string().required("Please provide a first name."),
  lastName: Yup.string().required("Please provide a last name."),
  homeAddr1: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  homeAddr2: Yup.string()
    .max(30, "Home address can not be longer than 30 characters")
    .nullable(),
  homeCity: Yup.string().nullable().required("Please provide home city."),
  homeState: Yup.string().nullable().required("Please provide home state."),
  homeZip: Yup.string().nullable().required("Please provide home zip."),
  homePhone: Yup.string().nullable().required("Please provide home phone."),
  schoolAddr1: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  schoolAddr2: Yup.string()
    .max(30, "School address can not be longer than 30 characters")
    .nullable(),
  schoolCity: Yup.string().nullable().required("Please provide school city."),
  schoolState: Yup.string().nullable().required("Please provide school state."),
  schoolZip: Yup.string().nullable().required("Please provide school zip."),
  schoolPhone: Yup.string().nullable().required("Please provide school phone."),
  emailAddress: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  schoolName: Yup.string().required("Please provide a school."),
  graduationYear: Yup.string()
    .test("len", "Must be exactly 4 digits", (val) => val?.length === 4)
    .required("Please provide an expected graduation year.")
    .nullable(),
});

const EditProfileShortFormValidateSchema = Yup.object({
  firstName: Yup.string().required("Please provide a first name."),
  lastName: Yup.string().required("Please provide a last name."),
  emailAddress: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  schoolName: Yup.string().required("Please provide a school."),
  graduationYear: Yup.string()
    .test("len", "Must be exactly 4 digits", (val) => val?.length === 4)
    .required("Please provide an expected graduation year.")
    .nullable(),
});

const initialValues: EditProfileFormValues = {
  firstName: "",
  lastName: "",
  homeAddr1: "",
  homeAddr2: "",
  homeCity: "",
  homeState: "",
  homeZip: "",
  homePhone: "",
  schoolAddr1: "",
  schoolAddr2: "",
  schoolCity: "",
  schoolState: "",
  schoolZip: "",
  schoolPhone: "",
  emailAddress: "",
  graduationYear: "",
  schoolName: "",
};

const EditProfileModal: React.FC<EditProfileProps> = ({
  studentData,
  cardData,
}) => {
  const dispatch = useAppDispatch();
  const [responseStatus, setResponseStatus] = useState({
    showSuccess: false,
    error: "",
    successMessage: "",
  });
  const currentStudentState = useSelector(currentStudentSelector);
  const formRef = useRef(null);

  useOutsideClick(formRef, () => {
    setInitialValues();
    setResponseStatus({
      showSuccess: false,
      error: "",
      successMessage: "",
    });
  });
  useEffect(() => {
    dispatch(getStudent());
  }, []);

  const formHandlers = useFormik<EditProfileFormValues>({
    initialValues,
    validationSchema:
        EditProfileFormValidateSchema,
    onSubmit: ({
      firstName,
      lastName,
      homeAddr1,
      homeAddr2,
      homeCity,
      homeState,
      homeZip,
      homePhone,
      schoolAddr1,
      schoolAddr2,
      schoolCity,
      schoolState,
      schoolZip,
      schoolPhone,
      emailAddress,
      schoolName,
      graduationYear,
    }) => {
      dispatch(
        studentActions.postStudent({
          data: {
            ...currentStudentState.data?.data.studentData,
            first_name: firstName,
            last_name: lastName,
            HomeAddr_A: homeAddr1,
            HomeAddr_B: homeAddr2,
            HomeCity: homeCity,
            HomeState: homeState,
            HomeZip: homeZip,
            HomePhone: fromPhoneNumberToString(homePhone),
            SchoolAddr_A: schoolAddr1,
            SchoolAddr_B: schoolAddr2,
            SchoolCity: schoolCity,
            SchoolState: schoolState,
            SchoolZip: schoolZip,
            SchoolPhone: fromPhoneNumberToString(schoolPhone),
            EmailAddress: emailAddress,
            schoolName: schoolName,
            GraduationYear: graduationYear,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setResponseStatus({
                showSuccess: true,
                error: "",
                successMessage: data.message,
              });
              dispatch(getStudent());
              return;
            }
            setResponseStatus({
              showSuccess: false,
              error:
                data?.message ||
                "Something went wrong on our end. Please try again.",
              successMessage: "",
            });

            return;
          },
        })
      );
    },
  });

  const setInitialValues = () => {
    formHandlers.setFieldValue("firstName", studentData?.first_name);
    formHandlers.setFieldValue("lastName", studentData?.last_name);
    formHandlers.setFieldValue("emailAddress", studentData?.EmailAddress);
    formHandlers.setFieldValue("graduationYear", studentData?.GraduationYear);
    formHandlers.setFieldValue("schoolName", studentData?.schoolName);
    formHandlers.setFieldValue("homeAddr1", studentData?.HomeAddr_A);
    formHandlers.setFieldValue("homeAddr2", studentData?.HomeAddr_B);
    formHandlers.setFieldValue("homeCity", studentData?.HomeCity);
    formHandlers.setFieldValue("homeState", studentData?.HomeState);
    formHandlers.setFieldValue("homeZip", studentData?.HomeZip);
    formHandlers.setFieldValue("homePhone", studentData?.HomePhone);
    formHandlers.setFieldValue("schoolAddr1", studentData?.SchoolAddr_A);
    formHandlers.setFieldValue("schoolAddr2", studentData?.SchoolAddr_B);
    formHandlers.setFieldValue("schoolCity", studentData?.SchoolCity);
    formHandlers.setFieldValue("schoolState", studentData?.SchoolState);
    formHandlers.setFieldValue("schoolZip", studentData?.SchoolZip);
    formHandlers.setFieldValue("schoolPhone", studentData?.SchoolPhone);
  };

  useEffect(() => {
    setInitialValues();
  }, [cardData, studentData]);

  return (
    <div
      className="modal fade"
      id="editProfile"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        ref={formRef}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {responseStatus.showSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {responseStatus.successMessage}
                </p>
              </div>
            ) : (
              <div className="form-content">
                <h2 className="xl-title text-center">Edit Profile Settings</h2>
                <p className="lg-title text-center mb-60">
                  Edit your profile below
                </p>
                <form
                  className="max-width-575"
                  onSubmit={formHandlers.handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="firstname">First Name *</label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-control"
                          placeholder="First Name"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.firstName}
                        />
                        {formHandlers.touched.firstName &&
                        formHandlers.errors.firstName ? (
                          <label className="error">
                            {formHandlers.errors.firstName}
                          </label>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="lastname">Last Name *</label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.lastName}
                        />
                        {formHandlers.touched.lastName &&
                        formHandlers.errors.lastName ? (
                          <label className="error">
                            {formHandlers.errors.lastName}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">Email *</label>
                        <input
                          type="email"
                          name="emailAddress"
                          id="emailAddress"
                          className="form-control"
                          placeholder="Email Address"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.emailAddress}
                        />
                        {formHandlers.touched.emailAddress &&
                        formHandlers.errors.emailAddress ? (
                          <label className="error">
                            {formHandlers.errors.emailAddress}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="schoolName">School *</label>
                        <input
                          type="text"
                          name="schoolName"
                          id="schoolName"
                          className="form-control"
                          placeholder="School Name"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.schoolName}
                          disabled
                        />
                        {formHandlers.touched.schoolName &&
                        formHandlers.errors.schoolName ? (
                          <label className="error">
                            {formHandlers.errors.schoolName}
                          </label>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="year">Expected Graduation Year*</label>
                        <GraduationYearSelection
                          name="graduationYear"
                          id="year"
                          className="form-control p-0"
                          onChange={(value: string) =>
                            formHandlers.setFieldValue("graduationYear", value)
                          }
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.graduationYear}
                        />

                        {formHandlers.touched.graduationYear &&
                        formHandlers.errors.graduationYear ? (
                          <label className="error">
                            {formHandlers.errors.graduationYear}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <>
                    <div className="mt-4">
                      <h2 className="xl-title mb-30">Student Home Address</h2>
                      <div className="form-group">
                        <label htmlFor="homeAddr1">Address 1 *</label>
                        <input
                          type="text"
                          name="homeAddr1"
                          id="homeAddr1"
                          className="form-control"
                          placeholder="Street address"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.homeAddr1}
                        />
                        {formHandlers.touched.homeAddr1 &&
                        formHandlers.errors.homeAddr1 ? (
                          <label className="error">
                            {formHandlers.errors.homeAddr1}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="homeAddr2">Address 2</label>
                        <input
                          type="text"
                          name="homeAddr2"
                          id="homeAddr2"
                          className="form-control"
                          placeholder="Apartment #, Suite etc."
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.homeAddr2}
                        />
                        {formHandlers.touched.homeAddr2 &&
                        formHandlers.errors.homeAddr2 ? (
                          <label className="error">
                            {formHandlers.errors.homeAddr2}
                          </label>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="homeCity">City *</label>
                            <input
                              type="text"
                              name="homeCity"
                              id="homeCity"
                              className="form-control"
                              placeholder="City"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.homeCity}
                            />
                            {formHandlers.touched.homeCity &&
                            formHandlers.errors.homeCity ? (
                              <label className="error">
                                {formHandlers.errors.homeCity}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="homeState">State *</label>
                            <AddressSelection
                              name="homeState"
                              id="homeState"
                              className="form-control p-0"
                              onChange={(value: string) =>
                                formHandlers.setFieldValue("homeState", value)
                              }
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.homeState}
                            />
                            {formHandlers.errors.homeState ? (
                              <label className="error">
                                {formHandlers.errors.homeState}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="zipCode">Zip Code *</label>
                            <input
                              type="text"
                              name="homeZip"
                              id="homeZip"
                              className="form-control"
                              placeholder="Zip Code"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.homeZip}
                            />
                            {formHandlers.touched.homeZip &&
                            formHandlers.errors.homeZip ? (
                              <label className="error">
                                {formHandlers.errors.homeZip}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="phone">Home Phone Number *</label>
                            <input
                              type="text"
                              name="homePhone"
                              id="phone"
                              className="form-control"
                              placeholder="Home Phone Number"
                              onBlur={formHandlers.handleBlur}
                              value={toPhoneNumber(
                                formHandlers.values.homePhone
                              )}
                              onChange={(e) => {
                                const result = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                formHandlers.setFieldValue(
                                  "homePhone",
                                  result
                                );
                              }}
                            />
                            {formHandlers.touched.homePhone &&
                            formHandlers.errors.homePhone ? (
                              <label className="error">
                                {formHandlers.errors.homePhone}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h2 className="xl-title mb-30">
                        Student School Address
                      </h2>
                      <div className="form-group">
                        <label htmlFor="schoolAddr1">Address 1 *</label>
                        <input
                          type="text"
                          name="schoolAddr1"
                          id="schoolAddr1"
                          className="form-control"
                          placeholder="Street address"
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.schoolAddr1}
                        />
                        {formHandlers.touched.schoolAddr1 &&
                        formHandlers.errors.schoolAddr1 ? (
                          <label className="error">
                            {formHandlers.errors.schoolAddr1}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="schoolAddr2">Address 2</label>
                        <input
                          type="text"
                          name="schoolAddr2"
                          id="schoolAddr2"
                          className="form-control"
                          placeholder="Apartment #, Suite etc."
                          onChange={formHandlers.handleChange}
                          onBlur={formHandlers.handleBlur}
                          value={formHandlers.values.schoolAddr2}
                        />
                        {formHandlers.touched.schoolAddr2 &&
                        formHandlers.errors.schoolAddr2 ? (
                          <label className="error">
                            {formHandlers.errors.schoolAddr2}
                          </label>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="schoolCity">City *</label>
                            <input
                              type="text"
                              name="schoolCity"
                              id="schoolCity"
                              className="form-control"
                              placeholder="City"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.schoolCity}
                            />
                            {formHandlers.touched.schoolCity &&
                            formHandlers.errors.schoolCity ? (
                              <label className="error">
                                {formHandlers.errors.schoolCity}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="schoolState">State *</label>
                            <AddressSelection
                              name="schoolState"
                              id="schoolState"
                              className="form-control p-0"
                              onChange={(value: string) =>
                                formHandlers.setFieldValue(
                                  "schoolState",
                                  value
                                )
                              }
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.schoolState}
                            />
                            {formHandlers.errors.schoolState ? (
                              <label className="error">
                                {formHandlers.errors.schoolState}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="zipCod1e">Zip Code *</label>
                            <input
                              type="text"
                              name="schoolZip"
                              id="homeZip"
                              className="form-control"
                              placeholder="Zip Code"
                              onChange={formHandlers.handleChange}
                              onBlur={formHandlers.handleBlur}
                              value={formHandlers.values.schoolZip}
                            />
                            {formHandlers.touched.schoolZip &&
                            formHandlers.errors.schoolZip ? (
                              <label className="error">
                                {formHandlers.errors.schoolZip}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="phone">
                              School Phone Number *
                            </label>
                            <input
                              type="text"
                              name="schoolPhone"
                              id="school_no"
                              className="form-control"
                              placeholder="School Phone Number"
                              onChange={(e) => {
                                const result = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                formHandlers.setFieldValue(
                                  "schoolPhone",
                                  result
                                );
                              }}
                              onBlur={formHandlers.handleBlur}
                              value={toPhoneNumber(
                                formHandlers.values.schoolPhone
                              )}
                            />
                            {formHandlers.touched.schoolPhone &&
                            formHandlers.errors.schoolPhone ? (
                              <label className="error">
                                {formHandlers.errors.schoolPhone}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  {responseStatus.error && (
                    <label className="error mb-1">{responseStatus.error}</label>
                  )}
                  <button type="submit" className="btn btn-primary w100">
                    Save Changes
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
