import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { StudentEntity } from "../../api/studentApi";
import { useAppDispatch } from "../../app/hooks";
import { getStudent, studentActions } from "../../state/student/studentSlice";
import tickAnimate from "../../assets/img/tick-animation.gif";
import useOutsideClick from "../../hooks/useOutsideClick";
import AddressSelection from "../../components/common/AddressSelection";

type ReportCardProps = {
  reportData: StudentEntity | undefined;
};
type ReportFormValues = {
  PIN: string;
  reason: string;
  Mailto: string;
};
type AddressValues = {
  Addr_A: string;
  Addr_B: string;
  City: string;
  State: string;
  Zip: string;
};
const ReportFormValidateSchema = Yup.object({
  PIN: Yup.string().required("Please provide PIN."),
  Mailto: Yup.string().required("Please provide Shipping Address."),
});
const AddressValidateSchema = Yup.object({
  Addr_A: Yup.string()
    .max(30, "Address can not be longer than 30 characters")
    .nullable()
    .required("Please provide home address."),
  Addr_B: Yup.string()
    .max(30, "Address can not be longer than 30 characters")
    .nullable(),
  City: Yup.string().required("Please provide City."),
  State: Yup.string().required("Please provide State."),
  Zip: Yup.string().required("Please provide Zip."),
});
const initialValues: ReportFormValues = {
  reason: "1",
  PIN: "",
  Mailto: "",
};
const initialValuesAddress: AddressValues = {
  Addr_A: "",
  Addr_B: "",
  City: "",
  State: "",
  Zip: "",
};
const ReportCard: React.FC<ReportCardProps> = ({ reportData }) => {
  const dispatch = useAppDispatch();
  const [showUpdateAddress, setShowUpdateAddress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const formRef = useRef(null);

  useOutsideClick(formRef, () => {
    setShowSuccess(false);
    setError("");
    setSuccessMessage("");
    reportFormHandlers.resetForm();
  });

  const reportFormHandlers = useFormik<ReportFormValues>({
    initialValues,
    validationSchema: ReportFormValidateSchema,
    onSubmit: () => {
      if (
        updateAddressHandlers.values.Addr_A &&
        updateAddressHandlers.values.City &&
        updateAddressHandlers.values.State &&
        updateAddressHandlers.values.Zip &&
        !showUpdateAddress
      ) {
        setShowSuccess(false);
        setError("");
        setSuccessMessage("");
        dispatch(
          studentActions.reissueCard({
            data: {
              ...reportFormHandlers.values,
              ...updateAddressHandlers.values,
            },
            onComplete: (error, data) => {
              if (data?.status) {
                setError("");
                setSuccessMessage(data.message);
                setShowSuccess(true);
                dispatch(getStudent());
                return;
              }
              setError(
                data?.message ||
                  "Something went wrong on our end. Please try again."
              );
              setShowSuccess(false);
              return;
            },
          })
        );
      } else {
        reportFormHandlers.setFieldError(
          "Mailto",
          "Please provide detail address"
        );
      }
    },
  });
  const updateAddressHandlers = useFormik<AddressValues>({
    initialValues: initialValuesAddress,
    validationSchema: AddressValidateSchema,
    onSubmit: () => {
      dispatch(
        studentActions.postStudent({
          data: {
            ...reportData,
            HomeAddr_A:
              reportFormHandlers.values.Mailto === "H"
                ? updateAddressHandlers.values.Addr_A
                : reportData?.HomeAddr_A,
            HomeAddr_B:
              reportFormHandlers.values.Mailto === "H"
                ? updateAddressHandlers.values.Addr_B
                : reportData?.HomeAddr_B,
            HomeCity:
              reportFormHandlers.values.Mailto === "H"
                ? updateAddressHandlers.values.City
                : reportData?.HomeCity,
            HomeState:
              reportFormHandlers.values.Mailto === "H"
                ? updateAddressHandlers.values.State
                : reportData?.HomeState,
            HomeZip:
              reportFormHandlers.values.Mailto === "H"
                ? updateAddressHandlers.values.Zip
                : reportData?.HomeZip,
            SchoolAddr_A:
              reportFormHandlers.values.Mailto === "S"
                ? updateAddressHandlers.values.Addr_A
                : reportData?.SchoolAddr_A,
            SchoolAddr_B:
              reportFormHandlers.values.Mailto === "S"
                ? updateAddressHandlers.values.Addr_B
                : reportData?.SchoolAddr_B,
            SchoolCity:
              reportFormHandlers.values.Mailto === "S"
                ? updateAddressHandlers.values.City
                : reportData?.SchoolCity,
            SchoolState:
              reportFormHandlers.values.Mailto === "S"
                ? updateAddressHandlers.values.State
                : reportData?.SchoolState,
            SchoolZip:
              reportFormHandlers.values.Mailto === "S"
                ? updateAddressHandlers.values.Zip
                : reportData?.SchoolZip,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setError("");
              reportFormHandlers.setFieldError("Mailto", "");
              setShowUpdateAddress(false);
              return;
            }
            setError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );
            return;
          },
        })
      );
    },
  });
  useEffect(() => {
    setInitValueAddr();
  }, [reportFormHandlers.values.Mailto]);
  const setInitValueAddr = () => {
    if (reportFormHandlers.values.Mailto && reportData) {
      if (reportFormHandlers.values.Mailto === "H") {
      }
      updateAddressHandlers.setFieldValue(
        "Addr_A",
        reportFormHandlers.values.Mailto === "H"
          ? reportData.HomeAddr_A
          : reportData.SchoolAddr_A
      );
      updateAddressHandlers.setFieldValue(
        "Addr_B",
        reportFormHandlers.values.Mailto === "H"
          ? reportData.HomeAddr_B
          : reportData.SchoolAddr_B
      );
      updateAddressHandlers.setFieldValue(
        "City",
        reportFormHandlers.values.Mailto === "H"
          ? reportData.HomeCity
          : reportData.SchoolCity
      );
      updateAddressHandlers.setFieldValue(
        "Zip",
        reportFormHandlers.values.Mailto === "H"
          ? reportData.HomeZip
          : reportData.SchoolZip
      );
      updateAddressHandlers.setFieldValue(
        "State",
        reportFormHandlers.values.Mailto === "H"
          ? reportData.HomeState
          : reportData.SchoolState
      );
    }
  };
  const handleCancelUpdate = () => {
    setShowUpdateAddress(false);
    setInitValueAddr();
  };

  const handleClose = () => {
    if (showSuccess) {
      window.location.reload();
      setShowSuccess(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="stolenCard"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        ref={formRef}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body pt-0">
            {!showSuccess ? (
              <div className="form-content">
                <h2 className="xl-title text-center">
                  Report a Missing or Stolen Card
                </h2>
                <h6 className="text-center dark-text mb-40">
                  Complete the form below to report a missing or stolen card
                </h6>
                {error && (
                  <label className="error mb-4 text-center w-100">
                    {error}
                  </label>
                )}
                <form
                  action="#"
                  className="max-width-575"
                  id="reportCard"
                  onSubmit={reportFormHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label>My Card Was:</label>
                    <div className="d-flex radioButtonGroup">
                      <div className="radioButton">
                        <input
                          type="radio"
                          name="reason"
                          id="lost"
                          value="1"
                          checked={reportFormHandlers.values.reason === "1"}
                          onChange={() =>
                            reportFormHandlers.setFieldValue("reason", "1")
                          }
                        />
                        <label htmlFor="lost">Lost</label>
                      </div>
                      <div className="radioButton">
                        <input
                          type="radio"
                          name="reason"
                          id="stolen"
                          value="2"
                          checked={reportFormHandlers.values.reason === "2"}
                          onChange={() =>
                            reportFormHandlers.setFieldValue("reason", "2")
                          }
                        />
                        <label htmlFor="stolen">Stolen</label>
                      </div>
                      <div className="radioButton">
                        <input
                          type="radio"
                          name="reason"
                          id="neverReceived"
                          value="3"
                          checked={reportFormHandlers.values.reason === "3"}
                          onChange={() =>
                            reportFormHandlers.setFieldValue("reason", "3")
                          }
                        />
                        <label htmlFor="neverReceived">Never Received</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="pin">PIN *</label>
                    <input
                      type="text"
                      id="pin"
                      className="form-control"
                      placeholder="PIN"
                      name="PIN"
                      onChange={reportFormHandlers.handleChange}
                      onBlur={reportFormHandlers.handleBlur}
                      value={reportFormHandlers.values.PIN}
                    />
                    {reportFormHandlers.touched.PIN &&
                    reportFormHandlers.errors.PIN ? (
                      <label className="error">
                        {reportFormHandlers.errors.PIN}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="shippingAddress">Shipping Address *</label>
                    <select
                      name="Mailto"
                      id="shippingAddress"
                      className="form-control"
                      value={reportFormHandlers.values.Mailto}
                      onChange={reportFormHandlers.handleChange}
                      onBlur={reportFormHandlers.handleBlur}
                    >
                      <option value="">Select An Address</option>
                      <option value="H">Home</option>
                      <option value="S">School</option>
                    </select>
                    {reportFormHandlers.touched.Mailto &&
                    reportFormHandlers.errors.Mailto ? (
                      <label className="error">
                        {reportFormHandlers.errors.Mailto}
                      </label>
                    ) : null}
                  </div>
                  {reportFormHandlers.values.Mailto && !showUpdateAddress && (
                    <div className="selectedShippingAddress">
                      <button
                        type="button"
                        className="btn btn-outline btn-update-address"
                        onClick={() => setShowUpdateAddress(true)}
                      >
                        Update Address
                      </button>
                    </div>
                  )}
                  {showUpdateAddress && (
                    <div className="address_fields">
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address1 *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Street address"
                              name="Addr_A"
                              value={updateAddressHandlers.values.Addr_A}
                              onChange={updateAddressHandlers.handleChange}
                              onBlur={updateAddressHandlers.handleBlur}
                            />
                            {updateAddressHandlers.touched.Addr_A &&
                            updateAddressHandlers.errors.Addr_A ? (
                              <label className="error">
                                {updateAddressHandlers.errors.Addr_A}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address2 *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Apartment #,  Suite etc."
                              name="Addr_B"
                              value={updateAddressHandlers.values.Addr_B}
                              onChange={updateAddressHandlers.handleChange}
                              onBlur={updateAddressHandlers.handleBlur}
                            />
                            {updateAddressHandlers.touched.Addr_B &&
                            updateAddressHandlers.errors.Addr_B ? (
                              <label className="error">
                                {updateAddressHandlers.errors.Addr_B}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>City *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="City"
                              value={updateAddressHandlers.values.City}
                              onChange={updateAddressHandlers.handleChange}
                              onBlur={updateAddressHandlers.handleBlur}
                            />
                            {updateAddressHandlers.touched.City &&
                            updateAddressHandlers.errors.City ? (
                              <label className="error">
                                {updateAddressHandlers.errors.City}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>State *</label>
                            <AddressSelection
                              className="form-control p-0"
                              name="State"
                              value={updateAddressHandlers.values.State}
                              onChange={(value: string) =>
                                updateAddressHandlers.setFieldValue(
                                  "State",
                                  value
                                )
                              }
                              onBlur={updateAddressHandlers.handleBlur}
                            />
                            {updateAddressHandlers.errors.State ? (
                              <label className="error">
                                {updateAddressHandlers.errors.State}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Zip Code *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              name="Zip"
                              value={updateAddressHandlers.values.Zip}
                              onChange={updateAddressHandlers.handleChange}
                              onBlur={updateAddressHandlers.handleBlur}
                            />
                            {updateAddressHandlers.touched.Zip &&
                            updateAddressHandlers.errors.Zip ? (
                              <label className="error">
                                {updateAddressHandlers.errors.Zip}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-outline btn-form-actions"
                          onClick={() => {
                            updateAddressHandlers.handleSubmit();
                          }}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline btn-form-actions"
                          onClick={handleCancelUpdate}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="checkbox mt-4">
                    <input type="checkbox" id="policy" name="policy" />
                    <label htmlFor="policy">
                      I know that this process is irreversible and want to
                      continue.
                    </label>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary w100 ${
                      showUpdateAddress && "disable"
                    }`}
                  >
                    Report My Card
                  </button>
                </form>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">{successMessage}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportCard;
