import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import { currentStudentSelector } from "../../state/student/studentSelector";
import { getStudent, studentActions } from "../../state/student/studentSlice";
import { fromPhoneNumberToString, toPhoneNumber } from "../../utils/string";

type RegisterStudentFormValues = {
  homeAddr1: string;
  homeAddr2?: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
  homePhone: string;
  schoolAddr1: string;
  schoolAddr2?: string;
  schoolCity: string;
  schoolState: string;
  schoolZip: string;
  schoolPhone: string;
};

const RegisterStudentFormValidateSchema = Yup.object({
  homeAddr1: Yup.string().required("Please provide home address."),
  homeCity: Yup.string().required("Please provide home city."),
  homeState: Yup.string().required("Please provide home state."),
  homeZip: Yup.string().required("Please provide home zip."),
  homePhone: Yup.string().required("Please provide home phone."),
  schoolAddr1: Yup.string().required("Please provide school address."),
  schoolCity: Yup.string().required("Please provide school city."),
  schoolState: Yup.string().required("Please provide school state."),
  schoolZip: Yup.string().required("Please provide school zip."),
  schoolPhone: Yup.string().required("Please provide school phone."),
});

const initialValues: RegisterStudentFormValues = {
  homeAddr1: "",
  homeAddr2: "",
  homeCity: "",
  homeState: "",
  homeZip: "",
  homePhone: "",
  schoolAddr1: "",
  schoolAddr2: "",
  schoolCity: "",
  schoolState: "",
  schoolZip: "",
  schoolPhone: "",
};

const RegisterStudentForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [attemptPostError, setAttemptPostError] = useState("");
  const currentStudentState = useSelector(currentStudentSelector);

  useEffect(() => {
    dispatch(getStudent());
  }, []);

  const formHandlers = useFormik<RegisterStudentFormValues>({
    initialValues,
    validationSchema: RegisterStudentFormValidateSchema,
    onSubmit: ({
      homeAddr1,
      homeAddr2,
      homeCity,
      homeState,
      homeZip,
      homePhone,
      schoolAddr1,
      schoolAddr2,
      schoolCity,
      schoolState,
      schoolZip,
      schoolPhone,
    }) => {
      dispatch(
        studentActions.postStudent({
          data: {
            ...currentStudentState.data?.data?.studentData,
            HomeAddr_A: homeAddr1,
            HomeAddr_B: homeAddr2,
            HomeCity: homeCity,
            HomeState: homeState,
            HomeZip: homeZip,
            HomePhone: fromPhoneNumberToString(homePhone),
            SchoolAddr_A: schoolAddr1,
            SchoolAddr_B: schoolAddr2,
            SchoolCity: schoolCity,
            SchoolState: schoolState,
            SchoolZip: schoolZip,
            SchoolPhone: fromPhoneNumberToString(schoolPhone),
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setAttemptPostError("");
              navigate(APP_ROUTES.Dashboard.path);
              return;
            }
            setAttemptPostError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );

            return;
          },
        })
      );
    },
  });

  return (
    <>
      <form onSubmit={formHandlers.handleSubmit}>
        <div className="card">
          <h2 className="lg-title mb-30">Permanent Address</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="address1">Address 1 *</label>
                <input
                  type="text"
                  name="homeAddr1"
                  id="address1"
                  className="form-control"
                  placeholder="Address 1"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homeAddr1}
                />
                {formHandlers.touched.homeAddr1 &&
                formHandlers.errors.homeAddr1 ? (
                  <label className="error">
                    {formHandlers.errors.homeAddr1}
                  </label>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="address2">Address 2</label>
                <input
                  type="text"
                  name="homeAddr2"
                  id="address2"
                  className="form-control"
                  placeholder="Address 2"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homeAddr2}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="city">City *</label>
                <input
                  type="text"
                  name="homeCity"
                  id="city"
                  className="form-control"
                  placeholder="City"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homeCity}
                />
                {formHandlers.touched.homeCity &&
                formHandlers.errors.homeCity ? (
                  <label className="error">
                    {formHandlers.errors.homeCity}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="zip">Zip Code *</label>
                <input
                  type="text"
                  name="homeZip"
                  id="zip"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homeZip}
                />
                {formHandlers.touched.homeZip && formHandlers.errors.homeZip ? (
                  <label className="error">{formHandlers.errors.homeZip}</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="state">State *</label>
                <select
                  name="homeState"
                  id="state"
                  className="form-control"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homeState}
                >
                  <option value="state">Select a state</option>
                  <option value="TX">TX</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                </select>
                {formHandlers.touched.homeState &&
                formHandlers.errors.homeState ? (
                  <label className="error">
                    {formHandlers.errors.homeState}
                  </label>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-0">
                <label htmlFor="school_no">Home Phone Number *</label>
                <input
                  type="text"
                  name="homePhone"
                  id="school_no"
                  className="form-control"
                  placeholder="Home Phone Number"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.homePhone}
                />
                {formHandlers.touched.homePhone &&
                formHandlers.errors.homePhone ? (
                  <label className="error">
                    {formHandlers.errors.homePhone}
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <h2 className="lg-title mb-30">School Address</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="address1">Address 1 *</label>
                <input
                  type="text"
                  name="schoolAddr1"
                  id="address1"
                  className="form-control"
                  placeholder="Address 1"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.schoolAddr1}
                />
                {formHandlers.touched.schoolAddr1 &&
                formHandlers.errors.schoolAddr1 ? (
                  <label className="error">
                    {formHandlers.errors.schoolAddr1}
                  </label>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="address2">Address 2</label>
                <input
                  type="text"
                  name="schoolAddr2"
                  id="address2"
                  className="form-control"
                  placeholder="Address 2"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.schoolAddr2}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="city">City *</label>
                <input
                  type="text"
                  name="schoolCity"
                  id="city"
                  className="form-control"
                  placeholder="City"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.schoolCity}
                />
                {formHandlers.touched.schoolCity &&
                formHandlers.errors.schoolCity ? (
                  <label className="error">
                    {formHandlers.errors.schoolCity}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="zip">Zip Code *</label>
                <input
                  type="text"
                  name="schoolZip"
                  id="zip"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.schoolZip}
                />
                {formHandlers.touched.schoolZip &&
                formHandlers.errors.schoolZip ? (
                  <label className="error">
                    {formHandlers.errors.schoolZip}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="state">State *</label>
                <select
                  name="schoolState"
                  id="state"
                  className="form-control"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={formHandlers.values.schoolState}
                >
                  <option value="state">Select a state</option>
                  <option value="TX">TX</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                </select>
                {formHandlers.touched.schoolState &&
                formHandlers.errors.schoolState ? (
                  <label className="error">
                    {formHandlers.errors.schoolState}
                  </label>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-0">
                <label htmlFor="school_no">School Phone Number*</label>
                <input
                  type="text"
                  name="schoolPhone"
                  id="school_no"
                  className="form-control"
                  placeholder="School Phone Number"
                  onChange={formHandlers.handleChange}
                  onBlur={formHandlers.handleBlur}
                  value={toPhoneNumber(formHandlers.values.schoolPhone)}
                />
                {formHandlers.touched.schoolPhone &&
                formHandlers.errors.schoolPhone ? (
                  <label className="error">
                    {formHandlers.errors.schoolPhone}
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {attemptPostError && (
          <label className="error mb-1">{attemptPostError}</label>
        )}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </>
  );
};

export default RegisterStudentForm;
