import React from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";
import OCMPLogo from "../common/OCMPLogo";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Collapse,
} from "reactstrap";

export const DashboardLayoutHeader: React.FC = () => {
  const active = window.location.pathname;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className="sticky-top">
      <nav className="navbar navbar-expand-md">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <OCMPLogo />
          </Link>

          <button
            className={`navbar-toggler ${isOpen ? "" : " collapsed"}`}
            type="button"
            id="toggler"
            onClick={toggle}
          >
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 8H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 24H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <Collapse isOpen={isOpen} className="navbar-collapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.Dashboard.path}
                  className={`nav-link ${active === "/" && "active"}`}
                  aria-current="page"
                >
                  My Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.MyCard.path}
                  className={`nav-link ${
                    active.includes("/my-card") && "active"
                  }`}
                >
                  My Card
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.Restaurants.path}
                  className={`nav-link ${
                    active.includes("/restaurants") && "active"
                  }`}
                >
                  Restaurants
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.GetHelp.path}
                  className={`nav-link ${
                    active.includes("/get-help") && "active"
                  }`}
                >
                  Help
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <UncontrolledDropdown className="nav-item dropdown">
                <DropdownToggle
                  className="none-focus"
                  color="primary"
                  style={{ backgroundColor: "transparent", border: "none" }}
                  caret
                >
                  <img
                    src="img/menu-toggle.svg"
                    alt=""
                    className="img-fluid d-md-inline-block d-none"
                  />
                  My Account
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-direction">
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Settings.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/settings") && "active")
                      }
                    >
                      Settings
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Transactions.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/transactions") && "active")
                      }
                    >
                      Transaction History
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Messages.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/messages") && "active")
                      }
                    >
                      Message
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link to={APP_ROUTES.Logout.path} className="dropdown-item">
                      Sign Out
                    </Link>
                  </li>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </Collapse>

          {/* <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 8H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 24H27"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.Dashboard.path}
                  className={`nav-link ${active === "/" && "active"}`}
                  aria-current="page"
                >
                  My Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.MyCard.path}
                  className={`nav-link ${
                    active.includes("/my-card") && "active"
                  }`}
                >
                  My Card
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={APP_ROUTES.Restaurants.path}
                  className={`nav-link ${
                    active.includes("/restaurants") && "active"
                  }`}
                >
                  Restaurants
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <UncontrolledDropdown className="nav-item dropdown">
                <DropdownToggle
                  className="none-focus"
                  color="primary"
                  style={{ backgroundColor: "transparent", border: "none" }}
                  caret
                >
                  <img
                    src="img/menu-toggle.svg"
                    alt=""
                    className="img-fluid d-md-inline-block d-none"
                  />
                  My Account
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-direction">
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Settings.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/settings") && "active")
                      }
                    >
                      Settings
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Transactions.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/transactions") && "active")
                      }
                    >
                      Transaction History
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link
                      to={APP_ROUTES.Messages.path}
                      className={
                        "dropdown-item " +
                        (active.includes("/messages") && "active")
                      }
                    >
                      Message
                    </Link>
                  </li>
                  <li className="nav-item" style={{ marginRight: "0" }}>
                    <Link to={APP_ROUTES.Logout.path} className="dropdown-item">
                      Sign Out
                    </Link>
                  </li>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </div> */}
        </div>
      </nav>
    </header>
  );
};
