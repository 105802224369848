import React, { useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { RestaurantEntity } from "../../api/restaurantApi";
import { size } from "lodash";

type MapProps = {
  restaurantData: Array<RestaurantEntity>;
  popupMID: number | undefined;
  activeTab: string;
  setPopupMID: (mid: number | undefined) => void;
};

export const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 42.360102340696,
  lng: -71.2021888320756,
};

const Map: React.FC<MapProps> = ({
  restaurantData,
  activeTab,
  popupMID,
  setPopupMID,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || "",
  });

  // eslint-disable-next-line
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
    setTimeout(() => {
      setDefaultView();
    }, 500);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const setDefaultView = () => {
    if (!popupMID && map && restaurantData.length > 0) {
      const bounds = getBounds(
        restaurantData.map(
          (res) =>
            new google.maps.LatLng({
              lat: Number(res.Latitude),
              lng: Number(res.Longitude),
            })
        )
      );
      map.fitBounds(bounds);
    }
  };

  const getBounds = (
    points: google.maps.LatLng[]
  ): google.maps.LatLngBounds => {
    const latArray = points.map((p) => p.lat());
    const lonArray = points.map((p) => p.lng());
    const sw = {
      lat: Math.min(...latArray),
      lng: Math.min(...lonArray),
    };
    const ne = {
      lat: Math.max(...latArray),
      lng: Math.max(...lonArray),
    };

    return new google.maps.LatLngBounds(sw, ne);
  };

  useEffect(() => {
    if (!popupMID && map && restaurantData.length > 0) {
      setTimeout(() => {
        setDefaultView();
      }, 500);
    }
    if (popupMID && map && restaurantData.length > 0) {
      const selectedRes = restaurantData.find(
        (restaurant) => restaurant.M_ID === popupMID
      );
      if (selectedRes) {
        const bounds = new google.maps.LatLngBounds({
          lat: Number(selectedRes.Latitude),
          lng: Number(selectedRes.Longitude),
        });
        map.fitBounds(bounds, { bottom: 350 });
        map.setZoom(18);
        map.panBy(0, 150);
      }
    }
  }, [popupMID, restaurantData, map]);

  return isLoaded && activeTab === "map" ? (
    <GoogleMap
      zoom={11}
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      clickableIcons={false}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain"],
        },
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      {restaurantData.map((restaurant, key) => {
        return (
          <Marker
            animation={3}
            key={key}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: restaurant.M_ID === popupMID ? "#00529A" : "#EE3D42",
              fillOpacity: 1,
              strokeColor: "#ffffff",
              strokeWeight: 4,
              scale: 10,
            }}
            zIndex={restaurant.M_ID === popupMID ? 1000 : 999}
            position={{
              lat: Number(restaurant.Latitude),
              lng: Number(restaurant.Longitude),
            }}
            onClick={() => {
              setPopupMID(restaurant.M_ID);
            }}
          />
        );
      })}
    </GoogleMap>
  ) : null;
};

export default React.memo(Map);
