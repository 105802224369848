import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import tickAnimate from "../../assets/img/tick-animation.gif";
import ReasonSelection from "../../components/common/ReasonSelection";
import useOutsideClick from "../../hooks/useOutsideClick";
import { siteActions } from "../../state/site/siteSlice";
import { currentStudentSelector } from "../../state/student/studentSelector";

type ContactUsFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  message?: string;
  reason: string;
};

const ContactUsFormValidateSchema = Yup.object({
  firstName: Yup.string().required("Please provide a first name."),
  lastName: Yup.string().required("Please provide a last name."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  reason: Yup.string().required("Please provide a reason."),
});

const initialValues: ContactUsFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  reason: "",
};

const ContactUsForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentStudentState = useSelector(currentStudentSelector);
  const [contactSuccess, setContactSuccess] = useState(false);
  const [attemptContactError, setAttemptContactError] = useState("");
  const [attemptContactSuccess, setAttemptContactSuccess] = useState("");

  const formRef = useRef(null);

  useOutsideClick(formRef, () => {
    setContactSuccess(false);
    setAttemptContactError("");
    setAttemptContactSuccess("");
    formHandlers.resetForm();
  });

  useEffect(() => {
    const { first_name, last_name, EmailAddress } =
      currentStudentState?.data?.data?.studentData || {};

    formHandlers.setValues({
      email: EmailAddress || "",
      firstName: first_name || "",
      lastName: last_name || "",
    } as ContactUsFormValues);
  }, [currentStudentState]);

  const formHandlers = useFormik<ContactUsFormValues>({
    initialValues,
    validationSchema: ContactUsFormValidateSchema,
    onSubmit: ({ email, firstName, lastName, message, reason }) => {
      dispatch(
        siteActions.contactUs({
          data: {
            email_address: email,
            first_name: firstName,
            last_name: lastName,
            message: message,
            subject: reason,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setContactSuccess(true);
              setAttemptContactError("");
              setAttemptContactSuccess(data?.message);
              return;
            }
            setAttemptContactError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );
            return;
          },
        })
      );
    },
  });
  return (
    <div
      className="modal fade"
      id="contactUsModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={formRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body px-5">
            {contactSuccess ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted">
                  {attemptContactSuccess}
                </p>
              </div>
            ) : (
              <form
                className="mb-0"
                noValidate
                onSubmit={formHandlers.handleSubmit}
              >
                <h2 className="xl-title text-center mb-40">Contact Us</h2>
                {attemptContactError && (
                  <label className="error my-3 text-center w-100">
                    {attemptContactError}
                  </label>
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name*</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        onChange={formHandlers.handleChange}
                        onBlur={formHandlers.handleBlur}
                        value={formHandlers.values.firstName}
                      />
                      {formHandlers.touched.firstName &&
                      formHandlers.errors.firstName ? (
                        <label className="error">
                          {formHandlers.errors.firstName}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name*</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={formHandlers.handleChange}
                        onBlur={formHandlers.handleBlur}
                        value={formHandlers.values.lastName}
                      />
                      {formHandlers.touched.lastName &&
                      formHandlers.errors.lastName ? (
                        <label className="error">
                          {formHandlers.errors.lastName}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email*</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={formHandlers.handleChange}
                        onBlur={formHandlers.handleBlur}
                        value={formHandlers.values.email}
                      />
                      {formHandlers.touched.email &&
                      formHandlers.errors.email ? (
                        <label className="error">
                          {formHandlers.errors.email}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Reason*</label>
                      <ReasonSelection
                        name="reason"
                        id="reason"
                        className="form-control p-0"
                        value={formHandlers.values.reason}
                        onBlur={formHandlers.handleBlur}
                        onChange={(value: string) => {
                          formHandlers.setFieldValue("reason", value);
                        }}
                      />
                      {formHandlers.touched.reason &&
                      formHandlers.errors.reason ? (
                        <label className="error">
                          {formHandlers.errors.reason}
                        </label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows={6}
                        placeholder="Message"
                        defaultValue={""}
                        onChange={formHandlers.handleChange}
                        onBlur={formHandlers.handleBlur}
                        value={formHandlers.values.message}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="d-block max-width-350 btn btn-primary w100"
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
