import React from "react";
import { Routes, Route } from "react-router-dom";
import { NotFoundPage } from "../pages/404NotFound";
import { APP_ROUTES } from "./routes";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {Object.entries(APP_ROUTES).map(([key, config]) => {
        const { path, Component } = config;
        return <Route key={key} path={path} element={<Component />} />;
      })}
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
