import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { useAppDispatch } from "../../app/hooks";
import DashboardLayout from "../../components/layout/DashboardLayout";
import RestaurantList from "../../modules/dashboard/RestaurantList";
import TransactionList from "../../modules/dashboard/TransactionList";
import SuggestRestaurantBySchoolModal from "../../modules/restaurants/SuggestRestaurantBySchoolModal";
import { programDetailSelector } from "../../state/program/programSelector";
import { getDetailProgram } from "../../state/program/programSlice";
import { listRestaurantSelector } from "../../state/restaurant/restaurantSelector";
import { listRestaurant } from "../../state/restaurant/restaurantSlice";
import { currentStudentSelector } from "../../state/student/studentSelector";
import DashboardNewAccount from "./DashboardNewAccount";

const Dashboard = () => {
  const currentStudentState = useSelector(currentStudentSelector);
  const listRestaurantState = useSelector(listRestaurantSelector);
  const programDetailState = useSelector(programDetailSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      if (currentStudentState.data?.data?.studentData.SpecialProgram === "1") {
        dispatch(
          getDetailProgram({
            data: {
              program_id:
                currentStudentState.data?.data?.studentData.program_id || "",
            },
          })
        );
      }

      dispatch(
        listRestaurant({
          data: {
            items_per_page: 1000,
            SchoolID: currentStudentState.data?.data?.studentData.SchoolID,
            page_number: 1,
            sort_by: "alpha",
            sort_order: "asc",
          },
        })
      );
    }
  }, [currentStudentState]);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | OCMP</title>
      </Helmet>
      {currentStudentState.isFetching && (
        <div className="container">
          <div className="card d-flex w-100 align-items-center justify-content-center my-4">
            <Spinner type="border" color="primary" />
          </div>
        </div>
      )}
      {currentStudentState.data?.data?.cardData &&
        !currentStudentState.data?.data?.cardData?.CardNumber && (
          <DashboardNewAccount />
        )}
      {currentStudentState.data &&
        currentStudentState?.data?.data?.cardData.CardNumber && (
          <TransactionList
            alertData={currentStudentState?.data?.data?.alert}
            cardData={currentStudentState.data?.data?.cardData}
            cardLoading={currentStudentState.isFetching}
            studentData={currentStudentState.data?.data?.studentData}
            transactions={currentStudentState?.data?.data?.transactions}
            transactionsLoading={currentStudentState?.isFetching}
            programData={programDetailState.data}
          />
        )}

      <RestaurantList
        restaurants={listRestaurantState.data}
        loading={
          currentStudentState.isFetching || listRestaurantState.isFetching
        }
      />
      {currentStudentState.data?.data?.studentData.EmailAddress &&
        currentStudentState.data?.data?.studentData.SchoolID && (
          <SuggestRestaurantBySchoolModal
            email={currentStudentState.data?.data?.studentData.EmailAddress}
            SchoolID={currentStudentState.data?.data?.studentData.SchoolID}
          />
        )}
    </DashboardLayout>
  );
};

export default Dashboard;
