import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch } from "../app/hooks";
import OCMPLogo from "../assets/img/ocmp.svg";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";
import DashboardLayout from "../components/layout/DashboardLayout";
import Card from "../modules/mycard/Card";
import {
  currentStudentSelector,
  loadDetailsTransactionSelector
} from "../state/student/studentSelector";
import { studentActions } from "../state/student/studentSlice";
import {
  toMaskedCardNumberForDownload,
  toMaskedCardNumberFromAsterisk
} from "../utils/string";

const OrderComplete = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const orderRef = useRef<HTMLDivElement>(null);

  const currentStudentState = useSelector(currentStudentSelector);
  const transactionState = useSelector(loadDetailsTransactionSelector);
  const order = transactionState.data?.data;

  const pageStyle = `{     
    @page {
    size: A4;
    } 
  }`;
  const handlePrint = useReactToPrint({
    content: () => orderRef.current,
    pageStyle
  });

  useEffect(() => {
    if (params.orderId) {
      dispatch(
        studentActions.loadDetailsTransaction({
          data: {
            order_number: params.orderId
          },
          onComplete: (error, data) => {
            if (!data?.status) {
              navigate("/404NotFound");
            }
          }
        })
      );
    }
  }, [params]);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order Information | OCMP</title>
      </Helmet>
      {order ? (
        <>
          {/* breadcrumb */}
          <BreadCrumbLayout>
            <li className="breadcrumb-item">
              <Link to="/my-card">My Card</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/my-card-add-funds">Add Funds</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {order.PaymentStatus === "PENDING" && "Pending"} Order Complete
            </li>
          </BreadCrumbLayout>
          {/* transaction */}
          <section className="transaction ordercomplete">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="lg-title">Thank you, {order.first_name}</h2>
                  {order.PaymentStatus === "PENDING" ? (
                    <>
                      <div className="dark-text mb-0">
                        Your order is being processed. Your Order Number is{" "}
                        {order.order_number}.
                        <br />
                        <span className="f400">
                          Please send your check with the total
                        </span>{" "}
                        ${order.Amount}{" "}
                        <span className="f400">to following address:</span>
                        <p className="my-4">
                          Boston Office <br />
                          PO Box 129
                          <br />
                          Newton, MA 02456
                        </p>
                        <div className="d-md-flex justify-content-between align-items-center mb-4">
                          <div className="dark-text mb-0">
                            <span className="f400">
                              Please make a note on the check this payment
                              <br /> is for order
                            </span>{" "}
                            #{order.order_number}.
                          </div>

                          <button
                            onClick={handlePrint}
                            className="btn btn-primary mt-md-0 mt-3"
                            id="download"
                          >
                            Print Receipt
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="d-md-flex justify-content-between align-items-center mb-4">
                      <p className="dark-text mb-0">
                        Your order has been confirmed. <br /> Your Order Number
                        is {order.order_number}
                      </p>
                      <button
                        onClick={handlePrint}
                        className="btn btn-primary mt-md-0 mt-3"
                      >
                        Print Receipt
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="card order-summary" ref={orderRef as any}>
                    <div>
                      <h2 className="xl-title primary-text mb-5">
                        {order.PaymentStatus === "PENDING"
                          ? "Pending Order Complete"
                          : "Order Summary"}
                      </h2>
                      <div id="invoice">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "\n                                    body, html {\n                                        background: #F7F7F7;\n                                        font-family: 'Roboto', sans-serif;\n                                        color: #212529;\n                                    }\n                                    .xl-title {\n                                        font-size: 32px;\n                                        line-height: 34px;\n                                        font-weight: 500;\n                                        color: #00529A;\n                                    }\n                                    .md-title {\n                                        font-size: 20px;\n                                        line-height: 24px;\n                                        font-weight: 500;\n                                        letter-spacing: 0.002em;\n                                    }\n                                    .primary-text {\n                                        color: #00529A;\n                                    }\n                                    .f500 {\n                                        font-weight: 500;\n                                    }\n                                    .mb-5 {\n                                        margin-bottom: 3rem!important;\n                                    }\n                                    .col-md-6 {\n                                        width: 50%;\n                                    }\n                                    .mt-3 {\n                                        margin-top: 1rem!important;\n                                    }\n                                "
                          }}
                        />
                        <p className="md-title">Payment Information</p>
                        {order.PaymentStatus === "DECLINED" ? (
                          <p className="mb-5">Declined</p>
                        ) : (
                          <div className="row mb-5">
                            <div className="col-md-6">
                              <p className="mb-0">
                                {order.payment_method_desc}
                              </p>
                              {order.payment_method === "CC" && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: toMaskedCardNumberFromAsterisk(
                                      order.CreditCardNumber
                                    )
                                  }}
                                />
                              )}
                            </div>
                            <div className="col-md-6">
                              {order.BillingName}
                              <br />
                              {order.BillingAddr_A}
                              {order.BillingAddr_B !== "" && (
                                <>
                                  <br />
                                  {order.BillingAddr_B}
                                </>
                              )}
                              <br />
                              {order.BillingCity !== ""
                                ? order.BillingCity + ", "
                                : ""}
                              {order.BillingState}{"  "}{order.BillingZip}
                            </div>
                          </div>
                        )}

                        <p className="md-title">Shipping Address</p>
                        <div className="row mb-5">
                          <div className="col-md-6">
                            {order.MailToAddr_A !== "" ? (
                              <>
                                {order.NamePrintedOnCard} <br />
                                {order.MailToAddr_A}
                                {order.MailToAddr_B !== "" && (
                                  <>
                                    <br /> {order.MailToAddr_B}
                                  </>
                                )}
                                <br />
                                {order.MailToCity !== ""
                                  ? order.MailToCity + ", "
                                  : ""}
                                {order.MailToState}{"  "}{order.MailToZip}
                              </>
                            ) : (
                              <>
                                <p>No card shipped</p>
                                If you do not have possession of your card,
                                please report it lost or stolen on the{" "}
                                <Link to="/my-card">My Card</Link> page
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <span className="f500">
                              Name to be printed on the Card:
                            </span>
                            <br />
                            {order.NamePrintedOnCard} <br />
                            <b>Student Email: </b>
                            {
                              currentStudentState.data?.data?.studentData
                                .student_email
                            }{" "}
                            <br />
                            <b> School:</b> {order.schoolName}
                          </div>
                        </div>
                        <p className="md-title">Purchase Summary</p>
                        <div className="row mb-5">
                          <div className="col-md-6">
                            Plan: {order.plan_name?.split("-")[0]?.trimEnd()}
                            <br />
                            Description: {order.plan_meals} Meals
                            <br />
                            Additional Meals:{" "}
                            {order.additional_meals === ""
                              ? 0
                              : order.additional_meals + " Meals"}
                          </div>
                        </div>
                        {order.PaymentStatus !== "DECLINED" && (
                          <p className="md-title">
                            <span className="primary-text">TOTAL:</span> $
                            {order.Amount}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <Card
                    className="h-auto"
                    cardData={currentStudentState.data?.data.cardData}
                    studentData={currentStudentState.data?.data?.studentData}
                  />
                </div>
              </div>
              <div style={{ position: "absolute", left: "-500vw", top: 0 }}>
                <div className="bg-white px-54 py-40" ref={orderRef as any}>
                  <div className="row">
                    <div className="col-md-6">
                      <img src={OCMPLogo} alt="OCMP Logo" className="h-86" />
                    </div>
                    <div className="col-md-6">
                      <p className="text-end f14 m-0">
                        Off Campus Meal Plan <br />
                        Boston Office <br />
                        PO Box 129 <br />
                        Newton, MA 02456
                      </p>
                    </div>
                  </div>
                  <div className="mt-54">
                    <p className="f14">
                      Order# {order.order_number} <br />
                      {moment(order.Stamp).format("MM.DD.YYYY")}
                    </p>
                    <p className="f16 f500 mb-1">Payment Information</p>
                    {order.PaymentStatus === "DECLINED" ? (
                      <p className="mb-5">Declined</p>
                    ) : (
                      <div className="row mb-5">
                        <div className="col-md-6">
                          <p className="mb-0">{order.payment_method_desc}</p>
                          {order.payment_method === "CC" && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: toMaskedCardNumberForDownload(
                                  order.CreditCardNumber
                                )
                              }}
                              style={{ marginTop: "-10px" }}
                            />
                          )}
                        </div>
                        <div className="col-md-6">
                          {order.BillingName}
                          <br />
                          {order.BillingAddr_A}
                          {order.BillingAddr_B !== "" && (
                            <>
                              <br />
                              order.BillingAddr_B
                            </>
                          )}
                          <br />
                          {order.BillingCity !== ""
                            ? order.BillingCity + ", "
                            : ""}
                          {order.BillingState}{" "}{order.BillingZip}
                        </div>
                      </div>
                    )}
                    <div className="row mb-30">
                      <div className="col-md-6">
                        <p className="f16 f500 mb-1">Shipping Address</p>
                        <p className="f14">
                          {order.MailToAddr_A !== "" ? (
                            <>
                              {order.NamePrintedOnCard} <br />
                              {order.MailToAddr_A}
                              {order.MailToAddr_B !== "" && (
                                <>
                                  <br /> order.MailToAddr_B
                                </>
                              )}
                              <br />
                              {order.MailToCity}{", "}{order.MailToState}{"  "}{order.MailToZip}
                            </>
                          ) : (
                            <>
                              <p>No card shipped</p>
                              If you do not have possession of your card, please
                              report it lost or stolen on the{" "}
                              <Link to="/my-card">My Card</Link> page
                            </>
                          )}
                        </p>
                      </div>
                      <div className="col-md-6 f14">
                        <p className="f500 m-0">Name to be printed on the Card</p>
                        <p>
                          {order.NamePrintedOnCard}
                          <br />
                          <span className="f500">Student Email:</span>{" "}
                          {
                            currentStudentState.data?.data?.studentData
                              .student_email
                          }
                          <br />
                          <span className="f500">School:</span>{" "}
                          {order.schoolName}
                        </p>
                      </div>
                    </div>
                    <p className="f16 f500 mb-1">Purchase Summary</p>
                    <div className="row mb-5">
                      <div className="col-md-6 f14">
                        <span className="f500">Plan:</span>{" "}
                        {order.plan_name?.split("-")[0]?.trimEnd()}
                        <br />
                        <span className="f500">Description:</span>{" "}
                        {order.plan_meals} Meals
                        <br />
                        <span className="f500">Additional Meals:</span>{" "}
                        {order.additional_meals === ""
                          ? 0
                          : order.additional_meals + " Meals"}
                      </div>
                    </div>
                    {order.PaymentStatus !== "DECLINED" && (
                      <p className="f16 f500">
                        <span className="primary-text">TOTAL:</span> $
                        {order.Amount}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </DashboardLayout>
  );
};

export default OrderComplete;
