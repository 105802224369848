import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "../assets/style/main.css";
import "../assets/style/portal-override.css";
import { getStudent } from "../state/student/studentSlice";
import { AlertProvider } from "./AlertContext";
import AppRoutes from "./AppRoutes";
import { APP_ROUTES } from "./routes";
import { useAppDispatch } from "./hooks";
import ScrollToTop from "../components/common/ScrollToTop";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const page = Object.values(APP_ROUTES).find((r) =>
      window.location.pathname.startsWith(r.path)
    );
    if (page?.withAuth) dispatch(getStudent());
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <AlertProvider>
          <AppRoutes />
        </AlertProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
