import moment from "moment";

export const generateNext12Years = (): string[] => {
  const currentYear = moment().year();
  return new Array(12).fill(0).map((item, index) => `${currentYear + index}`);
};

export const hoursOperation = (
  start: string | undefined,
  end: string | undefined
): string => {
  if (start) {
    return (
      start.toLowerCase().replace(" ", "") +
      " - " +
      end?.toLowerCase().replace(" ", "")
    );
  }
  return "Closed";
};
