import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RestaurantEntity } from "../../api/restaurantApi";
import { hoursOperation } from "../../utils/datetime";
import { getLogoUrl } from "../../utils/logo";
import { isHttp, isUrl } from "../../utils/string";
import FavoriteCheckbox from "./FavoriteCheckbox";
import Map from "./Map";

type RestaurantListProps = {
  restaurantData: Array<RestaurantEntity>;
  isFetching: boolean;
  setMID: any;
  activeTab: string;
  handleChangeFavorite: any;
};

const RestaurantMapView: React.FC<RestaurantListProps> = ({
  restaurantData,
  setMID,
  activeTab,
  handleChangeFavorite,
}) => {
  const [popupMID, setPopupMID] = useState<number>();

  useEffect(() => {
    setPopupMID(0);
  }, [activeTab]);

  return (
    <div className="row">
      <div className="col-xl-6 d-md-block d-none order-lg-1 order-2">
        <div className="bg-white shadow-sm py-2 border-none rounded-3 d-flex justify-content-between flex-column table-responsive">
          {restaurantData.length > 0 ? (
            <div className="restaurants_table py-3">
              {restaurantData.map((restaurant, key) => (
                <div
                  className="restaurant_item"
                  key={key}
                  onClick={() => setPopupMID(restaurant.M_ID)}
                >
                  <div className="row restaurant_content">
                    <div className="restaurant_name col-6">
                      <img
                        src={getLogoUrl(restaurant.Logo)}
                        alt="..."
                        className="img-fluid"
                      />
                      <div>
                        <p className="d-none d-md-block type truncate">
                          {restaurant.Type}
                        </p>
                        <h5 className="mb-0">
                          {restaurant.Name}
                          {restaurant.Delivery === "1" && (
                            <Link
                              to="#delivery"
                              className="me-3 d-inline-block d-md-none deliveryAvailable"
                            >
                              <img src="img/delivery.svg" alt="" />
                            </Link>
                          )}
                        </h5>
                        <p className="d-block d-md-none">
                          4140 Parker Rd. &nbsp;&nbsp;&nbsp; 8am - 10pm
                        </p>
                        <p className="d-none d-md-block">{restaurant.Addr_A}</p>
                      </div>
                    </div>
                    <div className="col-1">
                      {restaurant.Delivery === "1" && (
                        <Tippy content="This restaurant offers delivery.">
                          <Link
                            to="#delivery"
                            className="me-3 d-none d-md-block"
                          >
                            <img src="img/delivery.svg" alt="" />
                          </Link>
                        </Tippy>
                      )}
                    </div>
                    <div className="col-4">
                      {restaurant.Phone && (
                        <a
                          className="d-flex align-items-center mb-2"
                          href={`tel:${restaurant.Phone}`}
                        >
                          <img
                            src="img/phone-call.svg"
                            alt=""
                            className="me-2"
                          />
                          {restaurant.Phone}
                        </a>
                      )}

                      <div className="d-flex align-items-center text-nowrap">
                        <img src="img/clock.svg" alt="" className="me-2" />
                        {hoursOperation(
                          restaurant.OpenTime,
                          restaurant.CloseTime
                        )}
                      </div>
                    </div>
                    <div className="d-md-none d-inline-block">
                      {restaurant.Phone && (
                        <a
                          href={`tel:${restaurant.Phone}`}
                          className="btn-contact"
                        >
                          Call <img src="img/phone.svg" alt="" />
                        </a>
                      )}
                    </div>
                    <div className="d-md-none d-inline-block">
                      <a href={restaurant.Web} className="btn-contact">
                        Website <img src="img/web.svg" alt="..." />
                      </a>
                    </div>
                    <div className="col-1 align-self-center">
                      <FavoriteCheckbox
                        studentFavorite={restaurant.student_favorite}
                        mID={String(restaurant.M_ID)}
                        checkboxID={`map${String(restaurant.M_ID)}`}
                        handleChangeFavorite={handleChangeFavorite}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="px-40 my-4">
              <div className="no-restaurant p-5">
                <h5 className="text-center mb-4">
                  No restaurants have been found
                </h5>
                <Link to="/restaurants" className="btn btn-primary">
                  View All Restaurants
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-xl-6 order-lg-2 order-1">
        <div className="map_wrapper">
          <Map
            restaurantData={restaurantData}
            popupMID={popupMID}
            activeTab={activeTab}
            setPopupMID={setPopupMID}
          />
          {restaurantData.map((restaurant) => {
            return (
              restaurant.M_ID === popupMID && (
                <div className="mapPopup">
                  <div className="restaurant_name">
                    <img
                      src={getLogoUrl(restaurant.Logo)}
                      alt="..."
                      className="img-fluid"
                    />
                    <div>
                      <p>{restaurant.Type}</p>
                      <h5 className="mb-0">
                        {restaurant.Name}
                        {restaurant.Delivery === "1" && (
                          <Link
                            to="#delivery"
                            className="me-1 deliveryAvailable"
                          >
                            <img
                              src="img/delivery.svg"
                              width={24}
                              height={24}
                              alt=""
                            />
                          </Link>
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="row f14 my-4">
                    <div className="col-md-6">
                      <div className="d-flex align-items-start">
                        <img src="img/map-marker.svg" className="me-2" alt="" />
                        <div>
                          <p className="mb-0">{restaurant.Addr_A}</p>
                          {restaurant.Addr_B && (
                            <p className="mb-0">{restaurant.Addr_B}</p>
                          )}
                          <p className="mb-0">{`${restaurant.City}, ${restaurant.State} ${restaurant.Zip}`}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-md-0 mt-3">
                      {restaurant.Phone && (
                        <a
                          className="d-flex align-items-center mb-2"
                          href={`tel:${restaurant.Phone}`}
                        >
                          <img
                            src="img/phone-call.svg"
                            alt=""
                            className="me-2"
                          />
                          {restaurant.Phone}
                        </a>
                      )}

                      <div className="d-flex align-items-center">
                        <img src="img/clock.svg" alt="" className="me-2" />
                        {hoursOperation(
                          restaurant.OpenTime,
                          restaurant.CloseTime
                        )}
                      </div>
                    </div>
                  </div>
                  {isUrl(restaurant.Web) && (
                    <a
                      href={
                        !isHttp(restaurant.Web)
                          ? restaurant.Web
                          : `https://${restaurant.Web}`
                      }
                      className="btn btn-primary d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visit Website
                    </a>
                  )}

                  <Link
                    to="#moreDetail"
                    data-bs-toggle="modal"
                    className="btn btn-outline d-block"
                    onClick={() => setMID(String(restaurant.M_ID))}
                  >
                    More Details
                  </Link>
                  <ul className="list-inline align-items-center modalClose">
                    <li className="list-inline-item">
                      <FavoriteCheckbox
                        studentFavorite={restaurant.student_favorite}
                        mID={String(restaurant.M_ID)}
                        checkboxID={`popup${String(restaurant.M_ID)}`}
                        handleChangeFavorite={handleChangeFavorite}
                      />
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setPopupMID(0)}
                      >
                        <img src="img/close-icon.svg" alt="" />
                      </button>
                    </li>
                  </ul>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RestaurantMapView;
