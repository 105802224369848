import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import transactionApi, {
  APIListSchoolsResponse,
  SchoolEntity,
} from "../../api/schoolApi";
import { onCompleteCB } from "../../utils/apiClient";

export const listSchools = createAsyncThunk<
  APIListSchoolsResponse,
  onCompleteCB<APIListSchoolsResponse> | undefined
>("school/list", (onComplete = () => {}) => {
  return transactionApi
    .listSchools()
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export interface SchoolSate {
  isFetching: boolean;
  data: Array<SchoolEntity>;
}

const initialSchoolState: SchoolSate = {
  isFetching: false,
  data: [],
};

const schoolSlice = createSlice({
  name: "school",
  initialState: initialSchoolState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSchools.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(listSchools.fulfilled, (state, action) => {
        state.isFetching = false;
        state.data = action.payload.payload.data;
      })
      .addCase(listSchools.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const schoolReducer = schoolSlice.reducer;

export const schoolActions = {
  ...schoolSlice.actions,
  listSchools,
};
