import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import transactionApi, {
  APIListRecentTransactionsResponse,
  TransactionEntity,
} from "../../api/transactionApi";
import { onCompleteCB } from "../../utils/apiClient";

export const listRecentTransactions = createAsyncThunk<
  APIListRecentTransactionsResponse,
  onCompleteCB<APIListRecentTransactionsResponse> | undefined
>("transaction/recent_transactions", (onComplete = () => {}) => {
  return transactionApi
    .listRecentTransactions({ count: 5 })
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export interface TransactionSate {
  recentTransactions: {
    isFetching: boolean;
    data: Array<TransactionEntity>;
  };
  [shoolId: string]: {
    isFetching: boolean;
    data: Array<TransactionEntity>;
  };
}

const initialTransactionState: TransactionSate = {
  recentTransactions: {
    isFetching: false,
    data: [],
  },
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listRecentTransactions.pending, (state) => {
        state.recentTransactions.isFetching = true;
      })
      .addCase(listRecentTransactions.fulfilled, (state, action) => {
        state.recentTransactions.isFetching = false;
        state.recentTransactions.data =
          action.payload.payload.data.transactions;
      })
      .addCase(listRecentTransactions.rejected, (state) => {
        state.recentTransactions.isFetching = false;
      });
  },
});

export const transactionReducer = transactionSlice.reducer;

export const transactionActions = {
  ...transactionSlice.actions,
  listRecentTransactions,
};
