import React from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";

export const DashboardLayoutFooter: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <ul className="list-inline mb-md-0 page_link">
              <li className="list-inline-item">
                <Link to={APP_ROUTES.Dashboard.path}>My Dashboard</Link>
              </li>
              <li className="list-inline-item">
                <Link to={APP_ROUTES.GetHelp.path}>Get Help</Link>
              </li>
              <li className="list-inline-item">
                <Link to="#referFriend" data-bs-toggle="modal">
                  Refer a Friend
                </Link>
              </li>
              <li className="list-inline-item social_icon">
                <a
                  href="https://www.instagram.com/ocmpchs/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="img/insta.svg" alt="" />
                </a>
              </li>
              <li className="list-inline-item social_icon">
                <a
                  href="https://www.facebook.com/ocmpcharleston"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="img/fb.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 text-end copyright">
            <ul className="list-inline mb-0 d-md-none d-inline-flex social_icon">
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/ocmpchs/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="img/insta.svg" alt="" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.facebook.com/ocmpcharleston"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="img/fb.svg" alt="" />
                </a>
              </li>
            </ul>
            ©{new Date().getFullYear()} All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};
