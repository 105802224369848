import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";
import AuthLayout from "../../components/layout/AuthLayout";
import RegisterForm from "../../modules/auth/RegisterForm";

const Register = () => {
  const [error, setError] = useState<string>("");

  return (
    <AuthLayout
      pageName="auth-register"
      navItem={{ url: "/login", text: "Login" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register | OCMP</title>
      </Helmet>
      <section>
        <div className="authentication_box register_box">
          {error && (
            <div className="alert alert-danger p-2 mb-4" role="alert">
              {error}
            </div>
          )}
          <h1 className="xl-title text-center mb-4">Register</h1>
          <RegisterForm
            handleError={(err: string) => {
              setError(err);
            }}
          />
          <Link to={APP_ROUTES.Login.path} className="register_link">
            Already Registered? Login Here!
          </Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default Register;
