import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { CardEntity, StudentEntity } from "../../api/studentApi";

type CardProps = {
  cardData: CardEntity | undefined;
  studentData: StudentEntity | undefined;
};

const PrintTempCard: React.FC<CardProps> = ({ cardData, studentData }) => {
  const componentRef = useRef<any>(null);
  const pageStyle = `{ 
    @page { 
      size: A4;
    }
  }`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  return (
    <div
      className="modal fade"
      id="printTempCard"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header tempCardBg">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="tempCardBg tempMargin tempCardPrint">
              <div className="img-fluid d-block mx-auto position-relative text-center img-wrapper">
                <div className="img-content" />
                <h5>{studentData?.name_on_card}</h5>
                <h6>{cardData?.TempCardNumber}</h6>
              </div>
            </div>
            <div className="max-width-424 mx-auto">
              <div>
                <span className="f500 d-block">Students:</span>
                <p className="mb-0">
                  This is your digital copy of your OCMP card.
                  <br /><br />
                  You can use this card immediately while you are waiting for
                  your permanent card to arrive. Merchants will enter this card
                  number along with your 4-digit PIN to process your
                  transaction.
                  <br /><br />
                  You can also upload this card number into your Grubhub and
                  SnackPass apps.<br /><br />
                </p>
              </div>
              <div>
                <span className="f500 d-block">Restaurants:</span>
                <p className="mb-0">
                  This is a valid digital card issued by OCMP.
                  <br /><br />
                  Students use this card number when they are waiting for their
                  permanent card to arrive.
                  <br /><br />
                  Press “Sale” and manually enter the 6-digit card number and
                  then the cardholder’s 4-digit PIN. Then proceed as a regular
                  transaction.
                  <br /><br />
                  Having problems?  Give us a call at 888-211-6267.
                </p>
              </div>
              <button
                className="btn btn-primary d-block w100"
                onClick={handlePrint}
              >
                Print Temp Card
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="print-source" ref={componentRef as any}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body tempCardPrint printed-card">
              <div className="mb-4"></div>
              <div className="img-fluid d-block mx-auto position-relative text-center img-wrapper">
                <div className="img-content">
                  <img src="img/tempCard.svg" alt="" />
                </div>
                <h5>{studentData?.name_on_card}</h5>
                <h6>{cardData?.TempCardNumber}</h6>
              </div>
            </div>
            <div className="max-width-424 mx-auto">
              <p>
                <span className="f500 d-block">Students:</span>
                This is your digital copy of your OCMP card.
                <br /><br />
                You can use this card immediately while you are waiting for your
                your permanent card to arrive. Merchants will enter this 6-digit
                card number along with your 4-digit PIN to process your
                transaction.
                <br /><br />
                You can also upload this card number into your Grubhub and
                SnackPass apps.<br /><br />
              </p>
              <p>
                <span className="f500 d-block">Restaurants:</span>
                This is a valid digital card issued by OCMP.
                <br /><br />
                Students use this card number when they are waiting for their
                permanent card to arrive.
                <br /><br />
                Press “Sale” and manually enter the 6-digit card number and then
                the cardholder’s 4-digit PIN. Then proceed as a regular
                transaction.
                <br /><br />
                Having problems?  Give us a call at 888-211-6267.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrintTempCard;
