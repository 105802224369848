import apiClient from "../utils/apiClient";

export type APIListRecentTransactionsInput = {
  count?: number;
  SchoolID?: number;
};

export type TransactionEntity = {
  Stamp: string;
  MerchantName: string;
  TrxnType: string;
  Name: string;
  FormattedAmount: string;
  FormattedTip: string;
  Amount: string;
  TipAmount: string;
  Meals: string;
  TipMeals: string;
};

export type APIListRecentTransactionsResponse = {
  status: boolean;
  action_code: number;
  message: string;
  time_stamp: string;
  payload: {
    data: {
      transactions: Array<TransactionEntity>;
    };
  };
};

const listRecentTransactions = (data: APIListRecentTransactionsInput) => {
  return apiClient.get<APIListRecentTransactionsResponse>(
    `/v1/transaction/recenttransactions.json`,
    { params: data }
  );
};

const transactionApi = {
  listRecentTransactions,
};

export default transactionApi;
