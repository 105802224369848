import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch } from "../app/hooks";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";
import DashboardLayout from "../components/layout/DashboardLayout";
import RestaurantDetailsModal from "../modules/restaurants/RestaurantDetailsModal";
import RestaurantListView from "../modules/restaurants/RestaurantListView";
import RestaurantMapView from "../modules/restaurants/RestaurantMapView";
import SuggestRestaurantModal from "../modules/restaurants/SuggestRestaurantModal";
import { getServiceData } from "../services/service-data";
import {
  listRestaurantSelector,
  restaurantTypesSelector,
} from "../state/restaurant/restaurantSelector";
import { getTypes, listRestaurant } from "../state/restaurant/restaurantSlice";
import { currentStudentSelector } from "../state/student/studentSelector";

type FilterType = {
  value: string;
  label: string;
};
const Restaurants = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const myFavoriteRef = useRef();

  const listRestaurantState = useSelector(listRestaurantSelector);
  const restaurantTypesState = useSelector(restaurantTypesSelector);
  const currentStudentState = useSelector(currentStudentSelector);

  const [mID, setMID] = useState("");
  const [activeTab, setActiveTab] = useState("list");
  const [openFilter, setOpenFilter] = useState(false);
  const [restaurantData, setRestaurantData] = useState(
    listRestaurantState?.data
  );
  const [filter, setFilter] = useState({
    favorites: params.get("isFavorites") === "true",
    location: {
      lat: "",
      lng: "",
    },
    category: {
      value: "",
      label: "All Category",
    },
    search: "",
  });
  const [search, setSearch] = useState("");
  const [relevantOptions, setRelevantOptions] = useState<FilterType[] | any[]>(
    []
  );
  const [service, setService] = React.useState({});

  async function fetchServiceData() {
    const res = await getServiceData();
    setService(res.data);
  }

  useEffect(() => {
    fetchServiceData();
  }, [currentStudentState]);

  useEffect(() => {
    dispatch(getTypes());
  }, []);

  useEffect(() => {
    // Update the search param for favorite toggle
  }, [filter]);

  const handleFavorite = () => {
    const newRestaurantData = listRestaurantState?.data.filter((restaurant) => {
      return filter.favorites
        ? restaurant.student_favorite === "1"
        : restaurant;
    });
    setRestaurantData(newRestaurantData);
  };

  useEffect(() => {
    handleFavorite();
  }, [listRestaurantState]);

  const handleChangeFavorite = () => {
    setMID("");
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      dispatch(
        listRestaurant({
          data: {
            latitude: filter.location.lat,
            longitude: filter.location.lng,
            name: filter.search,
            type: filter.category.value,
            items_per_page: 1000,
            SchoolID: currentStudentState.data?.data?.studentData.SchoolID,
            page_number: 1,
            sort_by: "alpha",
            sort_order: "asc",
          },
          onComplete: (error, data) => {
            handleFavorite();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      dispatch(
        listRestaurant({
          data: {
            latitude: filter.location.lat,
            longitude: filter.location.lng,
            name: filter.search,
            type: filter.category.value,
            items_per_page: 1000,
            SchoolID: currentStudentState.data?.data?.studentData.SchoolID,
            page_number: 1,
            sort_by: "alpha",
            sort_order: "asc",
          },
        })
      );
      closeFilter();
    }
  }, [filter, currentStudentState.data?.data]);

  useEffect(() => {
    if (restaurantTypesState.data && listRestaurantState.data) {
      const filterOptions: FilterType[] = [];
      filterOptions.push({
        value: "",
        label: "All Category",
      });
      restaurantTypesState.data.forEach((rt) => {
        listRestaurantState.data.forEach((lr) => {
          if (rt.type === lr.Type) {
            if (!filterOptions.find((f) => f.value === rt.type)) {
              filterOptions.push({
                value: rt.type,
                label: rt.type,
              });
            }
          }
        });
      });
      setRelevantOptions((prevState) => {
        if (prevState.length > 1) return prevState;
        return filterOptions;
      });
    }
  }, [restaurantTypesState, listRestaurantState]);
  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setFilter({
            ...filter,
            location: {
              lat: String(lat),
              lng: String(lng),
            },
          });
        }
      );
    } else {
      setFilter({
        ...filter,
        location: {
          lat: "",
          lng: "",
        },
      });
    }
  };

  const closeFilter = () => {
    const p = document.getElementById("filter-backdrop");
    p?.parentNode?.removeChild(p);

    setOpenFilter(false);
  };

  const handleOpenFilter = () => {
    const p = document.createElement("div");
    p.className = "filter-backdrop";
    p.id = "filter-backdrop";
    p.onclick = closeFilter;
    document.body.appendChild(p);

    setOpenFilter(true);
  };

  const hasServices =
    currentStudentState?.data?.data?.schoolServices &&
    currentStudentState?.data?.data?.schoolServices.length > 0;

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Restaurants | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Restaurants
        </li>
      </BreadCrumbLayout>
      <section className="restaurants">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link
                to="#filter"
                className="d-block d-md-none btn btn-manage btn-filter"
                onClick={handleOpenFilter}
              >
                Filter
              </Link>
              <form action="" className="filterForm">
                <div className={`filter ${openFilter && "d-flex"}`}>
                  <div className="filter_title lg-title f500 d-none d-md-block">
                    Filter by:
                  </div>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-4">
                      <span className="f500 me-2">Near My Location</span>
                      <input
                        type="checkbox"
                        className="switchToggle"
                        id="location"
                        name="location"
                        value="location"
                        onChange={handleLocation}
                      />
                      <label
                        htmlFor="location"
                        className="d-inline-block v-top mb-0"
                      />
                    </li>
                    <li className="list-inline-item">
                      <span className="f500 me-2">My Favorites</span>
                      <input
                        type="checkbox"
                        className="switchToggle"
                        id="favorites"
                        name="favorites"
                        checked={filter.favorites}
                        value="favorites"
                        onChange={(event) => {
                          setFilter({
                            ...filter,
                            [event.target.name]: event.target.checked,
                          });
                        }}
                      />
                      <label
                        ref={myFavoriteRef as any}
                        htmlFor="favorites"
                        className="d-inline-block v-top mb-0"
                      />
                    </li>
                  </ul>
                  <div className="d-flex align-items-center">
                    {relevantOptions && (
                      <Select
                        placeholder="Category"
                        value={filter.category}
                        options={relevantOptions}
                        className="form-control min-width-240 me-3 p-0 h-100"
                        onChange={(selectedOption: any) => {
                          setFilter({
                            ...filter,
                            category: selectedOption,
                          });
                        }}
                      />
                    )}

                    <div className="input-group min-width-400">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search OCMP Restaurants"
                        name="search"
                        onChange={(event) => {
                          setSearch(event.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            setFilter({
                              ...filter,
                              search,
                            });
                          }
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          setFilter({
                            ...filter,
                            search,
                          });
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <ul
                className="nav nav-tabs view_layout"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    className="nav-link active"
                    to="#listView"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setActiveTab("list")}
                  >
                    List View <img src="img/list.svg" alt="" />
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className="nav-link"
                    to="#mapView"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    onClick={() => setActiveTab("map")}
                  >
                    Map View <img src="img/map.svg" alt="" />
                  </Link>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="listView"
                  role="tabpanel"
                >
                  {hasServices && (
                    <div className="ocmp_ad_box horizontal_ad">
                      <div>
                        <h2
                          style={{ textAlign: "center" }}
                          className="mb-0 d-block"
                        >
                          Order Food on the Go
                        </h2>
                      </div>
                      <div>
                        <p
                          style={{ textAlign: "center" }}
                          className="mb-0 d-block"
                        >
                          Order delivery from 100’s of additional restaurants.&nbsp;<br />
                          Simply upload your OCMP card as a payment option in&nbsp; <br />
                          our delivery partner’s apps
                        </p>
                        <p
                          style={{ textAlign: "center" }}
                          className="mb-0 d-md-block d-sm-none d-none"
                        >
                          <strong>Connect your account to get started.</strong>
                        </p>
                      </div>
                      <div className="btn-toolbar">
                        {currentStudentState.data?.data.schoolServices?.map(
                          (s) => (
                            <a
                              key={s.SchoolServiceID}
                              href={
                                service
                                  ? _.get(
                                      service,
                                      `${s.SchoolServiceID}.schools.${currentStudentState.data?.data.studentData.SchoolID}.how_to_link`
                                    )
                                  : s.URL
                              }
                              className="btn btn-manage-service mt-1"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {s.Description}
                            </a>
                          )
                        )}
                      </div>
                      <div className="d-md-none">
                        <p
                          style={{ textAlign: "center" }}
                          className="m-md-0 mb-0"
                        >
                          Connect your account to get started.
                        </p>
                      </div>
                    </div>
                  )}
                  <RestaurantListView
                    myFavoriteRef={myFavoriteRef}
                    restaurantData={restaurantData}
                    setMID={setMID}
                    handleChangeFavorite={handleChangeFavorite}
                    isFetching={listRestaurantState?.isFetching}
                  />
                </div>
                <div className="tab-pane fade" id="mapView" role="tabpanel">
                  <RestaurantMapView
                    restaurantData={restaurantData}
                    isFetching={listRestaurantState.isFetching}
                    activeTab={activeTab}
                    setMID={setMID}
                    handleChangeFavorite={handleChangeFavorite}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="suggest-friend-block">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="get-started-banner">
                <div
                  style={{ color: "#fff", position: "relative", zIndex: "999" }}
                >
                  <h1>Suggest a Restaurant</h1>
                  <p className="f500">
                    Don't see your favorite restaurant? Let us know and we'll
                    get right on it!
                  </p>
                  <Link
                    to="#suggestRestaurant"
                    data-bs-toggle="modal"
                    className="btn btn-manage"
                  >
                    Make A Suggestion
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentStudentState.data?.data?.studentData.EmailAddress && (
        <SuggestRestaurantModal
          email={currentStudentState.data?.data?.studentData.EmailAddress}
        />
      )}
      {!!mID && (
        <RestaurantDetailsModal
          isOpen={!!mID}
          toggle={() => setMID("")}
          m_id={mID}
          setMID={setMID}
          handleChangeFavorite={handleChangeFavorite}
        />
      )}
    </DashboardLayout>
  );
};
export default Restaurants;
