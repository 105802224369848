import _ from "lodash";
import React from "react";
import { Spinner } from "reactstrap";
import { MealPlanEntity } from "../../api/mealPlanApi";
import { formatNumber, getNumber } from "../../utils/string";

interface PlanListProps {
  loading: boolean;
  mealData: MealPlanEntity[];
  handleSelectPlan: (plan: MealPlanEntity) => void;
  hasCard: boolean;
  planDescriptions?: string[];
}

export const getMealPrice = (mealPlan: MealPlanEntity, hasCard: boolean) => {
  const baseCost = getNumber(mealPlan.base_cost);
  const activationFee = getNumber(mealPlan.activation_fee);
  const multiplier = getNumber(mealPlan.multiplier);
  return mealPlan.plan_id === "0"
    ? 0
    : baseCost + (hasCard ? 0 : activationFee * multiplier);
};

const PlanList: React.FC<PlanListProps> = ({
  loading,
  mealData,
  handleSelectPlan,
  hasCard,
  planDescriptions,
}) => {
  console.log("=-=-", loading);
  return (
    <section className="transaction mycardAddFund">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="xl-title mb-4">Pick Your Plan</h2>
          </div>
        </div>
        <div className="row planSelection flex-wrap">
          {mealData.length > 0 &&
            mealData?.map((mealPlan) => (
              <div
                className="col-md-4 col-lg-3 col-12 col-sm-6 mb-3"
                key={mealPlan.plan_id}
                onClick={() => handleSelectPlan(mealPlan)}
              >
                <div className="radio">
                  <input type="radio" id={mealPlan.plan_id} name="selectPlan" />
                  <label htmlFor={mealPlan.plan_id}>
                    <div className="card plan-card min-h-0 plan-card-hover">
                      <h3 className="md-title mb-0 primary-text">
                        {mealPlan.plan_name}
                      </h3>
                      <div className="card-plan-description flex-1">
                        <div
                          className="my-2 w100"
                          style={{
                            height: "1px",
                            background: "#444444",
                            border: "1px solid #444444",
                          }}
                        />
                        <div className="f400 flex-1">
                          {_.get(
                            planDescriptions,
                            `${mealPlan.plan_id}.description`
                          ) ? (
                            <>
                              {_.get(
                                planDescriptions,
                                `${mealPlan.plan_id}.description`
                              )}
                              <br />
                            </>
                          ) : null}
                          {mealPlan.plan_id === "0"
                            ? null
                            : `${parseInt(
                                mealPlan?.meals_added
                              )} meals total`}{" "}
                        </div>
                        <div className="primary-color mt-auto sm-title">
                          {mealPlan.plan_id !== "0" &&
                            formatNumber(getMealPrice(mealPlan, hasCard))}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          {mealData.length === 0 && !loading && (
            <div>No meal plans available.</div>
          )}
          {loading && (
            <div className="d-flex w-100 align-items-center justify-content-center">
              <Spinner type="border" color="primary" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default PlanList;
