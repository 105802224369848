import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import tickAnimate from "../../assets/img/tick-animation.gif";
import useOutsideClick from "../../hooks/useOutsideClick";
import { studentActions } from "../../state/student/studentSlice";
import { DashboardLayoutFooter } from "./DashboardLayoutFooter";
import { DashboardLayoutHeader } from "./DashboardLayoutHeader";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const stickyItems = [
  {
    href: "/",
    title: "Dashboard",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.125 10.4995C8.98896 10.4995 10.5 8.98847 10.5 7.12451C10.5 5.26055 8.98896 3.74951 7.125 3.74951C5.26104 3.74951 3.75 5.26055 3.75 7.12451C3.75 8.98847 5.26104 10.4995 7.125 10.4995Z"
          stroke="#46484A"
          strokeWidth="1.82292"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.875 10.4995C18.739 10.4995 20.25 8.98847 20.25 7.12451C20.25 5.26055 18.739 3.74951 16.875 3.74951C15.011 3.74951 13.5 5.26055 13.5 7.12451C13.5 8.98847 15.011 10.4995 16.875 10.4995Z"
          stroke="#46484A"
          strokeWidth="1.82292"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 20.25C8.98896 20.25 10.5 18.739 10.5 16.875C10.5 15.011 8.98896 13.5 7.125 13.5C5.26104 13.5 3.75 15.011 3.75 16.875C3.75 18.739 5.26104 20.25 7.125 20.25Z"
          stroke="#46484A"
          strokeWidth="1.82292"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.875 20.25C18.739 20.25 20.25 18.739 20.25 16.875C20.25 15.011 18.739 13.5 16.875 13.5C15.011 13.5 13.5 15.011 13.5 16.875C13.5 18.739 15.011 20.25 16.875 20.25Z"
          stroke="#46484A"
          strokeWidth="1.82292"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    href: "/my-card",
    title: "My Card",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.25 7.5H3.75C3.33579 7.5 3 7.83579 3 8.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H17.25C17.6642 19.5 18 19.1642 18 18.75V8.25C18 7.83579 17.6642 7.5 17.25 7.5Z"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00049 4.50049H20.2505C20.4494 4.50049 20.6402 4.57951 20.7808 4.72016C20.9215 4.86081 21.0005 5.05158 21.0005 5.25049V16.5005"
          stroke="#46484A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    href: "/restaurants",
    title: "Restaurants",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.875 3V6.75"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.875 10.875V21"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 3L11.25 7.5C11.25 8.39511 10.8944 9.25355 10.2615 9.88648C9.62855 10.5194 8.77011 10.875 7.875 10.875C6.97989 10.875 6.12145 10.5194 5.48851 9.88648C4.85558 9.25355 4.5 8.39511 4.5 7.5L5.25 3"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1255 15H13.8755C13.8755 15 15.0005 4.5 19.1255 3V21"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    href: "/restaurants?isFavorites=true",
    title: "Favorites",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0005 20.9995L12.0005 17.2495L6.00049 20.9995V4.49951C6.00049 4.3006 6.07951 4.10983 6.22016 3.96918C6.36081 3.82853 6.55158 3.74951 6.75049 3.74951H17.2505C17.4494 3.74951 17.6402 3.82853 17.7808 3.96918C17.9215 4.10983 18.0005 4.3006 18.0005 4.49951V20.9995Z"
          stroke="#46484A"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

export const StickyMenu: React.FC = () => {
  const location = useLocation();
  return (
    <div className="sticky_menu d-flex d-md-none">
      {stickyItems.map(({ href, icon, title }, i) => (
        <Link
          key={i}
          to={href}
          className={location.pathname === href ? "active" : ""}
        >
          {icon}
          {title}
        </Link>
      ))}
    </div>
  );
};

type ReferFriendFormValues = {
  friendName: string;
  friendEmail: string;
  message?: string;
};

const ReferFriendFormValidateSchema = Yup.object({
  friendEmail: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  friendName: Yup.string().required("Please provide a name."),
});

const initialValues: ReferFriendFormValues = {
  friendName: "",
  friendEmail: "",
};

export const ReferFriend: React.FC = () => {
  const dispatch = useAppDispatch();
  const [attemptReferError, setAttemptReferError] = useState("");
  const [referSuccess, setReferSuccess] = useState(false);
  const [attemptReferSuccess, setAttemptReferSuccess] = useState("");

  const formRef = useRef(null);

  useOutsideClick(formRef, () => {
    setReferSuccess(false);
    setAttemptReferError("");
    setAttemptReferSuccess("");
    formHandlers.resetForm();
  });

  const formHandlers = useFormik<ReferFriendFormValues>({
    initialValues,
    validationSchema: ReferFriendFormValidateSchema,
    onSubmit: ({ friendEmail, friendName, message }) => {
      dispatch(
        studentActions.referFriend({
          data: {
            friend_name: friendName,
            friend_email: friendEmail,
            message: message,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setAttemptReferError("");
              setReferSuccess(true);
              setAttemptReferSuccess(data.message);
              return;
            }
            setAttemptReferError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );

            return;
          },
        })
      );
    },
  });

  return (
    <div
      className="modal fade"
      id="referFriend"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={formRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body pt-0">
            {referSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {attemptReferSuccess}
                </p>
              </div>
            ) : (
              <>
                <h2 className="xl-title text-center mb-30">Refer A Friend</h2>
                {attemptReferError && (
                  <label className="error mb-1">{attemptReferError}</label>
                )}
                <form
                  className="max-width-350"
                  id="reportCard"
                  noValidate
                  onSubmit={formHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="name">Friend’s Name*</label>
                    <input
                      type="text"
                      name="friendName"
                      id="name"
                      className="form-control"
                      placeholder="Friend’s Name"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.friendName}
                    />
                    {formHandlers.touched.friendName &&
                    formHandlers.errors.friendName ? (
                      <label className="error">
                        {formHandlers.errors.friendName}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Friend’s Email*</label>
                    <input
                      type="email"
                      name="friendEmail"
                      id="email"
                      className="form-control"
                      placeholder="Friend’s Email"
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.friendEmail}
                    />
                    {formHandlers.touched.friendEmail &&
                    formHandlers.errors.friendEmail ? (
                      <label className="error">
                        {formHandlers.errors.friendEmail}
                      </label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      rows={6}
                      className="form-control"
                      defaultValue={""}
                      onChange={formHandlers.handleChange}
                      onBlur={formHandlers.handleBlur}
                      value={formHandlers.values.message}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary w100">
                    Submit
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <div>
      {/* <AppAlert
        kind="error"
        content={
          <>
            This is an alert message from OMCP. <a href="/">More Information</a>
          </>
        }
      /> */}
      <DashboardLayoutHeader />
      {children}
      <DashboardLayoutFooter />
      <StickyMenu />
      <ReferFriend />
    </div>
  );
};

export default DashboardLayout;
