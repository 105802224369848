import apiClient from "../utils/apiClient";

export type APIContactUsInput = {
  email_address: string;
  first_name: string;
  last_name: string;
  message?: string;
  subject: string;
};

export type APIContactUsResponse = {
  status: boolean;
  action_code: number;
  message: string;
  time_stamp: string;
};

const contactUs = (data: APIContactUsInput) => {
  return apiClient.post(`/v1/site/contactus.json`, {
    ...data,
    client_id: process.env.REACT_APP_CLIENT_ID,
  });
};

const siteAPI = {
  contactUs,
};

export default siteAPI;
