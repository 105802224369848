import apiClient from "../utils/apiClient";

export type SchoolEntity = {
  SchoolID: string;
  Name: string;
};

export type APIListSchoolsResponse = {
  status: boolean;
  action_code: number;
  message: string;
  time_stamp: string;
  payload: {
    data: Array<SchoolEntity>;
  };
};

const listSchools = () => {
  return apiClient.get<APIListSchoolsResponse>(`/v1/school.json`);
};

const transactionApi = {
  listSchools,
};

export default transactionApi;
