import Cookies from "js-cookie";
export const localAuthenticate = (): boolean => {
  const token = getAccessToken();
  if (token && token.length > 20) {
    // safe checking with length > 20
    return true;
  }

  return false;
};

export const setAccessToken = (token: string) =>
  Cookies.set("access_token", token);

export const getAccessToken = () => {
  const token = Cookies.get("access_token");
  return token;
};

export const removeAccessToken = () => {
  return Cookies.remove("access_token");
};

export const setRefreshToken = (token: string) =>
  Cookies.set("refresh_token", token);

export const getRefreshToken = () => {
  const token = Cookies.get("refresh_token");
  return token;
};

export const removeRefreshToken = () => {
  return Cookies.remove("refresh_token");
};

export const setLocalAuthCredentials = (
  accessToken: string,
  refreshToken: string,
  loginID: number
): void => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  Cookies.set("login_id", `${loginID}`);
};

export const clearLocalAuthCredentials = (): void => {
  removeAccessToken();
  removeRefreshToken();
  Cookies.remove("login_id");
};
