import React from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";

const MyCardAddFundsInstitutionFunded = () => {
  return (
    <DashboardLayout>
      {/* breadcrumb */}
      <section className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="my-card-institution-funded.html">My Card</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Funds
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* transaction */}
      <section className="transaction mycard">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card text-center justify-content-center">
                <h2 className="no_fund_title">No Funds Can Be Added</h2>
                <p className="dark-text mb-0">
                  Please contact your program representitive to add funds to
                  your card
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <h4 className="md-title text-uppercase mb-60">
                  Program Information
                </h4>
                <div>
                  <h4 className="md-title mb-3">
                    University of Delaware McNair Scholars Program
                  </h4>
                  <div className="row">
                    <div className="col-sm-6 admin-address">
                      <p className="f500 mb-2">Your Program Administrator:</p>
                      Linda McDowell
                      <a href="mailto:lmcdowell@udel.edu">lmcdowell@udel.edu</a>
                      <a href="tel:302-555-5555">302-555-5555</a>
                    </div>
                    <div className="col-sm-6">
                      <p className="f500 mb-2">Amount Provided:</p>
                      $1,200
                    </div>
                  </div>
                  <div className="active-card-toast">
                    <span>
                      OCMP card will be active from July 1st, 2022 – July 16thh,
                      2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default MyCardAddFundsInstitutionFunded;
