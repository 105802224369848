import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const listRestaurantSelector = createSelector(
  (state: RootState) => state.restaurant,
  (state) => state.list
);

export const restaurantDetailsSelector = createSelector(
  (state: RootState) => state.restaurant,
  (state) => state.details
);

export const restaurantTypesSelector = createSelector(
  (state: RootState) => state.restaurant,
  (state) => state.types
);

export const requestRestaurantSelector = createSelector(
  (state: RootState) => state.restaurant,
  (state) => state.request
);
