import React from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../app/routes";
import ocmpLogo from "./../../assets/img/ocmp.svg";

type AuthLayoutProps = {
  pageName: string;
  navItem: {
    url: string;
    text: string;
  };
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, navItem }) => {
  return (
    <div>
      <header>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-4 col-sm-6">
              <Link to={APP_ROUTES.Dashboard.path}>
                <img
                  src={ocmpLogo}
                  alt="OCMP"
                  className="img-fluid header-logo"
                />
              </Link>
            </div>
            <div className="col-8 col-sm-6 text-end">
              <Link to={navItem.url} className="btn btn-secondary">
                {navItem.text}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;
