import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { RestaurantEntity } from "../../api/restaurantApi";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import { getServiceData } from "../../services/service-data";
import { listRestaurant } from "../../state/restaurant/restaurantSlice";
import { currentStudentSelector } from "../../state/student/studentSelector";
import { siteRecentTransactionSelector } from "../../state/transaction/transactionSelector";
import { listRecentTransactions } from "../../state/transaction/transactionSlice";
import { getLogoUrl } from "../../utils/logo";
import { isHttp } from "../../utils/string";
import RestaurantDetailsModal from "../restaurants/RestaurantDetailsModal";
import * as _ from "lodash";

type RestaurantListProps = {
  restaurants: Array<RestaurantEntity>;
  loading?: boolean;
};

const RestaurantList: React.FC<RestaurantListProps> = ({
  restaurants,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const transitionDuration = 5000;
  const recentTransactionState = useSelector(siteRecentTransactionSelector);
  const currentStudentState = useSelector(currentStudentSelector);

  const [mID, setMID] = useState("");
  const [favoriteRestaurant, setFavoriteRestaurant] = useState<
    Array<RestaurantEntity>
  >([]);
  const [showingRecentTransactionIndex, setShowingRecentTransactionIndex] =
    useState(0);
  const [service, setService] = React.useState({});

  async function fetchServiceData() {
    const res = await getServiceData();
    setService(res.data);
  }

  useEffect(() => {
    fetchServiceData();
  }, [currentStudentState]);

  const hasServices =
    currentStudentState?.data?.data?.schoolServices &&
    currentStudentState?.data?.data?.schoolServices.length > 0;

  useEffect(() => {
    let interval = setInterval(() => {
      setShowingRecentTransactionIndex(showingRecentTransactionIndex + 1);
    }, transitionDuration);
    if (
      showingRecentTransactionIndex <
      recentTransactionState.data.length - 1
    ) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });

  useEffect(() => {
    const blurbs = document.getElementsByClassName("recent-transaction");
    if (Array.from(blurbs).length > 0) {
      Array.from(blurbs).forEach((d) => {
        d.classList.add("fadeOut");
        d.classList.remove("fadeIn");
        setTimeout(() => {
          d.classList.add("fadeIn");
          d.classList.remove("fadeOut");
        }, 1000);
      });
    }
  }, [showingRecentTransactionIndex]);

  useEffect(() => {
    setFavoriteRestaurant(() => {
      return restaurants.filter(
        (restaurant) => restaurant.student_favorite === "1"
      );
    });
  }, [restaurants]);

  useEffect(() => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      dispatch(listRecentTransactions());
    }
  }, [currentStudentState]);

  const handleChangeFavorite = () => {};

  const refetchFavoriteRestaurants = () => {
    if (currentStudentState.data?.data?.studentData.SchoolID) {
      dispatch(
        listRestaurant({
          data: {
            items_per_page: 1000,
            SchoolID: currentStudentState.data?.data?.studentData.SchoolID,
            page_number: 1,
            sort_by: "alpha",
            sort_order: "asc",
          },
        })
      );
    }
  };

  return (
    <section className="restaurant_list">
      <div className="container">
        <div className="row">
          <div className={hasServices ? "col-xl-9" : "col-xl-12"}>
            <div className="card px-0 justify-content-start h-md-0 min-h-lg-0">
              {!loading && favoriteRestaurant.length > 0 && (
                <>
                  <div className="px-40 d-flex align-items-center justify-content-between mt-2">
                    <h3 className="xl-title mb-0">Favorite Restaurants</h3>
                    <ul className="mb-0 d-md-flex gap-2 d-none">
                      <div>
                        <Link
                          to={APP_ROUTES.Restaurants.path}
                          className="btn-outline-secondary btn"
                        >
                          View all Restaurants
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="/restaurants?isFavorites=true"
                          className="btn btn-outline-secondary"
                        >
                          Manage Favorites
                        </Link>
                      </div>
                    </ul>
                  </div>
                  <table className="restaurant_table mt-4">
                    <tbody>
                      {favoriteRestaurant.map((restaurant) => {
                        return (
                          <tr
                            key={restaurant.M_ID}
                            data-bs-target="#moreDetail"
                            data-bs-toggle="modal"
                            onClick={(e) => {
                              if (restaurant.M_ID) {
                                setMID(String(restaurant.M_ID));
                              }
                            }}
                          >
                            <td>
                              <div className="restaurant_name">
                                <img
                                  src={getLogoUrl(restaurant.Logo)}
                                  alt="..."
                                  className="img-fluid"
                                />
                                <div>
                                  <h5 className="text-break">
                                    {restaurant.Name}
                                  </h5>
                                  <p className="text-break">
                                    {restaurant.Addr_A}
                                    <span className="time d-md-none d-inline-block ms-4">
                                      {restaurant.OpenTime} -
                                      {restaurant.CloseTime}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              {restaurant.OpenTime &&
                                restaurant.CloseTime &&
                                `${restaurant.OpenTime} - ${restaurant.CloseTime}`}
                            </td>
                            <td>
                              {restaurant?.Phone && (
                                <span className="d-none d-md-block">
                                  {restaurant.Phone}
                                </span>
                              )}

                              <a
                                href={`tel:${restaurant.Phone}`}
                                className="btn-contact d-block d-md-none"
                              >
                                Call <img src="img/phone.svg" alt="" />
                              </a>
                            </td>
                            <td
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <a
                                href={
                                  !isHttp(restaurant.Web)
                                    ? restaurant.Web
                                    : `https://${restaurant.Web}`
                                }
                                className="website_link d-none d-md-block"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Visit website
                                <img
                                  src="img/arrow.png"
                                  alt="..."
                                  className="img-link"
                                />
                              </a>
                              <a
                                href={
                                  !isHttp(restaurant.Web)
                                    ? restaurant.Web
                                    : `https://${restaurant.Web}`
                                }
                                className="btn-contact d-block d-md-none"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Website <img src="img/web.svg" alt="..." />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
              {!loading && favoriteRestaurant.length === 0 && (
                <div className="px-40">
                  <h3 className="xl-title mb-30">Favorite Restaurants</h3>
                  <div className="no-restaurant">
                    <h5 className="text-center mb-4">
                      No restaurants have been added to your favorites
                    </h5>
                    <Link to="/restaurants" className="btn btn-primary">
                      View All Restaurants
                    </Link>
                  </div>
                </div>
              )}

              {loading && (
                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                  <Spinner type="border" color="primary" />
                </div>
              )}
              <ul className="list-unstyled mb-0 d-md-none d-block px-20 mb-0 pt-36">
                <li>
                  <Link
                    to={APP_ROUTES.Restaurants.path}
                    className="btn-outline-secondary btn d-block"
                  >
                    View all Restaurants
                  </Link>
                </li>
                <li className="mt-3">
                  <Link
                    to="/restaurants?isFavorites=true"
                    className="btn-outline-secondary btn d-block"
                  >
                    Manage Favorites
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {hasServices && (
            <div className={hasServices ? "col-xl-3" : ""}>
              <div className="ocmp_ad_box">
                <div>
                  <h2>
                    Order Food <br />
                    on the Go
                  </h2>
                  <p>Use your OCMP card on select third party application.</p>
                  <p>
                    Connect your account
                    <br /> to get started.
                  </p>
                </div>
                <div className="row gap-2">
                  {currentStudentState.data?.data.schoolServices?.map((s) => (
                    <a
                      key={s.SchoolServiceID}
                      href={
                        service
                          ? _.get(
                              service,
                              `${s.SchoolServiceID}.schools.${currentStudentState.data?.data?.studentData.SchoolID}.how_to_link`
                            )
                          : s.URL
                      }
                      className="btn btn-manage mt-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {s.Description}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {recentTransactionState.data.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="notification-bar">
                <span className="red-icon">
                  <img src="img/msg.svg" alt="..." />
                </span>

                <span className="recent-transaction">
                  {`${recentTransactionState.data[showingRecentTransactionIndex]?.Name} just purchased a meal at ${recentTransactionState.data[showingRecentTransactionIndex]?.MerchantName}`}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-6">
            <div
              className="reference_box refer_friend"
              style={{
                backgroundImage: `url('/img/refer_friend.svg')`,
                backgroundSize: "65% 100%",
                backgroundPosition: "115% 25px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="row align-items-center">
                <div className="col-xl-8">
                  <h3 className="xl-title mb-2">Refer a Friend</h3>
                  <Link
                    to="#referFriend"
                    data-bs-toggle="modal"
                    className="btn-outline-secondary btn"
                  >
                    Refer a Friend
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="reference_box suggest_restaurant"
              style={{
                backgroundImage: `url('/img/suggest.svg')`,
                backgroundSize: "65% 100%",
                backgroundPosition: "115% 25px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="row align-items-center">
                <div className="col-xl-8">
                  <h3 className="xl-title mb-2">Suggest a Restaurant</h3>
                  <Link
                    to="#suggestRestaurant"
                    data-bs-toggle="modal"
                    className="btn-outline-secondary btn"
                  >
                    Suggest a Restaurant
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!mID && (
        <RestaurantDetailsModal
          isOpen={!!mID}
          toggle={() => setMID("")}
          m_id={mID}
          setMID={setMID}
          handleChangeFavorite={handleChangeFavorite}
          handleClose={refetchFavoriteRestaurants}
        />
      )}
    </section>
  );
};

export default RestaurantList;
