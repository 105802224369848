import apiClient from "../utils/apiClient";

export type MealPlanEntity = {
  plan_id: string;
  plan_code: string;
  plan_name: string;
  base_cost: number;
  meals_added: string;
  activation_fee: number;
  multiplier: number;
  meal_cost: number;
};

export type APIListMealPlanResponse = {
  status: boolean;
  action_code: number;
  message: string;
  time_stamp: string;
  payload: {
    data: Array<MealPlanEntity>;
  };
};

const listMealPlans = (SchoolID?: string) => {
  return apiClient.get<APIListMealPlanResponse>(
    `/v1/mealplan.json?SchoolID=${SchoolID}`
  );
};

const mealPlanApi = {
  listMealPlans,
};

export default mealPlanApi;
