import { APIResponse } from "./types";
import apiClient from "../utils/apiClient";

export type StoredPaymentOptionsEntity = {
  payment_profile_id: string;
  CreditCardNumber: string;
  CardType: string;
};

export type StoredPaymentEntity = {
  AuthDataDescriptor: string;
  AuthDataValue: string;
  BillingFirstName: string;
  BillingLastName: string;
  BillingAddr_A: string;
  BillingAddr_B: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
};

export type APIListStoredPaymentOptionsResponse = APIResponse<{
  data: Array<StoredPaymentOptionsEntity>;
}>;

export type APIAddStoredPaymentResponse = {
  status: boolean;
  action_code: number;
  message: string;
  time_stamp: string;
  payload: any;
};

const listStoredPaymentOptions = () => {
  return apiClient.get<APIListStoredPaymentOptionsResponse>(
    `/v1/payment/stored.json`
  );
};

const addStoredPayment = (data: StoredPaymentEntity) => {
  return apiClient.post<APIAddStoredPaymentResponse>(
    `/v1/payment/stored.json`,
    data
  );
};

const paymentApi = {
  listStoredPaymentOptions,
  addStoredPayment,
};

export default paymentApi;
