import { createContext, ReactNode, useContext, useState } from "react";
import AppAlert from "../components/alert";

type AlertMessage = {
  id: number;
  content: ReactNode;
  kind: "error" | "info" | "success" | "warning";
  autoDismiss?: boolean;
};

type AlertContextType = {
  messages: Array<AlertMessage>;
  pushMessage: (message: Omit<AlertMessage, "id">) => void;
  removeMessage: (id: number) => void;
};

const AlertContext = createContext<AlertContextType>({
  messages: [],
  pushMessage: (message: Omit<AlertMessage, "id">) => {},
  removeMessage: (id: number) => {},
});

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<AlertMessage[]>([]);

  const removeMessage: AlertContextType["removeMessage"] = (id) => {
    setMessages((prevMessages) => prevMessages.filter((ms) => ms.id !== id));
  };
  const pushMessage: AlertContextType["pushMessage"] = (message) => {
    const newMessage: AlertMessage = {
      id: Date.now(),
      ...message,
    };
    // auto dismiss
    if (newMessage.autoDismiss) {
      setTimeout(() => {
        removeMessage(newMessage.id);
        return;
      }, 3000);
    }

    // max 3 messages will be display on top of page
    setMessages((prevMessages) => {
      if (prevMessages.length === 3) {
        const [firstMessage] = prevMessages;
        prevMessages = prevMessages.filter((ms) => ms.id !== firstMessage.id);
      }
      return prevMessages.concat(newMessage);
    });
  };

  return (
    <AlertContext.Provider value={{ messages, pushMessage, removeMessage }}>
      {messages.map((ms, idx) => {
        return (
          <AppAlert
            idx={idx}
            key={ms.id}
            content={ms.content}
            kind={ms.kind}
            onClose={() => removeMessage(ms.id)}
          />
        );
      })}
      {children}
    </AlertContext.Provider>
  );
};

export const useAppAlert = () => {
  const { pushMessage } = useContext(AlertContext);
  return {
    pushMessage,
  };
};
