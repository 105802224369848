import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import tickAnimate from "../../assets/img/tick-animation.gif";
import { authActions } from "../../state/auth/authSlice";

type ForgotPasswordFormValues = {
  email: string;
};

const ForgotPasswordFormValidateSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
});

const initialValues: ForgotPasswordFormValues = {
  email: "",
};

const ForgotPasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [attemptPostError, setAttemptPostError] = useState("");

  const formHandlers = useFormik<ForgotPasswordFormValues>({
    initialValues,
    validationSchema: ForgotPasswordFormValidateSchema,
    onSubmit: ({ email }) => {
      setAttemptPostError("");
      dispatch(
        authActions.forgotPassword({
          data: {
            username: email,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              setForgotPasswordSuccess(true);
              return;
            }
            setAttemptPostError(
              data?.message ||
                "Something went wrong on our end. Please try again"
            );
          },
        })
      );
    },
  });

  return (
    <>
      {forgotPasswordSuccess ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={tickAnimate} alt="" width={200} />
          <p className="text-center text-muted mt-4">
            Please sign in with the temporary password <br /> sent to your
            email. Once logged in, you will be <br /> prompted to change your
            password.
          </p>
        </div>
      ) : (
        <>
          {formHandlers.touched.email && formHandlers.errors.email ? (
            <div className="alert alert-danger p-2 mb-4" role="alert">
              {formHandlers.errors.email}
            </div>
          ) : null}
          <h1 className="xl-title text-center mb-3">Forgot Password?</h1>
          <p className="text-center text-muted mb-5">
            Confirm your email to receive instructions
            <br /> to reset your password
          </p>
          <form
            id="forgot-password-form"
            noValidate
            onSubmit={formHandlers.handleSubmit}
          >
            <div className="form-group">
              <label htmlFor="">Email *</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
                onChange={formHandlers.handleChange}
                onBlur={formHandlers.handleBlur}
                value={formHandlers.values.email}
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-block">
                Reset Password
              </button>
            </div>

            {attemptPostError && (
              <label className="error mt-2 text-center w-100">
                {attemptPostError}
              </label>
            )}
          </form>
        </>
      )}
      <div className="text-center w-100 mt-3">
        <Link to="/login" className="primary-text">
          Return to Login
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
