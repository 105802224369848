import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch } from "../../app/hooks";
import { APP_ROUTES } from "../../app/routes";
import { authActions } from "../../state/auth/authSlice";
import { getStudent } from "../../state/student/studentSlice";

type LoginFormValues = {
  email: string;
  password: string;
};

const LoginFormValidateSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  password: Yup.string()
    .required("Please provide a password.")
    .min(6, "Your password must be at least 6 characters long."),
});

const initialValues: LoginFormValues = {
  email: "",
  password: "",
};

const LoginForm: React.FC<{ handleError: (e: string) => void }> = ({
  handleError,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formHandlers = useFormik<LoginFormValues>({
    initialValues,
    validationSchema: LoginFormValidateSchema,
    onSubmit: ({ email, password }) => {
      dispatch(
        authActions.login({
          data: {
            username: email,
            password: password,
          },
          onComplete: (error, data) => {
            if (data?.status) {
              handleError("");
              navigate(APP_ROUTES.Dashboard.path);
              return;
            } else if (data?.action_code === 1502) {
              handleError("");
              navigate(APP_ROUTES.ResetPassword.path);
              return;
            }
            handleError(
              data?.message ||
                "Something went wrong on our end. Please try again."
            );

            return;
          },
        })
      ).then(() => {
        dispatch(getStudent());
      });
    },
  });

  return (
    <>
      <form id="login-form" noValidate onSubmit={formHandlers.handleSubmit}>
        <div className="form-group">
          <label htmlFor="">Email *</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-control"
            onChange={formHandlers.handleChange}
            onBlur={formHandlers.handleBlur}
            value={formHandlers.values.email}
          />
          {formHandlers.touched.email && formHandlers.errors.email ? (
            <label className="error">{formHandlers.errors.email}</label>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="">Password *</label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="form-control"
            onChange={formHandlers.handleChange}
            onBlur={formHandlers.handleBlur}
            value={formHandlers.values.password}
          />
          {formHandlers.touched.password && formHandlers.errors.password ? (
            <label className="error">{formHandlers.errors.password}</label>
          ) : null}
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary btn-block">
            Login
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
