import React from "react";

type BreadCrumbLayoutProps = {
  children: React.ReactNode;
};

const BreadCrumbLayout: React.FC<BreadCrumbLayoutProps> = ({ children }) => {
  return (
    <div>
      <section className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">{children}</ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BreadCrumbLayout;
