import mastercard from "../assets/img/master-card.png";
import visa from "../assets/img/visa.png";
import discover from "../assets/img/discover.svg";
import defaultCard from "../assets/img/default-card.svg";
import americanExpress from "../assets/img/american-express.svg";
import dinerClub from "../assets/img/diner-club.svg";
import JCB from "../assets/img/JCB.svg";

export const getLogoUrl = (logo: string | undefined): string => {
  const logoPrefixUrl = process.env.REACT_APP_LOGO_PREFIX_URL;
  if (logo) {
    return logoPrefixUrl + logo;
  } else {
    return logoPrefixUrl + "s.png";
  }
};

export const getCardLogo = (logoType: string | undefined) => {
  switch (logoType) {
    case "MasterCard":
      return mastercard;
    case "Visa":
      return visa;
    case "AmericanExpress":
      return americanExpress;
    case "DinersClub":
      return dinerClub;
    case "Discover":
      return discover;
    case "JCB":
      return JCB;
    default:
      return defaultCard;
  }
};
