import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import address from "../assets/img/address.svg";
import callCenter from "../assets/img/customer-care.svg";
import emailAddress from "../assets/img/email.svg";
import support from "../assets/img/support.svg";
import BreadCrumbLayout from "../components/layout/BreadCrumbLayout";
import DashboardLayout from "../components/layout/DashboardLayout";
import ContactUsForm from "../modules/site/ContactUsForm";
import { getFAQs } from "../services/service-data";

interface FAQEntity {
  question: string;
  answer: any;
  category: string;
  tags: string;
}

const GetHelp = () => {
  const [faqs, setFaqs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    async function fetchFAQs() {
      try {
        setLoading(true);
        const res = await getFAQs();
        setFaqs(res.data.faqs);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    fetchFAQs();
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get Help | OCMP</title>
      </Helmet>
      {/* breadcrumb */}
      <BreadCrumbLayout>
        <li className="breadcrumb-item active" aria-current="page">
          Get Help
        </li>
      </BreadCrumbLayout>
      {/* help */}
      <section className="help">
        <div className="container">
          <div className="row mb-30 contact_box">
            <div className="col-lg-3 col-md-6">
              <div className="card text-center justify-content-start min-h-0 px-2">
                <div>
                  <img
                    src={address}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                  <h6 className="mb-4">Main Address</h6>
                </div>
                <address className="mb-0 fs-5">
                  132 Adam Street
                  <br />
                  Suite #2
                  <br />
                  Newton, MA 02458
                </address>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card text-center justify-content-start min-h-0 px-2">
                <div>
                  <img
                    src={support}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                  <h6 className="mb-4">Support Line</h6>
                </div>
                <a href="tel:888-211-6267" className="fs-5">
                  888-211-6267
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card text-center justify-content-start min-h-0 px-2">
                <div>
                  <img
                    src={emailAddress}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                  <h6 className="mb-4">Email Address</h6>
                </div>
                <a href="mailto:info@ocmp.com" className="fs-5">
                  info@ocmp.com
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card text-center justify-content-start min-h-0 px-2">
                <div>
                  <img
                    src={callCenter}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                  <h6 className="mb-4">Call Center Hours</h6>
                </div>
                <p className="fs-5">Contact Us 24/7</p>
              </div>
            </div>
          </div>

          <div className="row mb-60">
            <div className="col-md-12 text-center">
              <Link
                to="#contactUsModal"
                data-bs-toggle="modal"
                className="btn btn-primary max-width-350 d-block"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card accordion-card justify-content-start">
                <h2 className="xl-title mb-30">Frequently Asked Questions</h2>
                <div className="row" id="faq">
                  {!loading &&
                    faqs.length > 0 &&
                    faqs.map((f: FAQEntity, i) => (
                      <div className="col-md-6" key={i}>
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {f.question}
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#faq"
                            >
                              <div
                                className="accordion-body"
                                dangerouslySetInnerHTML={{ __html: f.answer }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {!loading && faqs.length === 0 && (
                    <div className="col-md-12">
                      <p className="text-center">
                        No FAQs found. Please contact us for more information.
                      </p>
                    </div>
                  )}
                  {loading && (
                    <div className="d-flex w-100 align-items-center justify-content-center">
                      <Spinner type="border" color="primary" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUsForm />
    </DashboardLayout>
  );
};

export default GetHelp;
