import axios from "axios";

export const getServiceData = () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/data/service-data.php`
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPlanDescriptions = () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/data/plan-descriptions.php`
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFAQs = () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/data/portal-faqs.php`
    );
  } catch (e) {
    return Promise.reject(e);
  }
};
